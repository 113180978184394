import { Action, Dispatch } from "redux"
import { CenovaPonuka, State } from "../../ts/CP"
import axios from "../../axios/axios"
import { Auth } from "../../ts/Auth"

export const EDIT_CP =
  (cp: CenovaPonuka, auth: Auth, success: (state: boolean) => void) =>
  async (dispatch: Dispatch<Action<"EDIT_CP" | "SET_ERRORS">>) => {
    try {
      const req = await axios.post("/cp/edit", {
        cp,
        id: auth.user.id,
      })
      if (success) success(true)
      dispatch({
        type: "SET_ERRORS",
        payload: {},
      })
      dispatch({
        type: "EDIT_CP",
        payload: {
          ...cp,
          last_edited_by: {
            name: auth.user.firstName + " " + auth.user.lastName,
            mobil: auth.user.mobil,
            email: auth.user.email,
          },
          last_edited_date: new Date().getTime(),
        },
      })
    } catch (err) {
      if (success) success(false)
      dispatch({
        type: "SET_ERRORS",
        payload: (err as any).response.data.errors,
      })
    }
  }

export const CREATE_CP =
  (
    cp: CenovaPonuka,
    id: string,
    success: (state: boolean, id: string) => void
  ) =>
  async (dispatch: Dispatch<Action<"ADD_CP" | "SET_ERRORS">>) => {
    try {
      const req = await axios.post("/cp/create", {
        cp,
        id,
      })
      success(true, req.data.success.id)
      dispatch({
        type: "SET_ERRORS",
        payload: {},
      })
      dispatch({
        type: "ADD_CP",
        payload: req.data.success,
      })
    } catch (err) {
      success(false, "")
      dispatch({
        type: "SET_ERRORS",
        payload: (err as any).response.data.errors,
      })
    }
  }

export const EDIT_CP_STATE =
  (cp: CenovaPonuka, auth: Auth, state: State) =>
  async (dispatch: Dispatch<Action<"EDIT_CP_STATE">>) => {
    await axios.post("/cp/edit-state", {
      cp,
      id: auth.user.id,
      state,
    })

    dispatch({
      type: "EDIT_CP_STATE",
      payload: {
        cp: {
          ...cp,
          last_edited_by: {
            name: auth.user.firstName + " " + auth.user.lastName,
            mobil: auth.user.mobil,
            email: auth.user.email,
          },
          last_edited_date: new Date().getTime(),
        },
        state,
      },
    })
  }

export const CREATE_COPY =
  (cp: CenovaPonuka, id: string) =>
  async (dispatch: Dispatch<Action<"ADD_CP">>) => {
    try {
      const req = await axios.post("/cp/create-copy", {
        cp,
        id,
      })

      dispatch({
        type: "ADD_CP",
        payload: req.data.success,
      })
    } catch (err) {}
  }

export const GET_CP =
  (id: string) =>
  async (dispatch: Dispatch<Action<"SET_LOADING" | "SET_CP">>) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    })

    const req = await axios.get("/cp", {
      params: {
        id,
      },
    })

    if (req.data.length > 0)
      dispatch({
        type: "SET_CP",
        payload: req.data,
      })

    dispatch({
      type: "SET_LOADING",
      payload: false,
    })
  }

export const DELETE_CP =
  (cp: CenovaPonuka, id: string) =>
  async (dispatch: Dispatch<Action<"DELETE_CP">>) => {
    await axios.post("/cp/delete", {
      cp,
      id,
    })
    dispatch({
      type: "DELETE_CP",
      payload: cp.id,
    })
  }
