import React from "react"
import "./TextField.scss"

import {
  StandardTextFieldProps,
  TextField as MaterialTextField,
} from "@material-ui/core"

interface Props extends StandardTextFieldProps {

}

const TextField = (props: Props) => {
  return <MaterialTextField className="_styled" {...props} />
}

export default TextField
