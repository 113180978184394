import React, { CSSProperties, useState } from "react"
import "./Dropdown.scss"

import classNames from "classnames"
import { HiChevronRight } from "react-icons/hi"
import OutsideClickHandler from "react-outside-click-handler"

type DropdownItemType = {
  title: string
  onClick?(): void
}

interface Props {
  title: string
  color?: "primary" | "yellow"
  list: DropdownItemType[]
  disabled?: boolean
  style?: CSSProperties
}

const DROPDOWN_ITEM_HEIGHT = 36

const DropdownItem = ({ title, onClick }: DropdownItemType) => {
  return (
    <div
      onClick={onClick}
      className="item"
      style={{
        height: DROPDOWN_ITEM_HEIGHT,
      }}
    >
      {title}
    </div>
  )
}

const Dropdown: React.FC<Props> = ({
  title,
  color = "primary",
  list,
  disabled = false,
  style,
}: Props) => {
  const [opened, setOpened] = useState(false)

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpened(false)
      }}
    >
      <div
        style={style}
        className={classNames(
          "dropdown",
          { opened: opened && !disabled, disabled },
          color
        )}
      >
        <div
          className="dropdownTitle"
          style={{
            height: DROPDOWN_ITEM_HEIGHT,
          }}
          onClick={disabled ? () => null : () => setOpened(!opened)}
        >
          {title}
          <HiChevronRight size={20} />
        </div>
        <div
          className="list"
          style={{
            height:
              opened && !disabled
                ? list.length * DROPDOWN_ITEM_HEIGHT + list.length
                : 0,
          }}
        >
          {list.map((item, index) => (
            <DropdownItem key={index} {...item} />
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Dropdown
