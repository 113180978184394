import React from "react"

import {
  HiChevronDown,
  HiChevronDoubleLeft,
  HiChevronRight,
  HiChevronLeft,
  HiChevronDoubleRight,
} from "react-icons/hi"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import IconButton from "@material-ui/core/IconButton"

interface Props {
  numberPerPage: number
  setNumberPerPage(value: number): void
  firstPage(): void
  previousPage: () => void
  nextPage(): void
  lastPage(): void
  range: [number, number, number]
}

const TableFooter: React.FC<Props> = ({
  range,
  numberPerPage,
  setNumberPerPage,
  firstPage,
  previousPage,
  nextPage,
  lastPage,
}: Props) => {
  return (
    <div className="tableFooter">
      <FormControl
        variant="outlined"
        className="object-count-form"
        style={{
          width: 120,
        }}
      >
        <InputLabel
          style={{
            color: "white",
            fontFamily: "inherit",
            whiteSpace: "nowrap",
            fontWeight: 400,
            fontSize: 16,
            marginTop: -1,
          }}
          id="object-count"
        >
          Počet riadkov
        </InputLabel>
        <Select
          className="object-count-select"
          labelId="object-count"
          id="object-count"
          variant="outlined"
          value={numberPerPage}
          onChange={(e) => setNumberPerPage(e.target.value as number)}
          label="Počet riadkov"
          IconComponent={(props) => (
            <HiChevronDown
              style={{
                fontSize: 20,
                marginTop: 2
              }}
              {...props}
            />
          )}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </FormControl>
      <div className="buttons">
        <IconButton onClick={firstPage}>
          <HiChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={previousPage}
          style={{
            paddingLeft: 10,
          }}
        >
          <HiChevronLeft />
        </IconButton>
        {range[0]} - {range[1]} z {range[2]}
        <IconButton
          onClick={nextPage}
          style={{
            paddingRight: 10,
          }}
        >
          <HiChevronRight />
        </IconButton>
        <IconButton onClick={lastPage}>
          <HiChevronDoubleRight />
        </IconButton>
      </div>
    </div>
  )
}

export default TableFooter
