import React, { ForwardedRef, forwardRef } from "react"
import { Link } from "react-router-dom"

import classnames from "classnames"

interface Props {
  link: string
  title: string
  active: boolean
  ref: ForwardedRef<HTMLAnchorElement>
}

const HeaderItem: React.ForwardRefExoticComponent<Props> = forwardRef(
  ({ link, title, active = false }, ref) => {
    return (
      <Link
        to={link}
        ref={ref}
        className={classnames("headerItem", { active })}
      >
        {title}
      </Link>
    )
  }
)

export default HeaderItem
