import classNames from "classnames"
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react"
import { ConfirmationType } from "../../../ts/UI"
import Button from "../Button/Button"
import "./Confirmation.scss"

import OutsideClickHandler from "react-outside-click-handler"

interface Props {
  yes(): void
  no?(): void
  title: string
  color?: "primary" | "yellow"
  style?: CSSProperties
}

const Confirmation = forwardRef(
  (
    { yes, no, title, color = "primary", style }: Props,
    ref
  ): React.ReactElement<Props> => {
    const [active, setActive] = useState(false)

    useImperativeHandle(
      ref,
      () => ({
        clickHandler() {
          setActive(!active)
        },
      }),
      [active]
    )

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setActive(false)
        }}
      >
        <div
          className={classNames("confirmation", { active }, color)}
          style={style}
        >
          <div className="confirmationTitle">{title}</div>
          <div className="yes-no-wrapper">
            <div className="yesButton">
              <Button
                onClick={() => {
                  yes()
                  setActive(false)
                }}
              >
                Ano
              </Button>
            </div>
            <div className="noButton">
              <Button onClick={no ? () => no() : () => setActive(false)}>
                Ne
              </Button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
)

export default Confirmation
