import React, { useRef, InputHTMLAttributes } from "react"
import "./TableInput.scss"

import classnames from "classnames"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  visible: boolean
}

const TableInput: React.FC<Props> = ({ visible, ...props }: Props) => {
  const tableInputWrapper = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={tableInputWrapper}
      className={classnames("tableInputWrapper", { visible, disabled: props.disabled })}
    >
      <input
        className="tableInput"
        onBlur={() => tableInputWrapper.current?.classList.remove("focused")}
        onFocus={() => tableInputWrapper.current?.classList.add("focused")}
        {...props}
        value={!props.value ? "" : props.value}
        spellCheck={false}
      />
    </div>
  )
}

export default TableInput
