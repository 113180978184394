export type SearchQuery = {
  cislo_ponuky: string
  objednavatel: string
  prijimatel: string
}

export type KontaktSearchQuery = {
  klient: string
  skupina: string
  obec: string
  okres: string
  meno: string
  priezvisko: string
  email: string
}

export const initialState: CenovaPonuka = {
  id: "12345",
  cislo_ponuky: "",
  bonus: undefined,
  rabat: undefined,
  platnost: undefined,
  dobierka: false,
  zaloha_platba: false,
  zaloha: 0,
  fakturacna: {
    psc: "",
    adresa: "",
    dic: "",
    ico: "",
    icodph: "",
    jmeno: "",
    nazev: "",
    obec: "",
    prijmeni: "",
  },
  objednavatel: {
    adresa: "",
    kontaktna_osoba: "",
    mobil: "",
    nazev: "",
    obec: "",
    psc: "",
    tel: "",
  },
  prijimatel: {
    adresa: "",
    jmeno: "",
    mobil: "",
    obec: "",
    prijmeni: "",
    psc: "",
  },
  polozky: [],
  splatnost: undefined,
  state: "new",
  termin: "",
}

export interface CenovaPonuka {
  id: string
  cislo_ponuky: string
  fakturacna: Fakturace
  objednavatel: Objednavatel
  prijimatel: Prijimatel
  zaloha: number
  splatnost: string | undefined
  termin: string | undefined
  rabat: number | undefined
  bonus: number | undefined
  platnost: string | undefined
  dobierka: boolean
  zaloha_platba: boolean
  polozky: Polozka[]
  state: State
  copy?: string
  kontakt_id?: string | null
  date?: number
  author?: string
  last_edited_by?: LastEdited
  last_edited_date?: number
}

export type LastEdited = {
  name: string
  mobil: string
  email: string
}

export type State = "new" | "in-progress" | "finished"

export type Fakturace = {
  nazev: string
  jmeno: string
  prijmeni: string
  adresa: string
  obec: string
  psc: string
  ico: string
  icodph: string
  dic: string
}

export type Polozka = {
  id: string
  kod: string
  sirka: number
  vyska: number
  dokoncena_stena: string
  popis: string
  mj: string
  mn: number
  cennikova_cena: number
  dph: number
}

export type PolozkaFromArray = {
  id: string
  kod: string
  sirka: string
  vyska: string
  dokoncena_stena: string
  popis: string
  mj: string
  mn: string
  cennikova_cena: string
  dph: string
}

export type Objednavatel = {
  nazev: string
  kontaktna_osoba: string
  adresa: string
  obec: string
  psc: string
  tel: string
  mobil: string
}

type Prijimatel = {
  jmeno: string | undefined
  prijmeni: string | undefined
  adresa: string | undefined
  obec: string | undefined
  psc: string | undefined
  mobil: string | undefined
}
