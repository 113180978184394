import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Auth } from "../../../ts/Auth"
import { KontaktSearchQuery, SearchQuery } from "../../../ts/CP"
import ReduxState from "../../../ts/Redux"
import Kontakty from "./Kontakty"
import "./KontaktyWrapper.scss"
import Header from "./Header"

import { initialState, Kontakt as KontaktType } from "../../../ts/Kontakt"
import SeznamKontaktu from "./SeznamKontaktu"
import {
  EDIT_KONTAKT,
  GET_KONTAKTY,
  ADD_KONTAKT,
} from "../../../redux/actions/kontaktyActions"

const KontaktyWrapperContext = React.createContext<
  | [
      value: KontaktType,
      setValue: (kontakt: KontaktType) => void,
      setIndex: (value: number) => void,
      addNew: (value: KontaktType) => void
    ]
  | []
>([])

const EditableKontaktContext = React.createContext<
  [
    editable: boolean,
    setEditable: (editable: boolean) => void,
    newKontakt: boolean,
    setNewKontakt: (state: boolean) => void,
    listShown: boolean,
    setListShown: (state: boolean) => void
  ]
>([false, () => null, false, () => null, false, () => null])

export const useKontakty = () => {
  return React.useContext(KontaktyWrapperContext)
}

export const useEditableKontakt = () => {
  return React.useContext(EditableKontaktContext)
}

interface Props {
  auth: Auth
  kontakty: KontaktType[]
  GET_KONTAKTY(id: string): void
  EDIT_KONTAKT(kontakt: KontaktType, id: string): void
  ADD_KONTAKT(kontakt: KontaktType, id: string): void
}

interface SeznamKontaktuRef {
  search(query: KontaktSearchQuery): void
}

const KontaktyWrapper: React.FC<Props> = ({
  auth,
  kontakty,
  GET_KONTAKTY,
  EDIT_KONTAKT,
  ADD_KONTAKT,
}: Props) => {
  const [expectIndex, setExpectIndex] = useState(false)
  const [index, setIndex] = useState<number>(0)
  const [editable, setEditable] = useState(false)
  const [listShown, setListShown] = useState(false)
  const [newKontakt, setNewKontakt] = useState(false)

  const seznamKontaktu = useRef<SeznamKontaktuRef>(null)

  const handleSearch = (query: KontaktSearchQuery) => {
    setListShown(true)
    seznamKontaktu.current?.search(query)
  }

  useEffect(() => {
    if (kontakty.length === 0) GET_KONTAKTY(auth.user.id)
  }, [kontakty, auth.user.id])

  useEffect(() => {
    if(expectIndex) {
      setIndex(kontakty.length)
      setExpectIndex(false)
    }
  }, [kontakty, expectIndex])

  return (
    <KontaktyWrapperContext.Provider
      value={[
        kontakty[index] ? kontakty[index] : initialState,
        (kontakt: KontaktType) => EDIT_KONTAKT(kontakt, auth.user.id),
        (value: number) =>
          setIndex(
            value > kontakty.length - 1 ? index : value < 0 ? index : value
          ),
        (kontakt: KontaktType) => {
          ADD_KONTAKT(kontakt, auth.user.id)
          setExpectIndex(true)
        },
      ]}
    >
      <div className="kontakty-wrapper">
        <div className="title">Kontakty</div>
        <EditableKontaktContext.Provider
          value={[
            editable,
            setEditable,
            newKontakt,
            setNewKontakt,
            listShown,
            setListShown,
          ]}
        >
          <Header kontakty={kontakty} index={index} handleSearch={handleSearch} />
          <Kontakty shown={!listShown} />
          <SeznamKontaktu
            ref={seznamKontaktu}
            shown={listShown}
            setListShown={setListShown}
          />
        </EditableKontaktContext.Provider>
      </div>
    </KontaktyWrapperContext.Provider>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  kontakty: state.kontakty,
})

export default connect(mapStateToProps, {
  GET_KONTAKTY,
  EDIT_KONTAKT,
  ADD_KONTAKT,
})(KontaktyWrapper)
