import React, { useEffect, useState } from "react"
import Checkbox from "../Checkbox/Checkbox"
import Input from "../Input/Input"
import Actions from "../Table/Actions"

type Props = {
  obj: any
  canBeEdited: boolean
  chosen: number[]
  setChosen(value: number[]): void
  index: number
  content: any[]
  setContent(content: any[]): void
}

const Row = ({
  content,
  setContent,
  chosen,
  setChosen,
  canBeEdited,
  obj,
  index,
}: Props) => {
  const [editable, setEditable] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(false)
  const [editableObject, setEditableObject] = useState<any>(obj)

  const handleConfirm = () => {
    if (toBeDeleted) {
      setContent(content.filter((_, i) => i !== index))
      hide()
      return
    }

    let newContent = [...content]
    newContent[index] = editableObject
    setContent(newContent)
    hide()
  }

  useEffect(() => {
    setEditableObject(obj)
  }, [obj])

  const hide = () => {
    setEditable(false)
    setToBeDeleted(false)
  }

  return (
    <tr>
      <td>
        <Checkbox
          checked={chosen.includes(index)}
          onClick={() => {
            if (chosen.includes(index))
              setChosen(chosen.filter((v) => v !== index))
            else setChosen([...chosen, index])
          }}
        />
      </td>
      {Object.keys(editableObject).map((key) =>
        canBeEdited ? (
          <td>
            <Input
              disabled={!editable || toBeDeleted}
              onChange={(e) => {
                let text = e.target.value,
                  _object = { ...editableObject }

                _object[key] = text

                setEditableObject(_object)
              }}
              value={editableObject[key]}
              style={{
                background: "transparent",
                border: "none",
                borderBottom: "2px solid #6b6b6b",
              }}
            />
          </td>
        ) : (
          <td>{obj[key]}</td>
        )
      )}
      {canBeEdited ? (
        <>
          <Actions
            active={editable}
            confirm={handleConfirm}
            close={() => {
              if (!toBeDeleted) {
                setEditableObject(obj)
              }

              hide()
            }}
            handleEdit={() => {
              setEditable(true)
              setToBeDeleted(false)
            }}
            handleRemove={() => {
              setEditable(true)
              setToBeDeleted(true)
            }}
          />
        </>
      ) : null}
    </tr>
  )
}

export default Row
