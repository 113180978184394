import { IconButton, Tooltip } from "@material-ui/core"
import React from "react"
import { RiCloseLine } from "react-icons/ri"
import "./Criteria.scss"
import { Filter, useFilterCriteria } from "./Obraty"
import { IS_OBJECT_DEFINED } from "./utils"

const getHeaderNazev = (key: keyof Filter) => {
  switch (key) {
    case "kontakt":
      return "Kontakt"
    case "rok":
      return "Rok"
    case "obchodnik":
      return "Obchodník"
    case "obec":
      return "Mesto"
    case "okres":
      return "Okres"
    case "skupina":
      return "Skupina"
    case "mesic":
      return "Mesiac"
  }
}

interface SingleCriteriaProps {
  filter: Filter
  clear(): void
}

const SingleCriteria = ({ clear, filter }: SingleCriteriaProps) => {
  return (
    <div className="criteria-wrapper">
      <table className="single-criteria">
        <thead className="criteria-header">
          <tr>
            {Object.keys(filter).map((k, i) => {
              if (IS_OBJECT_DEFINED(filter[k as keyof Filter]))
                return <td key={i}>{getHeaderNazev(k as keyof Filter)}</td>
              return null
            })}
          </tr>
        </thead>
        <tbody className="criteria-values">
          <tr>
            {Object.keys(filter).map((k, i) => {
              if (IS_OBJECT_DEFINED(filter[k as keyof Filter]))
                return <td key={i}>{filter[k as keyof Filter]}</td>
              return null
            })}
          </tr>
        </tbody>
      </table>
      <div className="remove">
        <Tooltip title="Odstranit kritérium">
          <IconButton onClick={clear}>
            <RiCloseLine />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

const Criteria = () => {
  const [filterCriteria, setFilterCriteria] = useFilterCriteria()

  return (
    <div className="filter-criteria">
      <div className="title-wrapper">ZVOLENÁ KRITÉRIA</div>
      {filterCriteria.map((c, i) => (
        <>
          <SingleCriteria
            key={i}
            clear={() =>
              setFilterCriteria(filterCriteria.filter((f, _i) => i !== _i))
            }
            filter={c}
          />
          {filterCriteria.length > 1 && i !== filterCriteria.length - 1 ? (
            <div className="or-criteria">NEBO</div>
          ) : null}
        </>
      ))}
    </div>
  )
}

export default Criteria
