import { ICenik, Item } from "../../ts/Cenik"

const initialState: ICenik = {
    loading: true,
    list: []
}

type Action = {
  type: string
  payload: Item[]
}

export const cenikReducer = (state: ICenik = initialState, action: Action) => {
  switch (action.type) {
    case "SET_CENIK":
      return {
        loading: false,
        list: action.payload,
      }

    default:
      return state
  }
}