import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import "./Header.scss"

import { connect } from "react-redux"
import { Obrat, RawObrat } from "../../../ts/Obraty"
import ReduxState from "../../../ts/Redux"
import { Auth } from "../../../ts/Auth"

import { readString } from "react-papaparse"
import {
  GET_OBRATY_BY_FILTER,
  IS_FILTER_EMPTY,
  MAP_CSV_OBRATY_TO_JSON,
  onlyUnique,
} from "./utils"
import { Errors } from "../../../ts/Errors"
import { ADD_OBRATY } from "../../../redux/actions/obratyActions"
import { FaFileImport } from "react-icons/fa"
import DropdownInput from "../../ui/Input/DropdownInput"
import Button from "../../ui/Button/Button"
import { IoMdAdd } from "react-icons/io"
import { Filter, useFilterCriteria, useObraty } from "./Obraty"
import { BsExclamationTriangle } from "react-icons/bs"
import axios from "../../../axios/axios"
import ImportPopup, {
  ImportPopupInterface,
} from "../../ui/ImportPopup/ImportPopup"

interface Props {
  auth: Auth
}

const Header = ({ auth }: Props) => {
  const [errors, setErrors] = useState<Errors>({})
  const [selectedKontakt, setSelectedKontakt] = useState<string | undefined>(
    undefined
  )
  const [selectedSkupina, setSelectedSkupina] = useState<string | undefined>(
    undefined
  )
  const [selectedMesto, setSelectedMesto] = useState<string | undefined>(
    undefined
  )
  const [selectedOkres, setSelectedOkres] = useState<string | undefined>(
    undefined
  )
  const [selectedObchodnik, setSelectedObchodnik] = useState<
    string | undefined
  >(undefined)
  const [selectedRok, setSelectedRok] = useState<number | undefined>(undefined)
  const [selectedMesiac, setSelectedMesiac] = useState<number | undefined>(
    undefined
  )

  const [obraty, setObraty] = useObraty()
  const [filterCriteria, setFilterCriteria] = useFilterCriteria()

  const ADD_OBRATY = async (obraty: RawObrat[], id: string) => {
    try {
      const req = await axios.post("/obraty/add", {
        obraty,
        id,
      })

      setObraty(req.data)
    } catch (err) {
      setErrors({ import: (err as any).response.data.error })
    }
  }

  const importObraty = (e: ChangeEvent<HTMLInputElement>) => {
    let f = e.target.files?.[0]

    if (f) {
      let r = new FileReader()
      r.onload = async (e) => {
        let contents = e.target?.result
        let csv = readString(contents as string, { header: true })
        let mapped = MAP_CSV_OBRATY_TO_JSON(csv.data)

        if (mapped) {
          setErrors({})
          const req = await axios.post("/obraty/import-preview", {
            data: mapped,
          })

          importPopupRef.current?.showPopup(
            req.data.array.map((i: any) => ({
              id_klienta: i.id_klienta,
              nazov_spolocnosti: i.nazov_spolocnosti,
              rok: i.rok,
              mesiac: i.mesiac,
              suma: i.suma,
            })) as any[],
            (content) => {
              if (content) {
                let _content = content.map((c) => {
                  return {
                    id_klienta: parseInt(c.id_klienta),
                    rok: parseInt(c.rok),
                    mesiac: parseInt(c.mesiac),
                    suma: parseFloat(c.suma),
                  }
                })

                ADD_OBRATY(_content, auth.user.id)
              }
            }
          )
        } else {
          setErrors({ ...errors, import: "Nesprávný formát CSV souboru" })
        }
      }
      r.readAsText(f)
    }
  }

  const CURRENT_FILTER: Filter = {
    kontakt: selectedKontakt,
    skupina: selectedSkupina,
    obec: selectedMesto,
    okres: selectedOkres,
    obchodnik: selectedObchodnik,
    rok: selectedRok,
    mesic: selectedMesiac,
  }

  const addToFilterCriteria = () => {
    setFilterCriteria([...filterCriteria, CURRENT_FILTER])
    setSelectedKontakt(undefined)
    setSelectedSkupina(undefined)
    setSelectedMesto(undefined)
    setSelectedOkres(undefined)
    setSelectedObchodnik(undefined)
    setSelectedRok(undefined)
    setSelectedMesiac(undefined)
  }

  const FILTERED_OBRATY = GET_OBRATY_BY_FILTER(CURRENT_FILTER, obraty)

  const importPopupRef = useRef<ImportPopupInterface>(null)

  return (
    <>
      <ImportPopup ref={importPopupRef} canBeEdited={true} />
      <div className="obraty-header-wrapper">
        <div className="up"></div>
        <div className="down">
          <div className="in-row">
            <div>
              <DropdownInput
                freeSolo={false}
                htmlId="kontakt-vyber"
                datalist={obraty
                  .map((o) => (o.kontakt ? o.kontakt.nazev : ""))
                  .filter(onlyUnique)
                  .filter((val) => val !== "")}
                setValue={(item: string) => setSelectedKontakt(item)}
                canBeDeleted={true}
                title="Kontakt"
                value={selectedKontakt}
              />
            </div>
            <div>
              <DropdownInput
                canBeDeleted={true}
                freeSolo={false}
                htmlId="skupina-vyber"
                datalist={obraty
                  .map((o) => (o.kontakt ? o.kontakt.skupina : ""))
                  .filter(onlyUnique)
                  .filter((val) => val !== "")}
                setValue={(value) => setSelectedSkupina(value)}
                title="Skupina"
                value={selectedSkupina}
              />
            </div>
          </div>
          <div className="in-row">
            <div>
              <DropdownInput
                canBeDeleted={true}
                freeSolo={false}
                htmlId="mesto-vyber"
                datalist={obraty
                  .map((o) => (o.kontakt ? o.kontakt.obec : ""))
                  .filter(onlyUnique)
                  .filter((val) => val !== "")}
                setValue={(value) => setSelectedMesto(value)}
                title="Mesto"
                value={selectedMesto}
              />
            </div>
            <div>
              <DropdownInput
                canBeDeleted={true}
                freeSolo={false}
                htmlId="okres-vyber"
                datalist={obraty
                  .map((o) => (o.kontakt ? o.kontakt.okres : ""))
                  .filter(onlyUnique)
                  .filter((val) => val !== "")}
                setValue={(value) => setSelectedOkres(value)}
                title="Okres"
                value={selectedOkres}
              />
            </div>
            <div>
              <DropdownInput
                canBeDeleted={true}
                freeSolo={false}
                htmlId="obchodnik-vyber"
                datalist={obraty
                  .map((o) => (o.kontakt ? o.kontakt.obchodnik : ""))
                  .filter(onlyUnique)
                  .filter((val) => val !== "")}
                setValue={(value) => setSelectedObchodnik(value)}
                title="Obchodník"
                value={selectedObchodnik}
              />
            </div>
          </div>

          <div className="in-row">
            <div className="in-row full-width">
              <div>
                <DropdownInput
                  canBeDeleted={true}
                  freeSolo={false}
                  htmlId="rok-vyber"
                  datalist={obraty
                    .map((o) => o.rok.toString())
                    .filter(onlyUnique)}
                  setValue={(value) => setSelectedRok(parseInt(value))}
                  title="Rok"
                  value={selectedRok}
                />
              </div>
              <div>
                <DropdownInput
                  canBeDeleted={true}
                  freeSolo={false}
                  htmlId="mesic-vyber"
                  datalist={obraty
                    .map((o) => o.mesiac.toString())
                    .filter(onlyUnique)}
                  setValue={(value) => setSelectedMesiac(parseInt(value))}
                  title="Mesiac"
                  value={selectedMesiac}
                />
              </div>
            </div>
            <div>
              {auth.user.function === "root" ? (
                <div>
                  <label htmlFor="obraty-import">
                    <div
                      className="styled-button"
                      style={{
                        marginTop: 16,
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <FaFileImport
                        size={16}
                        style={{
                          marginRight: 10,
                        }}
                      />{" "}
                      IMPORT OBRATOV
                    </div>
                  </label>
                  {errors.import ? (
                    <div className="error-message import">{errors.import}</div>
                  ) : null}
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    accept=".csv"
                    onClick={(
                      e: React.MouseEvent<HTMLInputElement, MouseEvent>
                    ) => {
                      const element = e.target as HTMLInputElement
                      element.value = ""
                    }}
                    onChange={importObraty}
                    id="obraty-import"
                  />
                </div>
              ) : null}
            </div>
            <div className="submit-obrat-wrapper">
              <Button
                style={{
                  marginTop: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled={IS_FILTER_EMPTY(CURRENT_FILTER)}
                onClick={addToFilterCriteria}
                error={FILTERED_OBRATY.length === 0 && obraty.length !== 0}
              >
                {" "}
                <IoMdAdd
                  size={16}
                  style={{
                    marginRight: 10,
                  }}
                />{" "}
                PRIDAŤ DO VÝBERU
              </Button>
              {FILTERED_OBRATY.length === 0 && obraty.length !== 0 ? (
                <div className="error-wrapper">
                  <span className="wrong">
                    <BsExclamationTriangle />
                  </span>{" "}
                  Zvolená kritéria neodpovídají žádným záznamům v databázi
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(Header)
