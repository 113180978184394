import { combineReducers } from "redux"
import { authReducer } from "./authReducer"
import { cpReducer } from "./cpReducer"
import { errorsReducer } from "./errorReducer"
import { kontaktyReducer } from "./kontaktyReducer"
import { skupinyReducer } from "./skupinyReducer"
import { obratyReducer } from "./obratyReducer"
import { cenikReducer } from "./cenikReducer"

export default combineReducers({
  auth: authReducer,
  cp: cpReducer,
  cs_skupiny: skupinyReducer,
  errors: errorsReducer,
  kontakty: kontaktyReducer,
  obraty: obratyReducer,
  cenik: cenikReducer
})
