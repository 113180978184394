import React, { useEffect, useRef, useState } from "react";
import "./Header.scss";
import {
  CenovaPonuka as CenovaPonukaType,
  SearchQuery,
  State,
} from "../../../ts/CP";
import Button from "../../ui/Button/Button";
import Dropdown from "../../ui/Dropdown/Dropdown";
import { useCenovaPonuka, useEditable } from "./CenovePonuky";

import { BsExclamationTriangle, BsSearch } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoMdList, IoMdClose, IoIosCopy, IoMdAdd } from "react-icons/io";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";

import {
  CREATE_COPY,
  DELETE_CP,
  EDIT_CP_STATE,
} from "../../../redux/actions/cpActions";
import { connect } from "react-redux";
import Confirmation from "../../ui/Confirmation/Confirmation";
import { ConfirmationType } from "../../../ts/UI";
import { Link } from "react-router-dom";
import generateCenovaPonuka from "../../../pdf/CenovaPonuka";
import Input from "../../ui/Input/Input";
import ReduxState from "../../../ts/Redux";
import { Auth } from "../../../ts/Auth";
import generateZalohovaFaktura from "../../../pdf/ZalohovaFaktura";
import generateDodaciList from "../../../pdf/DodaciList";
import FakturaPreview, { FakturaPreviewType } from "./FakturaPreview";
import {
  getNextCPState,
  getNextCPStateRaw,
  getObchodnikById,
} from "../../../utils";
import StateChange from "../../ui/StateChange/StateChange";

interface Props {
  index: number;
  length: number;
  DELETE_CP(cp: CenovaPonukaType, id: string): void;
  CREATE_COPY(cp: CenovaPonukaType, id: string): void;
  EDIT_CP_STATE(cp: CenovaPonukaType, auth: Auth, state: State): void;
  handleSearch(query: SearchQuery): void;
  auth: Auth;
}

const Header: React.FC<Props> = ({
  index,
  length,
  DELETE_CP,
  EDIT_CP_STATE,
  CREATE_COPY,
  handleSearch,
  auth,
}: Props) => {
  const [cenovaPonuka, , setIndex] = useCenovaPonuka();
  const [editable, setEditable, listShown, setListShown, newCP, setNewCP] =
    useEditable();
  const [searchActive, setSearchActive] = useState(false);
  const [query, setQuery] = useState<SearchQuery>({
    cislo_ponuky: "",
    objednavatel: "",
    prijimatel: "",
  });

  const searchClick = () => {
    if (
      query.cislo_ponuky === "" &&
      query.objednavatel === "" &&
      query.prijimatel === ""
    )
      return;

    setSearchActive(false);
    setQuery({
      cislo_ponuky: "",
      objednavatel: "",
      prijimatel: "",
    });
    handleSearch(query);
  };

  const inputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") searchClick();
  };

  const deleteConfirmation = useRef<ConfirmationType>(null);
  const stateConfirmation = useRef<ConfirmationType>(null);
  const previewRef = useRef<FakturaPreviewType>(null);

  const isEditableOrListShown = listShown || editable;

  if (length === 0)
    return (
      <div className="cenovaPonukaHeader">
        <div className="up">
          <div>
            <Button disabled={editable} onClick={() => setNewCP(!newCP)}>
              {newCP ? (
                <>
                  {" "}
                  <IoMdClose
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  ZRUŠIT
                </>
              ) : (
                <>
                  {" "}
                  <IoMdAdd
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  VYTVORIŤ
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    );

  if (!cenovaPonuka || !setIndex) return null;

  return (
    <>
      <FakturaPreview ref={previewRef} />
      <div className="cenovaPonukaHeader">
        <div className="up">
          <div>
            <Button
              disabled={listShown || cenovaPonuka.state === "finished" || newCP}
              onClick={() => {
                setEditable(!editable);
              }}
            >
              {editable ? (
                <>
                  {" "}
                  <IoMdClose
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  ZRUŠIT
                </>
              ) : (
                <>
                  {" "}
                  <BiEditAlt
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  UPRAVIŤ
                </>
              )}
            </Button>
          </div>

          <div
            style={{
              position: "relative",
            }}
          >
            <Confirmation
              title="Odstranit cenovú ponuku?"
              yes={() => {
                DELETE_CP(cenovaPonuka, auth.user.id);
                setIndex(index - 1);
              }}
              ref={deleteConfirmation}
              style={{
                top: "-100%",
                left: "-12.5%",
                whiteSpace: "nowrap",
              }}
            />
            <Button
              onClick={() => deleteConfirmation.current?.clickHandler()}
              disabled={
                isEditableOrListShown ||
                cenovaPonuka.state === "finished" ||
                newCP
              }
            >
              {" "}
              <RiDeleteBin7Line
                size={16}
                style={{
                  marginRight: 10,
                  marginTop: -2,
                }}
              />{" "}
              ODSTRÁNIŤ
            </Button>
          </div>
          <div>
            <Button
              disabled={editable || listShown}
              onClick={() => setNewCP(!newCP)}
            >
              {newCP ? (
                <>
                  {" "}
                  <IoMdClose
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  ZRUŠIT
                </>
              ) : (
                <>
                  {" "}
                  <IoMdAdd
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  VYTVORIŤ
                </>
              )}
            </Button>
          </div>
          <div>
            <Button
              disabled={editable || newCP}
              onClick={() => setSearchActive(!searchActive)}
            >
              {" "}
              <BsSearch
                size={16}
                style={{
                  marginRight: 10,
                  marginTop: -2,
                }}
              />{" "}
              VYHĽADAŤ
            </Button>
          </div>
          <div
            style={{
              position: "relative",
            }}
          >
            <StateChange
              title={`Zmeniť stav cenové ponuky na ${getNextCPState(
                cenovaPonuka.state
              ).toLowerCase()}?`}
              yes={(state) => EDIT_CP_STATE(cenovaPonuka, auth, state)}
              ref={stateConfirmation}
              style={{
                top: "-100%",
                left: "-12.5%",
                whiteSpace: "nowrap",
              }}
            />
            <Button
              onClick={() => stateConfirmation.current?.clickHandler()}
              disabled={isEditableOrListShown || newCP}
            >
              {" "}
              <BsExclamationTriangle
                size={16}
                style={{
                  marginRight: 10,
                  marginTop: -2,
                }}
              />{" "}
              STAV
            </Button>
          </div>
        </div>
        {searchActive ? (
          <div className="searchCP">
            <Input
              value={query.cislo_ponuky}
              onChange={(e) =>
                setQuery({ ...query, cislo_ponuky: e.target.value })
              }
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Číslo ponuky"
            />
            <Input
              value={query.objednavatel}
              onChange={(e) =>
                setQuery({ ...query, objednavatel: e.target.value })
              }
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Objednávatel - název"
            />
            <Input
              value={query.prijimatel}
              onChange={(e) =>
                setQuery({ ...query, prijimatel: e.target.value })
              }
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Prijímateľ - název"
            />
            <Button
              disabled={
                query.cislo_ponuky === "" &&
                query.objednavatel === "" &&
                query.prijimatel === ""
              }
              onClick={searchClick}
              style={{
                display: "flex",
              }}
            >
              {" "}
              <BsSearch
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              HLADAŤ
            </Button>
          </div>
        ) : null}
        <div className="middle">
          <div>
            <Button
              disabled={newCP || isEditableOrListShown}
              onClick={() => CREATE_COPY(cenovaPonuka, auth.user.id)}
            >
              {" "}
              <IoIosCopy
                size={16}
                style={{
                  marginRight: 10,
                  marginTop: -2,
                }}
              />{" "}
              VYTVORIŤ KÓPIU
            </Button>
          </div>
          <div>
            <Dropdown
              style={{
                width: 140,
              }}
              disabled={isEditableOrListShown || newCP}
              title="PDF"
              list={[
                {
                  title: "Cenová ponuka",
                  onClick: async () => {
                    if (
                      !cenovaPonuka.last_edited_by ||
                      !cenovaPonuka.last_edited_date
                    ) {
                      cenovaPonuka.last_edited_by = await getObchodnikById(
                        cenovaPonuka.author ? cenovaPonuka.author : ""
                      );
                      cenovaPonuka.last_edited_date = cenovaPonuka.date;
                    }

                    const { url, doc, fileName } = await generateCenovaPonuka(
                      cenovaPonuka
                    );
                    previewRef.current?.showPreview(url, doc, fileName);
                  },
                },
                {
                  title: "Zálohová faktúra",
                  onClick: async () => {
                    if (
                      !cenovaPonuka.last_edited_by ||
                      !cenovaPonuka.last_edited_date
                    ) {
                      cenovaPonuka.last_edited_by = await getObchodnikById(
                        cenovaPonuka.author ? cenovaPonuka.author : ""
                      );
                      cenovaPonuka.last_edited_date = cenovaPonuka.date;
                    }

                    const { url, doc, fileName } =
                      await generateZalohovaFaktura(cenovaPonuka);
                    previewRef.current?.showPreview(url, doc, fileName);
                  },
                },
                {
                  title: "Dodací list",
                  onClick: async () => {
                    if (
                      !cenovaPonuka.last_edited_by ||
                      !cenovaPonuka.last_edited_date
                    ) {
                      cenovaPonuka.last_edited_by = await getObchodnikById(
                        cenovaPonuka.author ? cenovaPonuka.author : ""
                      );
                      cenovaPonuka.last_edited_date = cenovaPonuka.date;
                    }

                    const { url, doc, fileName } =
                      generateDodaciList(cenovaPonuka);
                    previewRef.current?.showPreview(url, doc, fileName);
                  },
                },
              ]}
            />
          </div>

          <div>
            <Button
              disabled={editable || newCP}
              onClick={() => setListShown(!listShown)}
            >
              {" "}
              <IoMdList
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              {listShown ? "ZATVORIŤ" : "ZOZNAM"}
            </Button>
          </div>
          <div>
            <Link to="/cennik">
              <Button disabled={editable || newCP}>
                {" "}
                <FaMoneyCheckAlt
                  size={16}
                  style={{
                    marginRight: 10,
                  }}
                />{" "}
                CENNÍK
              </Button>
            </Link>
          </div>
        </div>
        <div className="down">
          <div>
            <Button
              color="dark"
              disabled={isEditableOrListShown || index === 0 || newCP}
              onClick={() => setIndex(index - 1)}
            >
              {" "}
              <MdChevronLeft
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              PREDCHÁDZAJÚCI ZÁZNAM
            </Button>
          </div>
          <div>
            <Button
              color="dark"
              disabled={
                isEditableOrListShown ||
                index === length - 1 ||
                length === 0 ||
                newCP
              }
              onClick={() => setIndex(index + 1)}
            >
              ĎALŠÍ ZÁZNAM{" "}
              <MdChevronRight
                size={16}
                style={{
                  marginLeft: 10,
                }}
              />{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  DELETE_CP,
  EDIT_CP_STATE,
  CREATE_COPY,
})(Header);
