import classNames from "classnames"
import './AttributeInput.scss'
import React, { InputHTMLAttributes } from "react"
import Input from "./Input"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  title: string
  value: any
  error?: string
  hardDisabled?: boolean
  setValue?(value: any): void
}

const AttributeInput: React.FC<Props> = ({
  title,
  value,
  error,
  setValue,
  hardDisabled,
  ...props
}: Props) => {
  return (
    <div style={props.style} className={classNames("attribute-input", { hardDisabled })}>
      <div className="attribute-input-title">{title}</div>
      <Input
        error={error}
        value={value}
        onChange={(e) => (setValue ? setValue(e.target.value) : null)}
        {...props}
        style={{}}
      />
    </div>
  )
}

export default AttributeInput
