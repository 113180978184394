import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Auth } from "../../ts/Auth"
import ReduxState from "../../ts/Redux"
import "./Footer.scss"

interface Props {
  auth: Auth
}

const Footer: React.FC<Props> = ({ auth }: Props) => {
  return (
    <footer>
      <div className="up">
        {auth.logged ? (
          <div className="user">
            <div className="title">VÁŠ ÚČET</div>
            <div>
              <Link to="/cenove-ponuky">Cenové ponuky</Link>
            </div>
            <div>
              <Link to="/zfadl">Zálohové faktúry</Link>
            </div>
            <div>
              <Link to="/zfadl">Dodacie listy</Link>
            </div>
          </div>
        ) : (
          <div>
            <div className="title">INFORMÁCIE O IS</div>
            <div>ECLISSE Slovakia, s.r.o.</div>
            <div>Zvolenská cesta 23</div>
            <div>974 05 Banská Bystrica</div>
            <div>Slovensko</div>
          </div>
        )}
      </div>

      <div className="down">
        © {new Date().getFullYear()} - ECLISSE Slovakia, s.r.o.
      </div>
    </footer>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(Footer)
