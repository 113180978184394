import React, { useState } from "react";
import "./styles.scss";

import { connect } from "react-redux";
import { Auth } from "../ts/Auth";
import { CenovaPonuka } from "../ts/CP";
import ReduxState from "../ts/Redux";
import Button from "../components/ui/Button/Button";
import { Kontakt } from "../ts/Kontakt";

import * as Papa from "papaparse";
import { getCPDate, getFileDate, getSumNoDPH } from "../utils";
import FileSaver from "file-saver";
import { ObratMesiceSingle } from "../components/main/Obraty/utils";
import { FiSave } from "react-icons/fi";
import axios from "../axios/axios";
import { CSSProperties } from "react";
import { ReactNode } from "react";

const mapCPtoCSV = (_cp: CenovaPonuka[]): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    let mappingCP: any[] = [
      [
        "Číslo ponuky",
        "Objednávateľ",
        "Prijímateľ",
        "Celkom položiek",
        "Cena celkom",
        "Obchodník",
        "Dátum",
      ],
    ];
    mappingCP = [...mappingCP, ..._cp];

    axios
      .post("/kontakty/get-obchodnici-by-kontakt-ids", {
        kontakt_ids: mappingCP
          .map((cp) => (cp.kontakt_id ? cp.kontakt_id : null))
          .filter((value, index, self) => {
            return self.indexOf(value) === index && value;
          }),
      })
      .then(({ data }) => {
        resolve(
          mappingCP.map((cp: any, i) => {
            if (i === 0) return cp;

            return [
              cp.cislo_ponuky,
              cp.objednavatel && cp.objednavatel.nazev
                ? cp.objednavatel.nazev
                : "",
              cp.prijimatel && cp.prijimatel.jmeno && cp.prijimatel.prijmeni
                ? cp.prijimatel.jmeno + " " + cp.prijimatel.prijmeni
                : "",
              cp.polozky.length,
              getSumNoDPH(cp),
              data.find((k: any) => k.id === cp.kontakt_id)?.obchodnik,
              getCPDate(cp.date),
            ];
          })
        );
      });
  });
};

const mapObratyToCSV = (
  obraty: ObratMesiceSingle[],
  mesice: (number | undefined)[]
): any[] => {
  const getMesiceHeader = () => {
    if (mesice.length > 0)
      return mesice.map((m) => {
        if (m === 1) return "Jan";
        if (m === 2) return "Feb";
        if (m === 3) return "Mar";
        if (m === 4) return "Apr";
        if (m === 5) return "Máj";
        if (m === 6) return "Jún";
        if (m === 7) return "Júl";
        if (m === 8) return "Aug";
        if (m === 9) return "Sep";
        if (m === 10) return "Okt";
        if (m === 11) return "Nov";
        if (m === 12) return "Dec";

        return "";
      });

    return [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Máj",
      "Jún",
      "Júl",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];
  };

  let mappingObraty: any[] = [
    [
      "ID - Klient",
      "Spoločnosť",
      "Skupina",
      "Mesto",
      "Okres",
      "Rok",
      ...getMesiceHeader(),
      "Spolu (€)",
      "Obchodník",
    ],
    ...obraty,
  ];

  return mappingObraty.map((o: any, i) => {
    if (i === 0) return o;

    const getMesice = (o: any) => {
      if (mesice.length > 0)
        return mesice.map((m) => {
          if (m === 1) return o.leden;
          if (m === 2) return o.unor;
          if (m === 3) return o.brezen;
          if (m === 4) return o.duben;
          if (m === 5) return o.kveten;
          if (m === 6) return o.cerven;
          if (m === 7) return o.cervenec;
          if (m === 8) return o.srpen;
          if (m === 9) return o.zari;
          if (m === 10) return o.rijen;
          if (m === 11) return o.listopad;
          if (m === 12) return o.prosinec;

          return "";
        });

      return [
        o.leden,
        o.unor,
        o.brezen,
        o.duben,
        o.kveten,
        o.cerven,
        o.cervenec,
        o.srpen,
        o.zari,
        o.rijen,
        o.listopad,
        o.prosinec,
      ];
    };

    return [
      o.id_klienta,
      o.spolocnost,
      o.skupina,
      o.mesto,
      o.okres,
      o.rok,
      ...getMesice(o),
      o.spolu,
      o.obchodnik,
    ];
  });
};

const mapKontaktyToCSV = (kontakty: Kontakt[]): any[] => {
  let mappingKontakty: any[] = [
    [
      "Názov spoločnosti",
      "Skupina",
      "Titul  (objednávateľ)",
      "Meno  (objednávateľ)",
      "Priezvisko  (objednávateľ)",
      "Funkce  (objednávateľ)",
      "Adresa  (objednávateľ)",
      "PSČ  (objednávateľ)",
      "Mesto  (objednávateľ)",
      "Okres  (objednávateľ)",
      "Štát  (objednávateľ)",
      "Telefonné číslo  (objednávateľ)",
      "Mobil  (objednávateľ)",
      "Email  (objednávateľ)",
      "Web  (objednávateľ)",
      "Rabat (%)",
      "Bonus (%)",
      "Splatnosť faktúr",
      "Obchodník",
      "Názov spoločnosti (fakturačná)",
      "Meno (fakturačná)",
      "Priezvisko (fakturačná)",
      "Adresa (fakturačná)",
      "PSČ (fakturačná)",
      "Mesto (fakturačná)",
      "Email (fakturačná)",
      "Okres (fakturačná)",
      "IČO (fakturačná)",
      "DIČ (fakturačná)",
      "IČO DPH (fakturačná)",
      "Telefonné číslo (fakturačná)",
    ],
    ...kontakty,
  ];

  return mappingKontakty.map((k: Kontakt, i) => {
    if (i === 0) return k;

    return [
      k.nazev,
      k.skupina,
      k.titul,
      k.jmeno,
      k.prijmeni,
      k.funkce,
      k.adresa,
      k.psc,
      k.obec,
      k.okres,
      k.stat,
      k.tel,
      k.mobil,
      k.email,
      k.web,
      k.rabat,
      k.bonus,
      k.splatnost,
      k.obchodnik,
      k.fakturacna.nazev,
      k.fakturacna.jmeno,
      k.fakturacna.prijmeni,
      k.fakturacna.adresa,
      k.fakturacna.psc,
      k.fakturacna.obec,
      k.fakturacna.email,
      k.fakturacna.okres,
      k.fakturacna.ico,
      k.fakturacna.dic,
      k.fakturacna.icodph,
      k.fakturacna.tel,
    ];
  });
};

const EXPORT_CP = (cp: CenovaPonuka[]) => {
  return new Promise(async (resolve, reject) => {
    let csv = Papa.unparse(await mapCPtoCSV(cp), {
      header: false,
      delimiter: ";",
    });

    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(csvData, `CP-export-${getFileDate()}.csv`);
    resolve(true);
  });
};

const EXPORT_OBRATY = (
  obraty: ObratMesiceSingle[],
  mesice: (number | undefined)[]
) => {
  return new Promise((resolve, reject) => {
    let csv = Papa.unparse(mapObratyToCSV(obraty, mesice), {
      header: false,
      delimiter: ";",
    });

    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(csvData, `obraty-export-${getFileDate()}.csv`);
    resolve(true);
  });
};

const EXPORT_KONTAKTY = (kontakty: Kontakt[]) => {
  return new Promise((resolve, reject) => {
    let csv = Papa.unparse(mapKontaktyToCSV(kontakty), {
      header: false,
      delimiter: ";",
    });

    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(csvData, `kontakty-export-${getFileDate()}.csv`);
    resolve(true);
  });
};
interface Props {
  auth: Auth;
  list: CenovaPonuka[] | ObratMesiceSingle[] | Kontakt[];
  type: "cp" | "obraty" | "kontakty";
  isSkupina?: boolean;
  mesice?: (number | undefined)[];
  label?: string;
  wrapperStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  icon?: ReactNode;
}

const ExportButton = ({
  auth,
  list,
  type,
  isSkupina,
  mesice,
  label = "EXPORTOVAŤ DO CSV",
  wrapperStyle,
  buttonStyle,
  icon,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (type === "cp") {
      setLoading(true);
      await EXPORT_CP(list as CenovaPonuka[]);
      setLoading(false);
      return;
    }

    if (type === "obraty") {
      setLoading(true);
      await EXPORT_OBRATY(list as ObratMesiceSingle[], mesice as number[]);
      setLoading(false);
      return;
    }

    if (type === "kontakty") {
      setLoading(true);
      await EXPORT_KONTAKTY(list as Kontakt[]);
      setLoading(false);
      return;
    }
  };

  if (auth && auth.user && auth.user.function !== "root") return null;

  return (
    <div className="export-button-wrapper" style={wrapperStyle}>
      <Button
        style={{
          width: 245,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...buttonStyle,
        }}
        loading={loading}
        color="primary"
        onClick={handleClick}
      >
        {icon ? (
          icon
        ) : (
          <FiSave
            size={24}
            style={{
              marginRight: 12,
            }}
          />
        )}
        {label}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ExportButton);
