import React, { ComponentType } from "react"
import { connect } from "react-redux"
import { Route, RouteComponentProps, RouteProps } from "react-router-dom"
import { Auth } from "../ts/Auth"
import ReduxState from "../ts/Redux"
import Uvod from "../components/main/Uvod/Uvod"

interface Props {
  auth: Auth
  component: ComponentType<any>
}

const AdminRoute: React.FC<Props & RouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {
  if (!Component) return null
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>) =>
        auth && auth.user && auth.user.function === "root" ? <Component {...props} /> : <Uvod />
      }
    />
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(AdminRoute)
