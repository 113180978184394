import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import axios from "../../../axios/axios"
import ExportButton from "../../../exports/ExportButton"
import { Auth } from "../../../ts/Auth"
import { Obrat } from "../../../ts/Obraty"
import ReduxState from "../../../ts/Redux"
import { Column } from "../../../ts/UI"
import Checkbox from "../../ui/Checkbox/Checkbox"
import Table from "../../ui/Table/Table"
import { useFilterCriteria, useObraty } from "./Obraty"
import "./ObratyTable.scss"
import {
  GET_FILTERED_OBRATY,
  GET_FILTERED_OLD_OBRATY,
  GET_SUM_OBRATY,
  Mesic,
  MesicString,
  ObratMesice,
  ObratMesiceSingle,
  onlyUnique,
} from "./utils"

const oldObratyColumns: Column[] = [
  {
    key: "_id",
    visible: false,
  },
  {
    key: "id_klienta",
    title: "ID - Klient",
  },
  {
    key: "spolocnost",
    title: "SPOLOČNOSŤ",
    editable: false,
  },
  {
    key: "rok",
    title: "ROK",
    type: "number",
  },
  {
    key: "mesiac",
    title: "MESIAC",
    type: "number",
  },
  {
    key: "suma",
    title: "SPOLU",
    type: "number",
  },
  {
    key: "actions",
    width: "5%",
  },
]

const getObratyColumns = (mesice: (number | undefined)[]): Column[] => {
  const getMesice = (): Column[] => {
    let mesiceActive = mesice.length > 0

    if (!mesiceActive)
      return [
        {
          key: "leden",
          title: "Jan",
        },
        {
          key: "unor",
          title: "Feb",
        },
        {
          key: "brezen",
          title: "Mar",
        },
        {
          key: "duben",
          title: "Apr",
        },
        {
          key: "kveten",
          title: "Máj",
        },
        {
          key: "cerven",
          title: "Jún",
        },
        {
          key: "cervenec",
          title: "Júl",
        },
        {
          key: "srpen",
          title: "Aug",
        },
        {
          key: "zari",
          title: "Sep",
        },
        {
          key: "rijen",
          title: "Okt",
        },
        {
          key: "listopad",
          title: "Nov",
        },
        {
          key: "prosinec",
          title: "Dec",
        },
      ]

    return mesice.map((m) => {
      if (m === 1)
        return {
          key: "leden",
          title: "Jan",
        }
      if (m === 2)
        return {
          key: "unor",
          title: "Feb",
        }
      if (m === 3)
        return {
          key: "brezen",
          title: "Mar",
        }
      if (m === 4)
        return {
          key: "duben",
          title: "Apr",
        }
      if (m === 5)
        return {
          key: "kveten",
          title: "Máj",
        }
      if (m === 6)
        return {
          key: "cerven",
          title: "Jún",
        }
      if (m === 7)
        return {
          key: "cervenec",
          title: "Júl",
        }
      if (m === 8)
        return {
          key: "srpen",
          title: "Aug",
        }
      if (m === 9)
        return {
          key: "zari",
          title: "Sep",
        }
      if (m === 10)
        return {
          key: "rijen",
          title: "Okt",
        }
      if (m === 11)
        return {
          key: "listopad",
          title: "Nov",
        }
      if (m === 12)
        return {
          key: "prosinec",
          title: "Dec",
        }

      return {
        key: "",
        title: "",
      }
    })
  }

  return [
    {
      key: "_id",
      visible: false,
    },
    {
      key: "id_klienta",
      title: "ID - Klient",
    },
    {
      key: "spolocnost",
      title: "SPOLOČNOSŤ",
    },
    {
      key: "rok",
      title: "ROK",
    },
    ...getMesice(),
    {
      key: "spolu",
      title: "SPOLU",
      editable: false,
    },
  ]
}

const sumaForMesic = (
  mesic: MesicString,
  mesice: Mesic[]
): number | undefined => {
  let m = mesice.find((v) => v.nazev === mesic)

  if (m && m.suma) return m.suma

  return undefined
}

interface Props {
  auth: Auth
}

const ObratyTable = ({ auth }: Props) => {
  const [obraty, setObraty] = useObraty()
  const [filters] = useFilterCriteria()
  const [oldObraty, setOldObraty] = useState(false)

  const mesice = filters
    .map((f) => f.mesic)
    .filter(onlyUnique)
    .sort((a, b) => (a ?? 0) - (b ?? 0))
    .filter((v) => v !== undefined)

  const GET_OBRATY_FOR_TABLE = (): ObratMesice[] => {
    if (filters.length === 0) {
      return GET_SUM_OBRATY(obraty)
    }

    return GET_FILTERED_OBRATY(filters, obraty)
  }

  const GET_OLD_OBRATY_FOR_TABLE = (): Obrat[] => {
    if (filters.length === 0) {
      return obraty
    }

    return GET_FILTERED_OLD_OBRATY(filters, obraty)
  }

  const deleteObrat = async (_id: string) => {
    const req = await axios.post("/obraty/delete", {
      user: auth.user.id,
      id: _id,
    })

    setObraty(req.data)
  }

  const editObrat = async (o: any) => {
    const req = await axios.post("/obraty/edit", {
      user: auth.user.id,
      obrat: o,
    })

    setObraty(req.data)
  }

  const isFilterSkupina = Boolean(
    filters.find((f) => f.skupina) && !filters.find((f) => f.kontakt)
  )

  const mapObratForMesice = (
    o: ObratMesice,
    isForExport: boolean
  ): ObratMesiceSingle => {
    let leden = sumaForMesic("leden", o.mesice),
      unor = sumaForMesic("unor", o.mesice),
      brezen = sumaForMesic("brezen", o.mesice),
      duben = sumaForMesic("duben", o.mesice),
      kveten = sumaForMesic("kveten", o.mesice),
      cerven = sumaForMesic("cerven", o.mesice),
      cervenec = sumaForMesic("cervenec", o.mesice),
      srpen = sumaForMesic("srpen", o.mesice),
      zari = sumaForMesic("zari", o.mesice),
      rijen = sumaForMesic("rijen", o.mesice),
      listopad = sumaForMesic("listopad", o.mesice),
      prosinec = sumaForMesic("prosinec", o.mesice)

    let returningValue = {
      _id: o._id,
      id_klienta: o.kontakt.id_klienta as string,
      spolocnost: o.kontakt.nazev,
      leden: leden ? leden + " €" : leden,
      unor: unor ? unor + " €" : unor,
      brezen: brezen ? brezen + " €" : brezen,
      duben: duben ? duben + " €" : duben,
      kveten: kveten ? kveten + " €" : kveten,
      cerven: cerven ? cerven + " €" : cerven,
      cervenec: cervenec ? cervenec + " €" : cervenec,
      srpen: srpen ? srpen + " €" : srpen,
      zari: zari ? zari + " €" : zari,
      rijen: rijen ? rijen + " €" : rijen,
      listopad: listopad ? listopad + " €" : listopad,
      prosinec: prosinec ? prosinec + " €" : prosinec,
      rok: o.rok,
      spolu: isForExport
        ? o.spolu?.toFixed(2).replace(".", ",")
        : o.spolu + " €",
    }

    return isForExport
      ? {
          ...returningValue,
          leden: leden?.toFixed(2).replace(".", ","),
          unor: unor?.toFixed(2).replace(".", ","),
          brezen: brezen?.toFixed(2).replace(".", ","),
          duben: duben?.toFixed(2).replace(".", ","),
          kveten: kveten?.toFixed(2).replace(".", ","),
          cerven: cerven?.toFixed(2).replace(".", ","),
          cervenec: cervenec?.toFixed(2).replace(".", ","),
          srpen: srpen?.toFixed(2).replace(".", ","),
          zari: zari?.toFixed(2).replace(".", ","),
          rijen: rijen?.toFixed(2).replace(".", ","),
          listopad: listopad?.toFixed(2).replace(".", ","),
          prosinec: prosinec?.toFixed(2).replace(".", ","),
          spolocnost: o.kontakt.nazev,
          skupina: o.kontakt.skupina,
          okres: o.kontakt.okres,
          mesto: o.kontakt.obec,
          obchodnik: o.kontakt.obchodnik,
        }
      : returningValue
  }

  return (
    <div className="obraty-table">
      <div className="sum-checkboxes-wrapper">
        <div>
          <Checkbox
            checked={oldObraty}
            onClick={() => setOldObraty(!oldObraty)}
          />
          <div>ZOBRAZIŤ JEDNOTLIVÉ ZÁZNAMY</div>
        </div>
      </div>
      {oldObraty ? (
        <Table
          actions
          editHandler={(o) => editObrat(o)}
          deleteHandler={(o) => deleteObrat(o._id)}
          columns={oldObratyColumns}
          data={GET_OLD_OBRATY_FOR_TABLE().map((o) => {
            return {
              _id: o._id,
              id_klienta: o.kontakt.id_klienta as string,
              spolocnost: o.kontakt.nazev,
              rok: o.rok,
              mesiac: o.mesiac,
              suma: o.suma,
            }
          })}
        />
      ) : null}
      {!oldObraty ? (
        <Table
          columns={getObratyColumns(mesice)}
          data={GET_OBRATY_FOR_TABLE().map((o) => mapObratForMesice(o, false))}
        />
      ) : null}
      <ExportButton
        type="obraty"
        isSkupina={isFilterSkupina}
        list={GET_OBRATY_FOR_TABLE().map((o) => mapObratForMesice(o, true))}
        mesice={mesice}
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(ObratyTable)
