import { Kontakt } from "../../ts/Kontakt"

type Action = {
  type: string
  payload: any
}

export const kontaktyReducer = (state: Kontakt[] = [], action: Action) => {
  switch (action.type) {
    case "EDIT_KONTAKT": {
      const kontakt: Kontakt = action.payload
      let editedKontakty = [...state]
      editedKontakty[editedKontakty.findIndex((k) => k.id === kontakt.id)] =
        kontakt
      return editedKontakty
    }
    case "ADD_KONTAKT":
      return [...state, action.payload]
    case "ADD_KONTAKTY":
      return [...state, ...action.payload]
    case "SET_KONTAKTY":
      return action.payload
    case "DELETE_KONTAKT": {
      const id = action.payload
      return state.filter((k) => k.id !== id)
    }
    default:
      return state
  }
}
