import { Provider } from "react-redux"
import "./App.scss"

import { BrowserRouter as Router} from "react-router-dom"

import { store } from "./redux/store"
import Header from "./components/Header/Header"

import Footer from "./components/Footer/Footer"
import MainSwitch from "./components/MainRouter/MainSwitch"

import { setAuthToken } from "./utils"
import jwt_decode from 'jwt-decode'

import { SET_USER, LOGOUT_USER } from './redux/actions/authActions'

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(SET_USER(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(LOGOUT_USER);
    window.location.href = "/login";
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Header />
          <MainSwitch />
        <Footer />
      </Router>
    </Provider>
  )
}

export default App
