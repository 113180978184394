import { Skupina } from "../../ts/CSSkupiny"

const initialState: Skupina[] = []

type Action = {
  type: string
  payload: any
}

export const skupinyReducer = (
  state: Skupina[] = initialState,
  action: Action
) => {
  switch (action.type) {
    case "ADD_SKUPINA":
      return [...state, {...action.payload, author: true}]
    case "DELETE_SKUPINA":
      return state.filter((s: Skupina) => s.id !== action.payload.id);
    case "SET_SKUPINY":
      return action.payload
    case "EDIT_SKUPINA":
      const cp: Skupina = action.payload
      let editedCenovePonuky = [...state]
      editedCenovePonuky[editedCenovePonuky.findIndex((c) => c.id === cp.id)] =
        cp
      return editedCenovePonuky
    default:
      return state
  }
}
