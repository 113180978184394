import { useEffect, useRef, useState } from "react"
import "./Loading.scss"

interface Props {
  text?: string | string[]
  interval?: number
  size?: number
  border?: number
}

const Loading = ({ text, interval, size, border }: Props) => {
  const [activeText, setActiveText] = useState(
    Array.isArray(text) ? text[0] : text
  )

  let begin = 0

  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let canUpdate = true

    setTimeout(() => {
      if (Array.isArray(text) && begin + 1 < text.length && canUpdate)
        setActiveText(text[begin + 1])
    }, interval)

    return () => {
      canUpdate = false
    }
  }, [interval])

  return (
    <div className="loader-wrapper">
      <div
        className="loader"
        style={{
          width: size ? size : 42,
          height: size ? size : 42,
          borderTopWidth: border ? border : 6,
          borderWidth: border ? border : 6,
          borderStyle: "solid",
        }}
      />
      {text ? (
        <div className="text" ref={textRef}>
          {activeText}
        </div>
      ) : null}
    </div>
  )
}

export default Loading
