import React, { useEffect, useState } from "react"
import "./Obraty.scss"
import { connect } from "react-redux"
import axios from "../../../axios/axios"
import { Auth } from "../../../ts/Auth"
import { Obrat } from "../../../ts/Obraty"
import ReduxState from "../../../ts/Redux"
import Criteria from "./Criteria"
import Header from "./Header"
import ObratyTable from "./ObratyTable"
import { RouteComponentProps } from "react-router"
import { Kontakt } from "../../../ts/Kontakt"

export interface Filter {
  kontakt: string | undefined
  skupina: string | undefined
  obec: string | undefined
  okres: string | undefined
  obchodnik: string | undefined
  rok: number | undefined
  mesic: number | undefined
}

const FilterCriteriaWrapper = React.createContext<
  [Filter[], (filterCriteria: Filter[]) => void]
>([[], () => null])

export const useFilterCriteria = () => {
  return React.useContext(FilterCriteriaWrapper)
}

const ObratyWrapper = React.createContext<[Obrat[], (val: Obrat[]) => void]>([
  [],
  () => null,
])

export const useObraty = () => {
  return React.useContext(ObratyWrapper)
}

interface Params {
  kontakt: string
}

interface Props extends RouteComponentProps<Params> {
  auth: Auth
}

const Obraty: React.FC<Props> = ({ auth, match }: Props) => {
  const [filterCriteria, setFilterCriteria] = useState<Filter[]>([])
  const [obraty, setObraty] = useState<Obrat[]>([])

  useEffect(() => {
    let isMounted = true

    const GET_OBRATY = async (id: string) => {
      const req = await axios.get("/obraty", {
        params: {
          id,
        },
      })

      if (isMounted) setObraty(req.data)
    }

    if (auth.user.id) GET_OBRATY(auth.user.id)

    return () => {
      isMounted = false
    }
  }, [auth.user.id])

  useEffect(() => {
    let isMounted = true

    const setSearchQuery = async () => {
      const req = await axios.get<Kontakt>("/kontakty/get-by-id", {
        params: {
          kontakt: match.params.kontakt,
        },
      })

      if (isMounted)
        setFilterCriteria([
          {
            kontakt: req.data.nazev,
            obchodnik: undefined,
            obec: undefined,
            okres: undefined,
            rok: undefined,
            skupina: undefined,
            mesic: undefined
          },
        ])
    }

    if (match.params.kontakt) setSearchQuery()

    return () => {
      isMounted = false
    }
  }, [match.params.kontakt])

  return (
    <FilterCriteriaWrapper.Provider value={[filterCriteria, setFilterCriteria]}>
      <ObratyWrapper.Provider value={[obraty, setObraty]}>
        <div className="obraty">
          <div className="title">OBRATY</div>
          <Header />
          {filterCriteria.length > 0 ? <Criteria /> : null}
          <ObratyTable />
        </div>
      </ObratyWrapper.Provider>
    </FilterCriteriaWrapper.Provider>
  )
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(Obraty)
