import { Auth } from "../../ts/Auth"

const initialState: Auth = {
  user: {
    id: "",
    firstName: "",
    lastName: "",
    function: "klient",
    mobil: "",
    eshop_id: "",
    email: "",
    last_online: null
  },
  logged: false,
}

type Action = {
  type: string
  payload: any
}

export const authReducer = (state: Auth = initialState, action: Action) => {
  switch (action.type) {
    case "SET_USER":
      return action.payload
    default:
      return state
  }
}
