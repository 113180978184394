import React, { useEffect, useState } from "react"
import "./CenovaPonuka.scss"
import { useCenovaPonuka, useEditable } from "../CenovePonuky"
import AttributeInput from "../../../ui/Input/AttributeInput"
import Button from "../../../ui/Button/Button"
import { IoMdClose, IoMdCheckmark } from "react-icons/io"
import {
  CenovaPonuka as CenovaPonukaType,
  initialState,
} from "../../../../ts/CP"
import Polozky from "./Polozky"
import Checkbox from "../../../ui/Checkbox/Checkbox"
import DropdownInput, { DataListItem } from "../../../ui/Input/DropdownInput"
import { connect } from "react-redux"
import ReduxState from "../../../../ts/Redux"
import { Kontakt } from "../../../../ts/Kontakt"
import { GET_KONTAKTY } from "../../../../redux/actions/kontaktyActions"
import { Auth } from "../../../../ts/Auth"
import { Link, useHistory } from "react-router-dom"
import { Errors } from "../../../../ts/Errors"
import { SET_ERRORS } from "../../../../redux/actions/errorActions"
import axios from "../../../../axios/axios"
import { formatCPNumber } from "../../../../utils"

interface Props {
  shown: boolean
  kontakty: Kontakt[]
  auth: Auth
  errors: Errors
  GET_KONTAKTY(id: string): void
  SET_ERRORS(payload: any): void
}

const CenovaPonuka: React.FC<Props> = ({
  shown,
  kontakty,
  auth,
  errors,
  GET_KONTAKTY,
  SET_ERRORS,
}: Props) => {
  const [cenovaPonuka, setCenovaPonuka, , addNewCP] = useCenovaPonuka()
  const [editable, setEditable, , , newCP, setNewCP] = useEditable()
  const [editedCenovaPonuka, setEditedCenovaPonuka] =
    useState<CenovaPonukaType>()
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true)

  const history = useHistory()

  useEffect(() => {
    if (kontakty.length === 0) GET_KONTAKTY(auth.user.id)
  }, [kontakty, auth.user.id])

  useEffect(() => {
    const getNewCP = async () => {
      const req = await axios.get("/cp/get-new")

      if (req.data.success && cenovaPonuka && cenovaPonuka.state === "new")
        setEditedCenovaPonuka({
          ...cenovaPonuka,
          cislo_ponuky: req.data.success,
        })
    }

    if (!editable) setEditedCenovaPonuka(cenovaPonuka)

    if (
      editable &&
      (cenovaPonuka?.cislo_ponuky === "" || !cenovaPonuka?.cislo_ponuky)
    )
      getNewCP()

    setSaveButtonEnabled(true)
  }, [editable, cenovaPonuka])

  useEffect(() => {
    setEditedCenovaPonuka(cenovaPonuka)
  }, [cenovaPonuka])

  useEffect(() => {
    const getNewCP = async () => {
      const req = await axios.get("/cp/get-new")

      setEditedCenovaPonuka({
        ...initialState,
        cislo_ponuky: req.data.success ? req.data.success : "",
      })
    }

    if (!newCP && cenovaPonuka) {
      setEditedCenovaPonuka(cenovaPonuka)
      return
    }

    if (newCP && auth.user.id) getNewCP()
  }, [newCP, cenovaPonuka, auth.user.id])

  const closeHandler = () => {
    SET_ERRORS({})

    if (newCP) {
      setNewCP(false)
      return
    }
    setEditable(false)
  }

  const addNewCPHandler = () => {
    if (editedCenovaPonuka && addNewCP) {
      addNewCP(editedCenovaPonuka, (state, id) => {
        if (state) {
          history.push(`/cenove-ponuky/${id}`)
          setNewCP(false)
        }
      })
    }
  }

  const isEditableOrNew = editable || newCP

  if (!editedCenovaPonuka?.id) return null

  if (setCenovaPonuka === undefined) return null

  const editCPHandler = () => {
    setCenovaPonuka(editedCenovaPonuka, (state) => {
      if (state) setEditable(false)
    })
  }

  return (
    <div
      className="cenova-ponuka"
      style={{
        display: shown ? "block" : "none",
      }}
    >
      {editable || newCP ? (
        <div className="editable">
          <Button
            disabled={!saveButtonEnabled}
            onClick={() => {
              setSaveButtonEnabled(false)
              if (newCP) {
                addNewCPHandler()
                setSaveButtonEnabled(true)
                return
              }
              editCPHandler()
            }}
          >
            <IoMdCheckmark
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ULOŽIT
          </Button>
          <Button onClick={() => closeHandler()}>
            <IoMdClose
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ZRUŠIT
          </Button>
        </div>
      ) : null}
      <div className="columns">
        <div className="first">
          {cenovaPonuka?.copy && !editable && !newCP ? (
            <div className="copy-wrapper">
              Kópia{" "}
              <Link to={`/cenove-ponuky/${cenovaPonuka.copy}`}>originálu</Link>
            </div>
          ) : null}
          <div>
            <AttributeInput
              error={errors.cislo_ponuky}
              disabled={true}
              title="Číslo ponuky"
              value={
                editedCenovaPonuka.cislo_ponuky !== undefined
                  ? formatCPNumber(editedCenovaPonuka.cislo_ponuky)
                  : ""
              }
            />
            <div className="section-title">Objednávateľ</div>
            <DropdownInput
              htmlId="klient-spolecnost-input"
              datalist={kontakty.map((k) => {
                return {
                  title: k.nazev,
                  value: k.id,
                }
              })}
              freeSolo={false}
              disabled={!isEditableOrNew}
              title="Klient / Spoločnosť"
              value={
                editedCenovaPonuka.objednavatel?.nazev
                  ? editedCenovaPonuka.objednavatel?.nazev
                  : ""
              }
              clear={() => {
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  kontakt_id: null,
                  fakturacna: {
                    jmeno: "",
                    prijmeni: "",
                    adresa: "",
                    nazev: "",
                    obec: "",
                    psc: "",
                    ico: "",
                    icodph: "",
                    dic: "",
                  },
                  prijimatel: {
                    jmeno: "",
                    prijmeni: "",
                    adresa: "",
                    psc: "",
                    obec: "",
                    mobil: "",
                  },
                  objednavatel: {
                    kontaktna_osoba: "",
                    nazev: "",
                    adresa: "",
                    obec: "",
                    psc: "",
                    tel: "",
                    mobil: "",
                  },
                  rabat: undefined,
                  splatnost: undefined,
                })
              }}
              setValue={(item: DataListItem | string) => {
                if (editable || newCP) {
                  if (typeof item === "object" && item) {
                    let findByTitle = kontakty.find((i) => i.id === item.value)

                    if (findByTitle) {
                      setEditedCenovaPonuka({
                        ...editedCenovaPonuka,
                        kontakt_id: findByTitle.id,
                        objednavatel: {
                          ...editedCenovaPonuka.objednavatel,
                          nazev: findByTitle.nazev,
                          adresa: findByTitle.adresa,
                          obec: findByTitle.obec,
                          psc: findByTitle.psc,
                          kontaktna_osoba:
                            findByTitle.jmeno + " " + findByTitle.prijmeni,
                        },
                        fakturacna: {
                          ...editedCenovaPonuka.fakturacna,
                          nazev: findByTitle.fakturacna?.nazev,
                          adresa: findByTitle.fakturacna?.adresa,
                          obec: findByTitle.fakturacna?.obec,
                          psc: findByTitle.fakturacna?.psc,
                          ico: findByTitle.fakturacna.ico,
                          icodph: findByTitle.fakturacna.icodph,
                          dic: findByTitle.fakturacna.dic,
                        },
                        rabat: findByTitle.rabat,
                        bonus: findByTitle.bonus,
                        splatnost: findByTitle.splatnost,
                      })
                      return
                    }
                  }

                  if (typeof item === "string" && item) {
                    setEditedCenovaPonuka({
                      ...editedCenovaPonuka,
                      objednavatel: {
                        ...editedCenovaPonuka.objednavatel,
                        nazev: item,
                      },
                    })
                  }
                }
              }}
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Kontaktná osoba"
              value={
                editedCenovaPonuka.objednavatel?.kontaktna_osoba !== undefined
                  ? editedCenovaPonuka.objednavatel?.kontaktna_osoba
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  objednavatel: {
                    ...editedCenovaPonuka.objednavatel,
                    kontaktna_osoba: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Adresa"
              value={
                editedCenovaPonuka.objednavatel?.adresa !== undefined
                  ? editedCenovaPonuka.objednavatel?.adresa
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  objednavatel: {
                    ...editedCenovaPonuka.objednavatel,
                    adresa: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Mesto"
              value={
                editedCenovaPonuka.objednavatel?.obec !== undefined
                  ? editedCenovaPonuka.objednavatel?.obec
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  objednavatel: {
                    ...editedCenovaPonuka.objednavatel,
                    obec: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="PSČ"
              value={
                editedCenovaPonuka.objednavatel?.psc !== undefined
                  ? editedCenovaPonuka.objednavatel?.psc
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  objednavatel: {
                    ...editedCenovaPonuka.objednavatel,
                    psc: value,
                  },
                })
              }
            />
          </div>
        </div>
        <div>
          <div
            className="objednavatel-wrapper"
            style={{
              marginTop: 40,
            }}
          >
            <div className="section-title">Fakturačná adresa</div>
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Názov spoločnosti"
              value={
                editedCenovaPonuka.fakturacna?.nazev !== undefined
                  ? editedCenovaPonuka.fakturacna?.nazev
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  fakturacna: {
                    ...editedCenovaPonuka.fakturacna,
                    nazev: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Adresa"
              value={
                editedCenovaPonuka.fakturacna?.adresa !== undefined
                  ? editedCenovaPonuka.fakturacna?.adresa
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  fakturacna: {
                    ...editedCenovaPonuka.fakturacna,
                    adresa: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Mesto"
              value={
                editedCenovaPonuka.fakturacna?.obec !== undefined
                  ? editedCenovaPonuka.fakturacna?.obec
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  fakturacna: {
                    ...editedCenovaPonuka.fakturacna,
                    obec: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="PSČ"
              value={
                editedCenovaPonuka.fakturacna?.psc !== undefined
                  ? editedCenovaPonuka.fakturacna?.psc
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  fakturacna: {
                    ...editedCenovaPonuka.fakturacna,
                    psc: value,
                  },
                })
              }
            />
            <div
              className="in-row"
              style={{
                marginTop: -8,
              }}
            >
              <AttributeInput
                disabled={!isEditableOrNew}
                title="IČO"
                value={
                  editedCenovaPonuka.fakturacna?.ico !== undefined
                    ? editedCenovaPonuka.fakturacna?.ico
                    : ""
                }
                setValue={(value: string) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    fakturacna: {
                      ...editedCenovaPonuka.fakturacna,
                      ico: value,
                    },
                  })
                }
              />
              <AttributeInput
                disabled={!isEditableOrNew}
                title="IČ DPH"
                value={
                  editedCenovaPonuka.fakturacna?.icodph !== undefined
                    ? editedCenovaPonuka.fakturacna?.icodph
                    : ""
                }
                setValue={(value: string) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    fakturacna: {
                      ...editedCenovaPonuka.fakturacna,
                      icodph: value,
                    },
                  })
                }
              />
            </div>
            <div
              style={{
                marginTop: -8,
              }}
            >
              <AttributeInput
                disabled={!isEditableOrNew}
                title="DIČ"
                value={
                  editedCenovaPonuka.fakturacna?.dic !== undefined
                    ? editedCenovaPonuka.fakturacna?.dic
                    : ""
                }
                setValue={(value: string) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    fakturacna: {
                      ...editedCenovaPonuka.fakturacna,
                      dic: value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="second">
          <div className="prijimatel-wrapper">
            <div
              style={{
                visibility: "hidden",
              }}
            >
              <AttributeInput disabled={true} title="a" value={0} />
            </div>

            <div className="section-title">Prijímateľ - dodacia adresa</div>

            <AttributeInput
              disabled={!isEditableOrNew}
              title="Meno"
              value={
                editedCenovaPonuka.prijimatel?.jmeno !== undefined
                  ? editedCenovaPonuka.prijimatel?.jmeno
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  prijimatel: {
                    ...editedCenovaPonuka.prijimatel,
                    jmeno: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Priezvisko"
              value={
                editedCenovaPonuka.prijimatel?.prijmeni !== undefined
                  ? editedCenovaPonuka.prijimatel?.prijmeni
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  prijimatel: {
                    ...editedCenovaPonuka.prijimatel,
                    prijmeni: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Adresa"
              value={
                editedCenovaPonuka.prijimatel?.adresa !== undefined
                  ? editedCenovaPonuka.prijimatel?.adresa
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  prijimatel: {
                    ...editedCenovaPonuka.prijimatel,
                    adresa: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Mesto"
              value={
                editedCenovaPonuka.prijimatel?.obec !== undefined
                  ? editedCenovaPonuka.prijimatel?.obec
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  prijimatel: {
                    ...editedCenovaPonuka.prijimatel,
                    obec: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="PSČ"
              value={
                editedCenovaPonuka.prijimatel?.psc !== undefined
                  ? editedCenovaPonuka.prijimatel?.psc
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  prijimatel: {
                    ...editedCenovaPonuka.prijimatel,
                    psc: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!isEditableOrNew}
              title="Mobil"
              value={
                editedCenovaPonuka.prijimatel?.mobil !== undefined
                  ? editedCenovaPonuka.prijimatel?.mobil
                  : ""
              }
              setValue={(value: string) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  prijimatel: {
                    ...editedCenovaPonuka.prijimatel,
                    mobil: value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="third">
          <div>
            <div className="in-row">
              <div className="checkboxWrapper">
                <div className="checkboxTitle">Dobierka</div>
                <Checkbox
                  disabled={
                    !isEditableOrNew || editedCenovaPonuka.zaloha_platba
                  }
                  onClick={(state: boolean) => {
                    setEditedCenovaPonuka({
                      ...editedCenovaPonuka,
                      dobierka: state,
                    })
                  }}
                  checked={editedCenovaPonuka.dobierka}
                />
              </div>
              <div className="checkboxWrapper">
                <div className="checkboxTitle">Zálohová platba</div>
                <Checkbox
                  disabled={!isEditableOrNew || editedCenovaPonuka.dobierka}
                  onClick={(state: boolean) => {
                    setEditedCenovaPonuka({
                      ...editedCenovaPonuka,
                      zaloha_platba: state,
                      zaloha: state === false ? 0 : editedCenovaPonuka.zaloha,
                    })
                  }}
                  checked={editedCenovaPonuka.zaloha_platba}
                />
              </div>
            </div>

            <AttributeInput
              hardDisabled={
                !editedCenovaPonuka.zaloha_platba || editedCenovaPonuka.dobierka
              }
              disabled={!editable || !editedCenovaPonuka.zaloha_platba}
              type="number"
              title="Záloha (%)"
              value={
                editedCenovaPonuka.zaloha !== undefined
                  ? editedCenovaPonuka.zaloha
                  : ""
              }
              setValue={(value: number) =>
                setEditedCenovaPonuka({
                  ...editedCenovaPonuka,
                  zaloha: value,
                })
              }
            />
            <div className="in-row">
              <AttributeInput
                disabled={!isEditableOrNew}
                title="Splatnosť faktúry"
                value={
                  editedCenovaPonuka.splatnost !== undefined
                    ? editedCenovaPonuka.splatnost
                    : ""
                }
                setValue={(value: string) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    splatnost: value,
                  })
                }
              />
              <AttributeInput
                disabled={!isEditableOrNew}
                title="Platnosť ponuky"
                value={
                  editedCenovaPonuka.platnost !== undefined
                    ? editedCenovaPonuka.platnost
                    : ""
                }
                setValue={(value: string) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    platnost: value,
                  })
                }
              />
            </div>
            <div className="in-row">
              <AttributeInput
                disabled={!isEditableOrNew}
                title="Rabat klienta (%)"
                value={
                  editedCenovaPonuka.rabat !== undefined
                    ? editedCenovaPonuka.rabat
                    : ""
                }
                setValue={(value: number) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    rabat: value,
                  })
                }
              />
              <AttributeInput
                disabled={!isEditableOrNew}
                title="Bonus (%)"
                value={
                  editedCenovaPonuka.bonus !== undefined
                    ? editedCenovaPonuka.bonus
                    : ""
                }
                setValue={(value: number) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    bonus: value,
                  })
                }
              />
            </div>
            <div className="in-row">
              <AttributeInput
                disabled={!isEditableOrNew}
                title="Termín dodávky"
                value={
                  editedCenovaPonuka.termin !== undefined
                    ? editedCenovaPonuka.termin
                    : ""
                }
                setValue={(value: string) =>
                  setEditedCenovaPonuka({
                    ...editedCenovaPonuka,
                    termin: value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      {editable || newCP ? (
        <div className="editable">
          <Button
            disabled={!saveButtonEnabled}
            onClick={() => {
              setSaveButtonEnabled(false)
              if (newCP) {
                addNewCPHandler()
                return
              }
              editCPHandler()
            }}
          >
            <IoMdCheckmark
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ULOŽIT
          </Button>
          <Button onClick={() => closeHandler()}>
            <IoMdClose
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ZRUŠIT
          </Button>
        </div>
      ) : null}
      <Polozky
        disabled={!isEditableOrNew}
        editedCenovaPonuka={editedCenovaPonuka}
        setEditedCenovaPonuka={setEditedCenovaPonuka}
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  kontakty: state.kontakty,
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, { GET_KONTAKTY, SET_ERRORS })(
  CenovaPonuka
)
