import classNames from "classnames"
import React, { InputHTMLAttributes, Ref } from "react"
import { forwardRef } from "react"
import { Errors } from "../../../ts/Errors"
import "./Input.scss"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  borderColor?: "primary" | "yellow"
  error?: string
}

const Input: React.FC<Props & { ref?: Ref<HTMLInputElement> }> = forwardRef(
  ({ borderColor = "primary", error, ...props }, ref) => {
    return (
      <div className="styled-input-wrapper">
        <input
          ref={ref}
          className={classNames(
            "styled-input",
            {
              active: props.value && props.value !== "",
              error: error && error !== ""
            },
            borderColor
          )}
          spellCheck={false}
          {...props}
        />
        {error && error !== "" ?
        <div className="error-wrapper">
          {error}
        </div>
        : null }
      </div>
    )
  }
)

export default Input
