import React, { useEffect, useRef, useState } from "react"
import "./Header.scss"

import { RouteComponentProps, withRouter } from "react-router"
import { connect } from "react-redux"
import ReduxState from "../../ts/Redux"
import { LOGOUT_USER } from "../../redux/actions/authActions"

import { Auth } from "../../ts/Auth"
import HeaderItem from "./HeaderItem"
import Button from "../ui/Button/Button"
import { Link } from "react-router-dom"

import Logo from "../../media/Logo_ECLISSE_SK.png"

interface Props extends RouteComponentProps {
  auth: Auth
  LOGOUT_USER(): void
}

const Header: React.FC<Props> = ({ auth, location, LOGOUT_USER }: Props) => {
  const [moving, setMoving] = useState({ width: 0, left: 0, height: 0 })
  const refs = useRef(Array(7).fill(useRef<HTMLDivElement>(null))).current

  const getPathIndex = (path: string) => {
    if (path.includes("/cenove-ponuky")) return 3
    if (path.includes("/obraty")) return 4

    switch (path) {
      case "/":
        return 0
      case "/cennik":
        return 1
      case "/kontakty":
        return 2
      case "/cs-skupiny":
        return 5
      case "/pouzivatelia":
        return 6
      default:
        return 0
    }
  }

  useEffect(() => {
    setMoving({
      left: refs[getPathIndex(location.pathname)]?.offsetLeft,
      width: refs[getPathIndex(location.pathname)]?.clientWidth,
      height: refs[getPathIndex(location.pathname)]?.clientHeight,
    })

    const resizeHandler = () => {
      setMoving({
        left: refs[getPathIndex(location.pathname)]?.offsetLeft,
        width: refs[getPathIndex(location.pathname)]?.clientWidth,
        height: refs[getPathIndex(location.pathname)]?.clientHeight,
      })
    }

    window.addEventListener("resize", resizeHandler)

    return () => window.removeEventListener("resize", resizeHandler)
  }, [location.pathname, refs, auth.user?.function])

  return (
    <header>
      <div>
        <div className="up">
          <Link
            to="/"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="logo-wrapper">
              <img src={Logo} alt="Made by: Etzi.L (22cm)" />
            </div>
          </Link>
          {auth.logged ? (
            <div className="userHeader">
              <div className="name">
                <Link to="/">
                  {" "}
                  {auth.user.firstName} {auth.user.lastName}
                </Link>
              </div>
              <Button color="white" onClick={LOGOUT_USER}>
                ODHLÁSIŤ SA
              </Button>
            </div>
          ) : null}
        </div>
        <div
          className="itemsWrapper"
          style={{
            visibility: auth.logged ? "visible" : "hidden",
          }}
        >
          <div
            className="movingItem"
            style={{
              width: moving.width,
              height: 2,
              left: moving.left,
            }}
          />
          <HeaderItem
            active={location.pathname === "/"}
            ref={(ref) => (refs[0] = ref)}
            title="Úvod"
            link="/"
          />
          <HeaderItem
            active={location.pathname === "/cennik"}
            ref={(ref) => (refs[1] = ref)}
            title="Cenník"
            link="/cennik"
          />
          <HeaderItem
            active={location.pathname === "/kontakty"}
            ref={(ref) => (refs[2] = ref)}
            title="Kontakty"
            link="/kontakty"
          />
          <HeaderItem
            active={location.pathname.includes("/cenove-ponuky")}
            ref={(ref) => (refs[3] = ref)}
            title="Cenové ponuky"
            link="/cenove-ponuky"
          />
          <HeaderItem
            active={location.pathname === "/obraty"}
            ref={(ref) => (refs[4] = ref)}
            title="Obraty"
            link="/obraty"
          />
          <HeaderItem
            active={location.pathname === "/cs-skupiny"}
            ref={(ref) => (refs[5] = ref)}
            title="CS: Skupiny"
            link="/cs-skupiny"
          />
          {auth.user?.function === "root" ? (
            <HeaderItem
              active={location.pathname === "/pouzivatelia"}
              ref={(ref) => (refs[6] = ref)}
              title="Používatelia"
              link="/pouzivatelia"
            />
          ) : null}
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { LOGOUT_USER })(withRouter(Header))
