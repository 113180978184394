import { Kontakt } from "../../../ts/Kontakt"
import { Obrat, RawObrat } from "../../../ts/Obraty"
import { Filter } from "./Obraty"

export const MAP_CSV_OBRATY_TO_JSON = (object: any) => {
  let objectLength = Object.keys(object).length

  let mappedArray: any[] = []

  for (let i = 0; i < objectLength; i++) {
    if (Object.keys(object[i]).length !== 4) continue

    let mappedObject: any = {}

    Object.keys(object[i]).map((k) => {
      mappedObject[k] = parseFloat(object[i][k].replace(/,/, "."))
    })

    mappedArray.push(mappedObject)
  }

  return mappedArray.length === 0 ? false : (mappedArray as RawObrat[])
}

const IS_MATCH_WITH_FILTER = (filter: Filter, o: Obrat): boolean => {
  let criteriaCheck = true

  if (filter.kontakt)
    if (o.kontakt.nazev.toLowerCase() !== filter.kontakt?.toLowerCase())
      criteriaCheck = false

  if (filter.skupina)
    if (o.kontakt.skupina.toLowerCase() !== filter.skupina?.toLowerCase())
      criteriaCheck = false

  if (filter.obec)
    if (o.kontakt.obec.toLowerCase() !== filter.obec?.toLowerCase())
      criteriaCheck = false

  if (filter.okres)
    if (o.kontakt.okres.toLowerCase() !== filter.okres?.toLowerCase())
      criteriaCheck = false

  if (filter.obchodnik)
    if (o.kontakt.obchodnik.toLowerCase() !== filter.obchodnik?.toLowerCase())
      criteriaCheck = false

  if (filter.rok) if (o.rok !== filter.rok) criteriaCheck = false

  if (filter.mesic) if (o.mesiac !== filter.mesic) criteriaCheck = false

  return criteriaCheck
}

const IS_ALREADY_IN_ARRAY = (obrat: Obrat, obraty: Obrat[]): boolean => {
  if (obraty.find((o) => o._id === obrat._id)) return true

  return false
}

const GET_SUMA_FOR_OBRATY = (o: Obrat, obraty: Obrat[]): number => {
  return obraty
    .filter(
      (so) =>
        so.kontakt.id === o.kontakt.id &&
        so.rok === o.rok &&
        so.mesiac === o.mesiac
    )
    .map((o) => o.suma)
    .reduce((a, b) => a + b)
}

const getSumaForMesic = (
  obraty: Obrat[],
  kontakt: string,
  rok: number,
  mesiac: number
): number | undefined => {
  let filteredSumy = obraty
    .filter(
      (obr) =>
        obr.kontakt.id === kontakt && obr.rok === rok && obr.mesiac === mesiac
    )
    .map((obr) => obr.suma)

  if (filteredSumy.length === 0 || !filteredSumy) return undefined

  return filteredSumy.reduce((a, b) => a + b)
}

export type MesicString =
  | "leden"
  | "unor"
  | "brezen"
  | "duben"
  | "kveten"
  | "cerven"
  | "cervenec"
  | "srpen"
  | "zari"
  | "rijen"
  | "listopad"
  | "prosinec"

export type Mesic = {
  nazev:
    | "leden"
    | "unor"
    | "brezen"
    | "duben"
    | "kveten"
    | "cerven"
    | "cervenec"
    | "srpen"
    | "zari"
    | "rijen"
    | "listopad"
    | "prosinec"
  suma: number | undefined
}

export interface ObratMesice {
  _id: string
  date: string
  kontakt: Kontakt
  rok: number
  spolu: number | undefined
  mesice: Mesic[]
}

export interface ObratMesiceSingle {
  _id: string
  id_klienta: string
  spolocnost: string
  leden: number | undefined | string
  unor: number | undefined | string
  brezen: number | undefined | string
  duben: number | undefined | string
  kveten: number | undefined | string
  cerven: number | undefined | string
  cervenec: number | undefined | string
  srpen: number | undefined | string
  zari: number | undefined | string
  rijen: number | undefined | string
  listopad: number | undefined | string
  prosinec: number | undefined | string
  rok: number
  spolu: number | string | undefined
  obchodnik?: string
  skupina?: string
  mesto?: string
  okres?: string
}

export const GET_MESICE_OBRATY = (obraty: Obrat[]): ObratMesice[] => {
  let mesiceObraty: ObratMesice[] = []

  obraty.forEach((o) => {
    if (
      !mesiceObraty.find(
        (m) => m.kontakt.id === o.kontakt.id && m.rok === o.rok
      )
    ) {
      let mesice: Mesic[] = [
        {
          nazev: "leden",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 1),
        },
        {
          nazev: "unor",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 2),
        },
        {
          nazev: "brezen",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 3),
        },
        {
          nazev: "duben",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 4),
        },
        {
          nazev: "kveten",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 5),
        },
        {
          nazev: "cerven",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 6),
        },
        {
          nazev: "cervenec",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 7),
        },
        {
          nazev: "srpen",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 8),
        },
        {
          nazev: "zari",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 9),
        },
        {
          nazev: "rijen",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 10),
        },
        {
          nazev: "listopad",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 11),
        },
        {
          nazev: "prosinec",
          suma: getSumaForMesic(obraty, o.kontakt.id, o.rok, 12),
        },
      ]

      mesiceObraty.push({
        _id: o._id,
        date: o.date,
        kontakt: o.kontakt,
        rok: o.rok,
        mesice,
        spolu: mesice
          .map((m) => m.suma)
          .reduce((a, b) => {
            let _a = undefined,
              _b = undefined

            if (typeof a === "undefined") _a = 0
            else _a = a
            if (typeof b === "undefined") _b = 0
            else _b = b

            return _a + _b
          }),
      })
    }
  })

  return mesiceObraty
}

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index
}

export const GET_SUM_OBRATY = (obraty: Obrat[]): ObratMesice[] => {
  let sumObraty: Obrat[] = []

  obraty.forEach((o) => {
    let findElement = sumObraty.find(
      (so) =>
        so.kontakt.id === o.kontakt.id &&
        so.rok === o.rok &&
        so.mesiac === o.mesiac
    )

    if (!findElement)
      sumObraty.push({ ...o, suma: GET_SUMA_FOR_OBRATY(o, obraty) })
  })

  return GET_MESICE_OBRATY(sumObraty)
}

export const GET_FILTERED_OLD_OBRATY = (
  filters: Filter[],
  obraty: Obrat[]
): Obrat[] => {
  let filteredObraty: Obrat[] = []

  obraty.forEach((o) => {
    filters.forEach((f) => {
      if (IS_MATCH_WITH_FILTER(f, o) && !IS_ALREADY_IN_ARRAY(o, filteredObraty))
        filteredObraty.push(o)
    })
  })

  return filteredObraty
}

export const GET_FILTERED_OBRATY = (
  filters: Filter[],
  obraty: Obrat[]
): ObratMesice[] => {
  let filteredObraty: Obrat[] = []

  obraty.forEach((o) => {
    filters.forEach((f) => {
      if (IS_MATCH_WITH_FILTER(f, o) && !IS_ALREADY_IN_ARRAY(o, filteredObraty))
        filteredObraty.push(o)
    })
  })

  return GET_MESICE_OBRATY(filteredObraty)
}

export const GET_OBRATY_BY_FILTER = (
  filter: Filter,
  obraty: Obrat[]
): Obrat[] => {
  let filteredObraty: Obrat[] = []

  obraty.forEach((o) => {
    if (IS_MATCH_WITH_FILTER(filter, o)) {
      filteredObraty.push(o)
    }
  })

  return filteredObraty
}

export const IS_OBJECT_DEFINED = (val: any) => {
  return !(val === null || val === undefined || val === "")
}

export const IS_FILTER_EMPTY = (filter: Filter) => {
  let isFilterEmpty = true

  Object.keys(filter).forEach((k: string) => {
    if (IS_OBJECT_DEFINED(filter[k as keyof Filter])) isFilterEmpty = false
  })

  return isFilterEmpty
}
