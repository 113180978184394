import { Action, Dispatch } from "redux"
import { Errors } from "../../ts/Errors"

export const SET_ERRORS =
  (errors: Errors) => (dispatch: Dispatch<Action<"SET_ERRORS">>) => {
    dispatch({
      type: "SET_ERRORS",
      payload: errors,
    })
  }
