import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import axios from "../../../axios/axios";
import ExportButton from "../../../exports/ExportButton";
import { Auth } from "../../../ts/Auth";
import { CenovaPonuka, SearchQuery } from "../../../ts/CP";
import ReduxState from "../../../ts/Redux";
import { FetchedObchodnik, Row } from "../../../ts/UI";
import { formatCPNumber, getCPDate, getCPState } from "../../../utils";
import Button from "../../ui/Button/Button";
import Table from "../../ui/Table/Table";
import "./SeznamPonuk.scss";

const cpColumns = [
  { key: "id", visible: false },
  { title: "ČÍSLO PONUKY", key: "cislo_ponuky", width: "20%" },
  { title: "OBJEDNÁVATEĽ", key: "objednavatel", width: "10%" },
  { title: "KONTAKTNÁ OSOBA", key: "kontaktna_osoba", width: "10%" },
  {
    title: "PRIJÍMATEĽ",
    key: "prijimatel",
    width: "15%",
  },
  { title: "OBCHODNÍK", key: "obchodnik", width: "15%" },
  { title: "CELKOM POLOŽIEK", key: "celkem_polozek", width: "5%" },
  { title: "CENA CELKOM", key: "cena_celkem", width: "5%" },
  { title: "STAV", key: "state", width: "5%" },
  { title: "DATUM", key: "date", width: "10%" },
];

const sortByNew = (a: CenovaPonuka, b: CenovaPonuka): number => {
  let doesntHaveObjednavatel = Boolean(
    (!a.objednavatel || !a.objednavatel.nazev || a.objednavatel.nazev === "") &&
      b.objednavatel &&
      b.objednavatel.nazev &&
      b.objednavatel.nazev !== ""
  );

  if (doesntHaveObjednavatel) return -1;

  if (a.state === "new" && b.state === "in-progress") return -1;

  if (a.state === "in-progress" && b.state === "finished") return -1;

  return 0;
};

const sortByDate = (a: CenovaPonuka, b: CenovaPonuka): number => {
  return a.date && b.date ? b.date - a.date : 0;
};

interface Props {
  cp: {
    loading: boolean;
    list: CenovaPonuka[];
  };
  auth: Auth;
  shown: boolean;
  onlyNew?: boolean;
}

const SeznamPonuk = forwardRef(
  ({ cp, auth, shown, onlyNew = false }: Props, ref) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [searchData, setSearchData] = useState<CenovaPonuka[] | null>(null);
    const history = useHistory();

    const [obchodnici, setObchodnici] = useState<any[]>([]);

    useEffect(() => {
      let isMounted = true;

      const fetchObchodnici = async () => {
        const req = await axios.post(
          "/kontakty/get-obchodnici-by-kontakt-ids",
          {
            kontakt_ids: cp.list.map((c) => c.kontakt_id),
          }
        );
        if (isMounted) {
          setObchodnici(req.data);
        }
      };

      fetchObchodnici();

      return () => {
        isMounted = false;
      };
    }, []);

    useEffect(() => {
      wrapperRef.current?.classList.add("active");

      return () => wrapperRef.current?.classList.remove("active");
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        search(query: SearchQuery) {
          setSearchData(
            cp.list.filter(
              (cp) =>
                (cp.cislo_ponuky?.toString?.().toLowerCase?.() ===
                  query?.cislo_ponuky?.toLowerCase?.() &&
                  query?.cislo_ponuky !== "") ||
                (cp.objednavatel?.nazev
                  ?.toLowerCase?.()
                  .includes?.(query.objednavatel?.toLowerCase?.()) &&
                  query.objednavatel !== "") ||
                (cp.prijimatel?.jmeno
                  ?.toLowerCase?.()
                  .includes?.(query.prijimatel?.toLowerCase?.()) &&
                  query.prijimatel !== "") ||
                (cp.prijimatel?.prijmeni
                  ?.toLowerCase()
                  .includes(query.prijimatel?.toLowerCase()) &&
                  query.prijimatel !== "")
            )
          );
        },
      }),
      []
    );

    const getMappedCP = (cp: CenovaPonuka): any => {
      return {
        id: cp.id,
        cislo_ponuky: formatCPNumber(cp.cislo_ponuky),
        objednavatel: cp.objednavatel?.nazev,
        kontaktna_osoba: cp.objednavatel?.kontaktna_osoba,
        prijimatel: cp.prijimatel
          ? (cp.prijimatel.jmeno ? cp.prijimatel.jmeno : "") +
            (cp.prijimatel.prijmeni ? cp.prijimatel.prijmeni : "")
          : "",
        obchodnik: obchodnici.find((o) => o.id === cp.kontakt_id)?.obchodnik,
        celkem_polozek: cp.polozky.length,
        cena_celkem: cp.polozky
          .map((p) => p.cennikova_cena * p.mn)
          .reduce((a, b) => a + b, 0)
          .toFixed(2),
        state: getCPState(cp.state),
        date: getCPDate(cp.last_edited_date),
      };
    };

    const getData = (withMappedCP: boolean) => {
      if (searchData) {
        return searchData
          .sort(sortByDate)
          .sort(sortByNew)
          .map((cp) => (withMappedCP ? getMappedCP(cp) : cp));
      }

      return cp.list
        .filter((cp) => (onlyNew ? cp.state === "new" : true))
        .sort(sortByDate)
        .sort(sortByNew)
        .map((_cp) => (withMappedCP ? getMappedCP(_cp) : _cp));
    };

    return (
      <div
        style={{
          display: shown ? "block" : "none",
        }}
        ref={wrapperRef}
        className="cenove-ponuky-list"
      >
        <div className="cenove-ponuky-list-title">
          Kliknutím vyberiete cenovú ponuku
          {searchData ? (
            <Button onClick={() => setSearchData(null)}>VYMAZAT FILTR</Button>
          ) : null}
        </div>
        <Table
          rowClasses={["hover-cp"]}
          rowClickHandler={(row: Row) => {
            history.push(`/cenove-ponuky/${row.item[0].value}`);
          }}
          sortingColumns={onlyNew ? [] : [6, 7]}
          data={getData(true)}
          columns={cpColumns}
        />
        <ExportButton list={getData(false)} type="cp" />
      </div>
    );
  }
);

const mapStateToProps = (state: ReduxState) => ({
  cp: state.cp,
  auth: state.auth,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SeznamPonuk
);
