import React from "react"

import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "../Input/TextField"
import classNames from "classnames"

export type DataListItem = {
  value: any
  title: string
}

interface Props {
  htmlId: string
  title?: string
  value: any
  datalist: DataListItem[] | string[]
  setValue(value: any): void
  disabled?: boolean
  clear?(): void
  freeSolo?: boolean
  error?: string
  canBeDeleted?: boolean
}

const DropdownInput: React.FC<Props> = ({
  htmlId,
  title,
  value,
  setValue,
  datalist,
  disabled,
  error,
  canBeDeleted,
  freeSolo = true,
  clear,
}: Props) => {
  return (
    <div className="attribute-input">
      {title ? <div className="attribute-input-title">{title}</div> : null}
      <Autocomplete
        className={classNames("dropdown-input", error ? "error" : "")}
        disabled={disabled}
        onChange={(e, newValue, reason) => {
          if (reason === "clear" && clear) {
            clear()
            return
          }
          setValue(newValue)
        }}
        onInputChange={
          freeSolo === false
            ? undefined
            : (e, newValue) => {
                setValue(newValue)
              }
        }
        inputValue={canBeDeleted ? (value ? value.toString() : "") : undefined}
        freeSolo={freeSolo}
        value={value}
        noOptionsText="Žádné výsledky"
        id={htmlId}
        getOptionLabel={(option: any) =>
          option.title !== undefined ? option.title : option
        }
        autoComplete
        options={datalist}
        renderInput={(props) => {
          if (canBeDeleted) return <TextField {...props} value={value} />
          else return <TextField {...props} />
        }}
      />
    </div>
  )
}

export default DropdownInput
