import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { connect } from "react-redux"
import ExportButton from "../../../exports/ExportButton"
import { KontaktSearchQuery } from "../../../ts/CP"
import { Skupina } from "../../../ts/CSSkupiny"
import { Kontakt } from "../../../ts/Kontakt"
import ReduxState from "../../../ts/Redux"
import { Row } from "../../../ts/UI"
import { translateString } from "../../../utils"
import Button from "../../ui/Button/Button"
import Table from "../../ui/Table/Table"
import { useKontakty } from "./KontaktyWrapper"
import "./SeznamKontaktu.scss"

const kontaktyColumns = [
  { key: "id", visible: false },
  { title: "ID - KLIENT", key: "id_klienta", width: "5%" },
  { title: "KLIENT", key: "nazev", width: "20%" },
  { title: "SKUPINA", key: "skupina", width: "10%" },
  { title: "JMÉNO", key: "jmeno", width: "10%" },
  {
    title: "PRIEZVISKO",
    key: "prijmeni",
    width: "25%",
  },
  { title: "ADRESA", key: "adresa", width: "15%" },
]

interface Props {
  kontakty: Kontakt[]
  setListShown(value: boolean): void
  shown: boolean
  cs_skupiny: Skupina[]
}

const SeznamKontaktu = forwardRef(
  ({ kontakty, setListShown, shown, cs_skupiny }: Props, ref) => {
    const [, , setIndex] = useKontakty()
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [searchData, setSearchData] = useState<Kontakt[] | null>(null)

    useEffect(() => {
      wrapperRef.current?.classList.add("active")

      return () => wrapperRef.current?.classList.remove("active")
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        search(query: KontaktSearchQuery) {
          setSearchData(
            kontakty.filter((k) => {
              let skupina = cs_skupiny.find((cs) => cs.id === k.skupina)

              return (
                (translateString(k.nazev.toLowerCase()).includes(
                  translateString(query.klient.toLowerCase())
                ) &&
                  query.klient !== "") ||
                (translateString(
                  skupina
                    ? skupina.nazev.toLowerCase()
                    : k.skupina.toLowerCase()
                ).includes(translateString(query.skupina.toLowerCase())) &&
                  query.skupina !== "") ||
                (translateString(k.obec.toLowerCase()).includes(
                  translateString(query.obec.toLowerCase())
                ) &&
                  query.obec !== "") ||
                (translateString(k.okres.toLowerCase()).includes(
                  translateString(query.okres.toLowerCase())
                ) &&
                  query.okres !== "") ||
                (translateString(k.jmeno.toLowerCase()).includes(
                  translateString(query.meno.toLowerCase())
                ) &&
                  query.meno !== "") ||
                (translateString(k.prijmeni.toLowerCase()).includes(
                  translateString(query.priezvisko.toLowerCase())
                ) &&
                  query.priezvisko !== "") ||
                (translateString(k.email.toLowerCase()).includes(
                  translateString(query.email.toLowerCase())
                ) &&
                  query.email !== "")
              )
            })
          )
        },
      }),
      [kontakty]
    )

    const getMappedKontakt = (k: Kontakt) => {
      let skupina = cs_skupiny.find((cs) => cs.id === k.skupina)

      return {
        id: k.id,
        id_klienta: k.id_klienta,
        nazev: k.nazev,
        skupina: skupina ? skupina.nazev : k.skupina,
        jmeno: k.jmeno,
        prijmeni: k.prijmeni,
        adresa: k.adresa,
      }
    }

    const getData = (withMappedKontakt: boolean) => {
      if (searchData) {
        return searchData.map((k) =>
          withMappedKontakt ? getMappedKontakt(k) : k
        )
      }

      return kontakty.map((k) => (withMappedKontakt ? getMappedKontakt(k) : k))
    }

    if (!setIndex) return null

    return (
      <div
        style={{
          display: shown ? "block" : "none",
        }}
        ref={wrapperRef}
        className="kontakty-list"
      >
        <div className="kontakty-list-title">
          Kliknutím vyberiete kontakt
          {searchData ? (
            <Button onClick={() => setSearchData(null)}>VYMAZAT FILTR</Button>
          ) : null}
        </div>
        <Table
          rowClasses={["hover-cp"]}
          rowClickHandler={(row: Row) => {
            setIndex(kontakty.findIndex((p) => p.id === row.item[0].value))
            setListShown(false)
          }}
          data={getData(true)}
          columns={kontaktyColumns}
        />
        <ExportButton list={getData(false) as Kontakt[]} type="kontakty" />
      </div>
    )
  }
)

const mapStateToProps = (state: ReduxState) => ({
  kontakty: state.kontakty,
  cs_skupiny: state.cs_skupiny,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SeznamKontaktu
)
