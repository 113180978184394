import { encode } from "./qr/encode";

export const generateQRPayment = (sum: number, vs: string): string => {
  return encode({
    payments: [
      {
        bankAccounts: [{ iban: "SK6975000000004010780897" }],
        currencyCode: "EUR",
        type: 1,
        amount: parseFloat(sum.toFixed(2)),
        variableSymbol: vs,
        beneficiary: {
          name: "ECLISSE Slovakia, s.r.o.",
        },
      },
    ],
    invoiceId: vs,
  });
};
