import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./Header.scss";
import { KontaktSearchQuery, SearchQuery } from "../../../ts/CP";
import Button from "../../ui/Button/Button";
import { useEditableKontakt, useKontakty } from "./KontaktyWrapper";

import { BsSearch } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoMdList, IoMdClose, IoMdAdd } from "react-icons/io";
import { FaFileExport, FaFileImport, FaMoneyCheckAlt } from "react-icons/fa";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";

import Papa from "papaparse";

import { connect } from "react-redux";
import Confirmation from "../../ui/Confirmation/Confirmation";
import { ConfirmationType } from "../../../ts/UI";
import { Link } from "react-router-dom";
import Input from "../../ui/Input/Input";
import ReduxState from "../../../ts/Redux";
import { Auth } from "../../../ts/Auth";
import {
  DELETE_KONTAKT,
  ADD_KONTAKTY,
} from "../../../redux/actions/kontaktyActions";
import { Kontakt, Kontakt as KontaktType } from "../../../ts/Kontakt";
import { mapCSVDataToJson } from "../../../utils";
import ImportPopup, {
  ImportPopupInterface,
} from "../../ui/ImportPopup/ImportPopup";
import { AiOutlineUnorderedList } from "react-icons/ai";
import ExportButton from "../../../exports/ExportButton";

interface Props {
  index: number;
  kontakty: Kontakt[];
  DELETE_KONTAKT(kontakt: KontaktType, id: string): void;
  ADD_KONTAKTY(
    kontakty: KontaktType[],
    id: string,
    callback?: (success: boolean) => void
  ): void;
  handleSearch(query: KontaktSearchQuery): void;
  auth: Auth;
}
const Header: React.FC<Props> = ({
  index,
  kontakty,
  DELETE_KONTAKT,
  ADD_KONTAKTY,
  handleSearch,
  auth,
}: Props) => {
  const [kontakt, , setIndex] = useKontakty();
  const [
    editable,
    setEditable,
    newKontakt,
    setNewKontakt,
    listShown,
    setListShown,
  ] = useEditableKontakt();
  const [searchActive, setSearchActive] = useState(false);
  const [query, setQuery] = useState<KontaktSearchQuery>({
    klient: "",
    skupina: "",
    obec: "",
    okres: "",
    meno: "",
    priezvisko: "",
    email: "",
  });
  const [errors, setErrors] = useState<any>({});

  const deleteConfirmation = useRef<ConfirmationType>(null);

  const isEditableOrNewOrNewListShown = listShown || editable || newKontakt;

  const importPopupRef = useRef<ImportPopupInterface>(null);

  const importKontakty = (e: ChangeEvent<HTMLInputElement>) => {
    let f = e.target.files?.[0];

    if (f) {
      Papa.parse(f, {
        encoding: "utf8",
        header: true,
        complete: (csv) => {
          let mapped = mapCSVDataToJson(csv.data);

          if (mapped) {
            setErrors({});
            importPopupRef.current?.showPopup(csv.data, (content) => {
              if (content) {
                let mapped = mapCSVDataToJson(content);
                ADD_KONTAKTY(
                  mapped as KontaktType[],
                  auth.user.id,
                  (success) => {
                    importPopupRef.current?.showState(success);
                  }
                );
              }
            });
          } else {
            setErrors({ ...errors, import: "Nesprávný formát CSV souboru" });
          }
        },
      });
    }
  };

  const searchClick = () => {
    if (
      query.klient === "" &&
      query.skupina === "" &&
      query.obec === "" &&
      query.okres === "" &&
      query.meno === "" &&
      query.priezvisko === "" &&
      query.email === ""
    )
      return;

    setSearchActive(false);
    setQuery({
      klient: "",
      skupina: "",
      obec: "",
      okres: "",
      meno: "",
      priezvisko: "",
      email: "",
    });
    handleSearch(query);
  };

  const inputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") searchClick();
  };

  if (!kontakt || !setIndex) return null;

  return (
    <>
      <ImportPopup ref={importPopupRef} preventClosing />
      <div className="kontaktyHeader">
        <div className="up">
          <div>
            <Button
              disabled={listShown || editable}
              onClick={() => {
                setNewKontakt(!newKontakt);
              }}
            >
              {newKontakt ? (
                <>
                  {" "}
                  <IoMdClose
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  ZRUŠIT
                </>
              ) : (
                <>
                  {" "}
                  <IoMdAdd
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  VYTVORIŤ
                </>
              )}
            </Button>
          </div>

          <div>
            <Button
              disabled={listShown || newKontakt || kontakt.id === ""}
              onClick={() => {
                setEditable(!editable);
              }}
            >
              {editable ? (
                <>
                  {" "}
                  <IoMdClose
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  ZRUŠIT
                </>
              ) : (
                <>
                  {" "}
                  <BiEditAlt
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  UPRAVIŤ
                </>
              )}
            </Button>
          </div>

          <div
            style={{
              position: "relative",
            }}
          >
            <Confirmation
              title="Odstranit kontakt?"
              yes={() => {
                DELETE_KONTAKT(kontakt, auth.user.id);
                setIndex(index - 1 < 0 ? 0 : index - 1);
              }}
              ref={deleteConfirmation}
              style={{
                top: "-100%",
                left: "-12.5%",
                whiteSpace: "nowrap",
              }}
            />
            <Button
              onClick={() => deleteConfirmation.current?.clickHandler()}
              disabled={isEditableOrNewOrNewListShown || kontakt.id === ""}
            >
              {" "}
              <RiDeleteBin7Line
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              ODSTRÁNIŤ
            </Button>
          </div>
          <div>
            <Button
              disabled={editable || newKontakt}
              onClick={() => setSearchActive(!searchActive)}
            >
              {" "}
              <BsSearch
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              VYHĽADAŤ
            </Button>
          </div>
        </div>
        {searchActive ? (
          <div className="searchCP">
            <Input
              value={query.klient}
              onChange={(e) => setQuery({ ...query, klient: e.target.value })}
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Klient/Spoločnosť"
            />
            <Input
              value={query.skupina}
              onChange={(e) => setQuery({ ...query, skupina: e.target.value })}
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Skupina"
            />
            <Input
              value={query.obec}
              onChange={(e) => setQuery({ ...query, obec: e.target.value })}
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Obec"
            />
            <Input
              value={query.okres}
              onChange={(e) => setQuery({ ...query, okres: e.target.value })}
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Okres"
            />
            <Input
              value={query.meno}
              onChange={(e) => setQuery({ ...query, meno: e.target.value })}
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Meno"
            />
            <Input
              value={query.priezvisko}
              onChange={(e) =>
                setQuery({ ...query, priezvisko: e.target.value })
              }
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Priezvisko"
            />
            <Input
              value={query.email}
              onChange={(e) => setQuery({ ...query, email: e.target.value })}
              onKeyPress={inputKeyPress}
              borderColor="yellow"
              placeholder="Email"
            />
            <Button
              disabled={
                query.klient === "" &&
                query.skupina === "" &&
                query.obec === "" &&
                query.okres === "" &&
                query.meno === "" &&
                query.priezvisko === "" &&
                query.email === ""
              }
              onClick={searchClick}
              style={{
                display: "flex",
              }}
            >
              {" "}
              <BsSearch
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              HLADAŤ
            </Button>
          </div>
        ) : null}
        <div className="middle">
          <div>
            <Button
              disabled={editable || newKontakt}
              onClick={() => setListShown(!listShown)}
            >
              {" "}
              <IoMdList
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              {listShown ? "ZATVORIŤ" : "ZOZNAM"}
            </Button>
          </div>
          <div>
            <Link to={`/obraty/k/${kontakt.id}`}>
              <Button disabled={editable || newKontakt}>
                {" "}
                <FaMoneyCheckAlt
                  size={16}
                  style={{
                    marginRight: 10,
                  }}
                />{" "}
                OBRATY
              </Button>
            </Link>
          </div>
          <div>
            <Link to={`/cenove-ponuky/k/${kontakt.id}`}>
              <Button disabled={editable || newKontakt}>
                {" "}
                <AiOutlineUnorderedList
                  size={16}
                  style={{
                    marginRight: 10,
                  }}
                />{" "}
                CENOVÉ PONUKY
              </Button>
            </Link>
          </div>
          {auth.user.function === "root" ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <label htmlFor="kontakty-import">
                <div
                  className="styled-button yellow"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <FaFileImport
                    size={16}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  IMPORT KONTAKTOV
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 40,
                }}
              >
                <ExportButton
                  wrapperStyle={{ marginTop: 0 }}
                  buttonStyle={{ width: "auto" }}
                  list={kontakty}
                  type="kontakty"
                  label="EXPORT KONTAKTOV"
                  icon={
                    <FaFileExport
                      size={16}
                      style={{
                        marginRight: 10,
                      }}
                    />
                  }
                />
              </div>
              {errors.import ? (
                <div className="error-message import">{errors.import}</div>
              ) : null}
              <input
                type="file"
                style={{
                  display: "none",
                }}
                accept=".csv"
                onClick={(
                  e: React.MouseEvent<HTMLInputElement, MouseEvent>
                ) => {
                  const element = e.target as HTMLInputElement;
                  element.value = "";
                }}
                onChange={importKontakty}
                id="kontakty-import"
              />
            </div>
          ) : null}
        </div>
        <div className="down">
          <div>
            <Button
              disabled={isEditableOrNewOrNewListShown || index === 0}
              onClick={() => setIndex(index - 1)}
            >
              {" "}
              <MdChevronLeft
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              PREDCHÁDZAJÚCI ZÁZNAM
            </Button>
          </div>
          <div>
            <Button
              disabled={
                isEditableOrNewOrNewListShown ||
                index === kontakty.length - 1 ||
                kontakty.length === 0
              }
              onClick={() => setIndex(index + 1)}
            >
              ĎALŠÍ ZÁZNAM{" "}
              <MdChevronRight
                size={16}
                style={{
                  marginLeft: 10,
                }}
              />{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { DELETE_KONTAKT, ADD_KONTAKTY })(
  Header
);
