import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { Cell, Row as RowType } from "../../../ts/UI"
import Actions from "./Actions"
import TableInput from "./TableInput"

interface Props {
  passedItem: Cell[]
  actions?:
    | boolean
    | {
        custom: boolean
        arrayOfActions: JSX.Element | ((rowData: any) => JSX.Element)
      }
  handleRowChange?(item: any): void
  handleDelete(item: any): void
  rowClickHandler?(row: RowType): void
  rowClasses?: string[]
  actionsDisabled?: boolean
}

const Row: React.FC<Props> = ({
  passedItem,
  actions = false,
  actionsDisabled = false,
  handleRowChange,
  handleDelete,
  rowClasses,
  rowClickHandler,
}: Props) => {
  const [editing, setEditing] = useState("")
  const [values, setValues] = useState<any[]>([])

  useEffect(() => {
    setValues(passedItem.map((obj) => obj.value))
  }, [passedItem])

  return (
    <tr
      onClick={() => rowClickHandler?.({ item: passedItem, index: 1 })}
      className={classNames("", rowClasses)}
    >
      {passedItem.map((cell: Cell, i: number) =>
        cell.visible === false ? null : (
          <td key={i} style={cell.cellStyle}>
            <div
              style={{
                display: editing === "edit" ? "none" : "block",
                opacity: editing === "remove" ? 0.1 : 1,
              }}
            >
              {cell.value?.toString()}
            </div>

            {cell.editable === false ? (
              <TableInput value={cell.value} disabled={true} visible={editing === "edit"} />
            ) : (
              <TableInput
                type={cell.type}
                visible={editing === "edit"}
                onChange={(e) => {
                  let newItem = [...values]
                  newItem[i] = e.target.value
                  setValues(newItem)
                }}
                value={values[i]}
              />
            )}
          </td>
        )
      )}
      {actions ? (
        actions instanceof Object ? (
          typeof actions.arrayOfActions === "function" ? (
            actions.arrayOfActions(passedItem)
          ) : (
            actions.arrayOfActions
          )
        ) : (
          <Actions
            disabled={actionsDisabled}
            active={editing !== ""}
            close={() => {
              if (editing === "edit") {
                setValues(passedItem.map((obj) => obj.value))
              }

              setEditing("")
            }}
            confirm={() => {
              if (editing === "edit" && handleRowChange) {
                let returningObject: { [key: string]: any } = {}
                values.forEach((v, i) => {
                  returningObject[passedItem[i].key] = v
                })
                handleRowChange(returningObject)
              }

              if (editing === "remove") {
                let returningObject: { [key: string]: any } = {}
                values.forEach((v, i) => {
                  returningObject[passedItem[i].key] = v
                })
                handleDelete(returningObject)
              }

              setEditing("")
            }}
            handleEdit={handleRowChange ? () => setEditing("edit") : undefined}
            handleRemove={() => setEditing("remove")}
          />
        )
      ) : null}
    </tr>
  )
}

export default Row
