import React, { useState } from "react"
import "./Login.scss"

import { connect } from "react-redux"
import { LoginUser } from "../../ts/Auth"
import { LOGIN_USER } from "../../redux/actions/authActions"

import ReduxState from "../../ts/Redux"
import Button from "../ui/Button/Button"
import Input from "../ui/Input/Input"
import { Errors } from "../../ts/Errors"

interface Props {
  LOGIN_USER(user: LoginUser, callback: () => void): void
  errors: Errors
}

const Login: React.FC<Props> = ({ LOGIN_USER, errors }: Props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  return (
    <div className="login">
      <div>
        <div className="title">VITAJTE!</div>
        <div className="subtitle">PRIHLASOVACIE ÚDAJE</div>
        <div className="login-form">
          <div>
            <Input
              error={errors.email}
              placeholder="Váš email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <Input
              type="password"
              error={errors.password}
              placeholder="Heslo"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <Button
              style={{
                width: 220,
              }}
              loading={loading}
              onClick={() => {
                setLoading(true)
                LOGIN_USER(
                  {
                    email,
                    password,
                  },
                  () => {
                    setLoading(false)
                  }
                )
              }}
            >
              PRIHLÁSIŤ SA
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  errors: state.errors,
})

export default connect(mapStateToProps, { LOGIN_USER })(Login)
