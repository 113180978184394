import React, { useEffect, useRef } from "react";
import "./ZFaDL.scss";

import { connect } from "react-redux";

import { CenovaPonuka } from "../../../ts/CP";
import ReduxState from "../../../ts/Redux";
import Table from "../../ui/Table/Table";
import { GET_CP } from "../../../redux/actions/cpActions";
import { Auth } from "../../../ts/Auth";
import { CenovaPonuka as CenovaPonukaType } from "../../../ts/CP";
import { IconButton, Tooltip } from "@material-ui/core";
import { AiOutlineFilePdf } from "react-icons/ai";

import generateZalohovaFaktura from "../../../pdf/ZalohovaFaktura";
import generateCenovaPonuka from "../../../pdf/CenovaPonuka";
import generateDodaciList from "../../../pdf/DodaciList";
import FakturaPreview, {
  FakturaPreviewType,
} from "../CenovePonuky/FakturaPreview";
import { formatCPNumber, getObchodnikById } from "../../../utils";

interface Props {
  cp: {
    loading: boolean;
    list: CenovaPonuka[];
  };
  GET_CP(id: string): void;
  auth: Auth;
}

const ZFaDLColumns = [
  { key: "id", visible: false },
  { key: "objednavatel", visible: false },
  { key: "prijimatel", visible: false },
  { key: "zaloha", visible: false },
  { key: "splatnost", visible: false },
  { key: "termin", visible: false },
  { key: "dph", visible: false },
  { key: "rabat", visible: false },
  { key: "bonus", visible: false },
  { key: "platnost", visible: false },
  { key: "dobierka", visible: false },
  { key: "zaloha_platba", visible: false },
  { key: "polozky", visible: false },
  { title: "ČÍSLO PONUKY", key: "cislo_ponuky", width: "20%" },
  { title: "OBJEDNÁVATEĽ", key: "objednavatel_nazev", width: "30%" },
  {
    title: "PRIJÍMATEĽ",
    key: "prijimatel_nazev",
    width: "30%",
  },
  { title: "CENA CELKOM", key: "cena_celkem", width: "10%" },
  { title: "PDF", actions: true, key: "actions", width: "10%" },
];

const ZFaDL: React.FC<Props> = ({ auth, cp, GET_CP }: Props) => {
  useEffect(() => {
    if (cp.list.length === 0) GET_CP(auth.user.id);
  }, [cp.list, auth.user.id]);

  const previewRef = useRef<FakturaPreviewType>(null);

  const getFormattedRowData = (rowData: any): CenovaPonukaType => {
    return {
      id: rowData.find((r: { key: string; value: any }) => r.key === "id")
        ?.value,
      cislo_ponuky: rowData.find(
        (r: { key: string; value: any }) => r.key === "cislo_ponuky"
      )?.value,
      fakturacna: rowData.find(
        (r: { key: string; value: any }) => r.key === "fakturacna"
      )?.value,
      objednavatel: rowData.find(
        (r: { key: string; value: any }) => r.key === "objednavatel"
      )?.value,
      prijimatel: rowData.find(
        (r: { key: string; value: any }) => r.key === "prijimatel"
      )?.value,
      zaloha: rowData.find(
        (r: { key: string; value: any }) => r.key === "zaloha"
      )?.value,
      splatnost: rowData.find(
        (r: { key: string; value: any }) => r.key === "splatnost"
      )?.value,
      termin: rowData.find(
        (r: { key: string; value: any }) => r.key === "termin"
      )?.value,
      dph: rowData.find((r: { key: string; value: any }) => r.key === "dph")
        ?.value,
      rabat: rowData.find((r: { key: string; value: any }) => r.key === "rabat")
        ?.value,
      bonus: rowData.find((r: { key: string; value: any }) => r.key === "bonus")
        ?.value,
      platnost: rowData.find(
        (r: { key: string; value: any }) => r.key === "platnost"
      )?.value,
      dobierka: rowData.find(
        (r: { key: string; value: any }) => r.key === "dobierka"
      )?.value,
      zaloha_platba: rowData.find(
        (r: { key: string; value: any }) => r.key === "bonus"
      )?.value,
      polozky: rowData.find(
        (r: { key: string; value: any }) => r.key === "polozky"
      )?.value,
      state: rowData.find((r: { key: string; value: any }) => r.key === "state")
        ?.value,
    } as CenovaPonukaType;
  };

  return (
    <>
      <FakturaPreview ref={previewRef} />
      <div className="zfadl">
        <Table
          columns={ZFaDLColumns}
          actions={{
            custom: true,
            arrayOfActions: (rowData: any) => (
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="PDF Cenová ponuka">
                  <IconButton
                    onClick={async () => {
                      const cenovaPonuka = getFormattedRowData(rowData);

                      if (
                        !cenovaPonuka.last_edited_by ||
                        !cenovaPonuka.last_edited_date
                      ) {
                        cenovaPonuka.last_edited_by = await getObchodnikById(
                          cenovaPonuka.author ? cenovaPonuka.author : ""
                        );
                        cenovaPonuka.last_edited_date = cenovaPonuka.date;
                      }

                      const { url, doc, fileName } = await generateCenovaPonuka(
                        cenovaPonuka
                      );
                      previewRef.current?.showPreview(url, doc, fileName);
                    }}
                  >
                    <AiOutlineFilePdf size={26} color="#333" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="PDF Zálohová faktura">
                  <IconButton
                    onClick={async () => {
                      const cenovaPonuka = getFormattedRowData(rowData);

                      if (
                        !cenovaPonuka.last_edited_by ||
                        !cenovaPonuka.last_edited_date
                      ) {
                        cenovaPonuka.last_edited_by = await getObchodnikById(
                          cenovaPonuka.author ? cenovaPonuka.author : ""
                        );
                        cenovaPonuka.last_edited_date = cenovaPonuka.date;
                      }

                      const { url, doc, fileName } =
                        await generateZalohovaFaktura(cenovaPonuka);
                      previewRef.current?.showPreview(url, doc, fileName);
                    }}
                  >
                    <AiOutlineFilePdf size={26} color="#333" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="PDF Dodací list">
                  <IconButton
                    onClick={async () => {
                      const cenovaPonuka = getFormattedRowData(rowData);

                      if (
                        !cenovaPonuka.last_edited_by ||
                        !cenovaPonuka.last_edited_date
                      ) {
                        cenovaPonuka.last_edited_by = await getObchodnikById(
                          cenovaPonuka.author ? cenovaPonuka.author : ""
                        );
                        cenovaPonuka.last_edited_date = cenovaPonuka.date;
                      }

                      const { url, doc, fileName } =
                        generateDodaciList(cenovaPonuka);
                      previewRef.current?.showPreview(url, doc, fileName);
                    }}
                  >
                    <AiOutlineFilePdf size={26} color="#333" />
                  </IconButton>
                </Tooltip>
              </td>
            ),
          }}
          data={cp.list.map((cp) => {
            return {
              ...cp,
              cislo_ponuky: cp.cislo_ponuky
                ? formatCPNumber(cp.cislo_ponuky)
                : "",
              objednavatel_nazev: cp.objednavatel?.nazev
                ? cp.objednavatel.nazev
                : "",
              prijimatel_nazev:
                (cp.prijimatel?.jmeno ? cp.prijimatel?.jmeno : "") +
                " " +
                (cp.prijimatel?.prijmeni ? cp.prijimatel?.prijmeni : ""),
              cena_celkem: cp.polozky
                .map((p) => p.cennikova_cena * p.mn)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
            };
          })}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  cp: state.cp,
  auth: state.auth,
});

export default connect(mapStateToProps, { GET_CP })(ZFaDL);
