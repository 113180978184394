import React, { useEffect, useRef, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { CenovaPonuka, Polozka, PolozkaFromArray } from "../../../../ts/CP"
import { Column, TableReference } from "../../../../ts/UI"
import { getSum, returnDPH, totalDPH } from "../../../../utils"
import Button from "../../../ui/Button/Button"
import Table from "../../../ui/Table/Table"
import { useCenovaPonuka, useEditable } from "../CenovePonuky"
import CennikPopup from "./CennikPopup"
import "./Polozky.scss"
import { v4 as uuid } from "uuid"

const columns: Column[] = [
  { key: "id", visible: false },
  { title: "Kód", key: "kod", width: "10%" },
  { title: "Šírka", key: "sirka", width: "5%", type: "number" },
  { title: "Výška", key: "vyska", width: "5%", type: "number" },
  { title: "Dokončená stena", key: "dokoncena_stena", width: "10%" },
  {
    title: "Popis",
    key: "popis",
    width: "20%",
    cellStyle: { fontSize: 13, letterSpacing: "0.02em", fontWeight: 500 },
  },
  { title: "M.J.", key: "mj", width: "2.5%" },
  { title: "MN.", key: "mn", width: "2.5%", type: "number" },
  {
    title: "Cenníková cena",
    key: "cennikova_cena",
    width: "10%",
    type: "number",
  },
  { title: "DPH", key: "dph", width: "5%", type: "number" },
  {
    title: "Cena celkom bez DPH",
    key: "cena_celkom",
    width: "10%",
    editable: false,
  },
  { title: "", actions: true, key: "actions", width: "20%" },
]

interface Props {
  disabled: boolean
  editedCenovaPonuka: CenovaPonuka
  setEditedCenovaPonuka(cp: CenovaPonuka): void
}

interface CennikPopupReference {
  initialize(callback: (item: Polozka) => void): void
}

const Polozky: React.FC<Props> = ({
  disabled,
  editedCenovaPonuka,
  setEditedCenovaPonuka,
}: Props) => {
  const tableRef = useRef<TableReference>(null)
  const cennikRef = useRef<CennikPopupReference>(null)

  const [cenovaPonuka, setCenovaPonuka] = useCenovaPonuka()
  const [editable, , , , newCP] = useEditable()
  const [cennikActive, setCennikActive] = useState(false)

  useEffect(() => {
    if (newCP) {
      setEditedCenovaPonuka({ ...editedCenovaPonuka, polozky: [] })
    }
  }, [newCP])

  useEffect(() => {
    setCennikActive(false)
  }, [editable, newCP])

  const cennikCallback = async (item: Polozka) => {
    if (
      item &&
      (newCP || editable) &&
      editedCenovaPonuka &&
      setEditedCenovaPonuka
    ) {
      setEditedCenovaPonuka({
        ...editedCenovaPonuka,
        polozky: [
          ...editedCenovaPonuka.polozky,
          {
            ...item,
            id: uuid(),
          },
        ],
      })
      setCennikActive(false)
      return
    }

    setCennikActive(false)
  }

  const addNewRowFromCatalogue = () => {
    if (cennikActive) {
      setCennikActive(false)
      return
    }

    setCennikActive(true)
    cennikRef.current?.initialize(cennikCallback)
  }

  const getTableData = () => {
    if (newCP || editable) {
      return editedCenovaPonuka?.polozky.map((p) => {
        return {
          ...p,
          dokoncena_stena:
            p.dokoncena_stena === undefined ? "" : p.dokoncena_stena,
          dph: returnDPH(editedCenovaPonuka.fakturacna, p),
          cena_celkom: (p.cennikova_cena * p.mn).toFixed(2),
        }
      }) as any[]
    } else {
      return cenovaPonuka?.polozky.map((p) => {
        return {
          ...p,
          dokoncena_stena:
            p.dokoncena_stena === undefined ? "" : p.dokoncena_stena,
          dph: returnDPH(cenovaPonuka.fakturacna, p),
          cena_celkom: (p.cennikova_cena * p.mn).toFixed(2),
        }
      }) as any[]
    }
  }

  if (!setCenovaPonuka || !cenovaPonuka) return null

  return (
    <div className="polozky">
      <div className="polozkyTitle">Položky ponuky</div>
      <div className="pridat-polozku-wrapper">
        <Button
          color="dark"
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={disabled}
          onClick={addNewRowFromCatalogue}
        >
          {cennikActive ? (
            <>
              <IoMdClose
                size={16}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              ZRUŠIT
            </>
          ) : (
            "PRIDAŤ POLOŽKU Z CENNÍKA"
          )}
        </Button>
        <Button
          color="dark"
          style={{
            marginLeft: 20,
          }}
          disabled={disabled || cennikActive}
          onClick={() => {
            tableRef.current?.addNewRow()
          }}
        >
          PRIDAŤ POLOŽKU MANUÁLNE
        </Button>
      </div>

      <CennikPopup ref={cennikRef} active={cennikActive} />
      <Table
        visible={!cennikActive}
        ref={tableRef}
        columns={columns}
        actions={true}
        actionsDisabled={!editable}
        data={getTableData()}
        editHandler={(record: any) => {
          let recordIndex = editedCenovaPonuka.polozky.findIndex(
            (polozka) => polozka.id === record.id
          )
          let newPolozky = [...editedCenovaPonuka.polozky]
          delete record.cena_celkom
          newPolozky[recordIndex] = record
          setEditedCenovaPonuka({
            ...editedCenovaPonuka,
            polozky: newPolozky,
          })
        }}
        deleteHandler={(record: PolozkaFromArray) => {
          setEditedCenovaPonuka({
            ...editedCenovaPonuka,
            polozky: editedCenovaPonuka.polozky.filter(
              (polozka) => polozka.id !== record.id
            ),
          })
        }}
        newRowHandler={(record: PolozkaFromArray) => {
          if (newCP || editable) {
            setEditedCenovaPonuka({
              ...editedCenovaPonuka,
              polozky: [
                ...editedCenovaPonuka.polozky,
                {
                  ...record,
                  cennikova_cena: parseFloat(
                    record.cennikova_cena.replace(",", ".")
                  ),
                  dph: parseFloat(record.dph.replace(",", ".")),
                  mn: parseInt(record.mn),
                  sirka: parseFloat(record.sirka.replace(",", ".")),
                  vyska: parseFloat(record.vyska.replace(",", ".")),
                  id: uuid(),
                },
              ],
            })
            return
          }

          setCenovaPonuka({
            ...cenovaPonuka,
            polozky: [
              ...cenovaPonuka.polozky,
              {
                ...record,
                id: uuid(),
                cennikova_cena: parseFloat(
                  record.cennikova_cena.replace(",", ".")
                ),
                dph: parseFloat(record.dph.replace(",", ".")),
                mn: parseInt(record.mn),
                sirka: parseFloat(record.sirka.replace(",", ".")),
                vyska: parseFloat(record.vyska.replace(",", ".")),
              },
            ],
          })
        }}
      />
      {cennikActive ? null : (
        <table className="cena-celkom">
          <tbody>
            <tr>
              <td>Cena celkom: </td>
              <td className="sum">
                {getSum(
                  newCP || editable ? editedCenovaPonuka : cenovaPonuka
                ).toFixed(2)}{" "}
                EUR
              </td>
            </tr>

            <tr>
              <td>Cena celkom s DPH: </td>
              <td className="sum">
                {(getSum(
                  newCP || editable ? editedCenovaPonuka : cenovaPonuka
                )+ totalDPH(
                  newCP || editable ? editedCenovaPonuka : cenovaPonuka
                )).toFixed(2)}{" "}
                EUR
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Polozky
