import classNames from "classnames"
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react"
import Button from "../Button/Button"
import "./StateChange.scss"

import OutsideClickHandler from "react-outside-click-handler"
import { State } from "../../../ts/CP"

interface Props {
  yes(state: State): void
  no?(): void
  title: string
  color?: "primary" | "yellow"
  style?: CSSProperties
}

const StateChange = forwardRef(
  (
    { yes, no, title, color = "primary", style }: Props,
    ref
  ): React.ReactElement<Props> => {
    const [active, setActive] = useState(false)

    useImperativeHandle(
      ref,
      () => ({
        clickHandler() {
          setActive(!active)
        },
      }),
      [active]
    )

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setActive(false)
        }}
      >
        <div
          className={classNames("cp-states", { active }, color)}
          style={style}
        >
          <div className="states-title">Zmeniť stav cenové ponuky</div>
          <div className="states-wrapper">
            <Button
              onClick={() => {
                yes("new")
                setActive(false)
              }}
            >
              NOVÁ
            </Button>
            <Button
              onClick={() => {
                yes("in-progress")
                setActive(false)
              }}
            >
              SPRACOVÁVA SA
            </Button>
            <Button
              onClick={() => {
                yes("finished")
                setActive(false)
              }}
            >
              VYBAVENÁ
            </Button>
          </div>
          <div className="cancel-wrapper">
            <Button onClick={no ? () => no() : () => setActive(false)}>
              Zrušiť
            </Button>
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
)

export default StateChange
