import React, { useEffect, useRef, useState } from "react"
import "./Kontakty.scss"
import { IoMdClose, IoMdCheckmark } from "react-icons/io"
import { initialState, Kontakt as KontaktType } from "../../../ts/Kontakt"
import AttributeInput from "../../ui/Input/AttributeInput"
import { useKontakty, useEditableKontakt } from "./KontaktyWrapper"
import Button from "../../ui/Button/Button"
import DropdownInput, { DataListItem } from "../../ui/Input/DropdownInput"
import { connect } from "react-redux"
import ReduxState from "../../../ts/Redux"
import { GET_SKUPINY } from "../../../redux/actions/skupinyActions"
import { Skupina } from "../../../ts/CSSkupiny"
import { Auth } from "../../../ts/Auth"
import axios from "../../../axios/axios"
import { isKontaktAvailable } from "../../../utils"
import { ConfirmationType, FetchedObchodnik } from "../../../ts/UI"
import Confirmation from "../../ui/Confirmation/Confirmation"

interface Props {
  shown: boolean
  cs_skupiny: Skupina[]
  GET_SKUPINY(auth: Auth): void
  auth: Auth
}

const Kontakty: React.FC<Props> = ({
  shown,
  cs_skupiny,
  GET_SKUPINY,
  auth,
}: Props) => {
  const [kontakt, setKontakt, , addNew] = useKontakty()
  const [editable, setEditable, newKontakt, setNewKontakt] =
    useEditableKontakt()
  const [editedKontakt, setEditedKontakt] = useState<KontaktType>(initialState)
  const [obchodnici, setObchodnici] = useState<FetchedObchodnik[]>([])

  useEffect(() => {
    if (!editable && kontakt) setEditedKontakt(kontakt)
  }, [editable, kontakt])

  useEffect(() => {
    if (kontakt) setEditedKontakt(kontakt)
  }, [kontakt])

  useEffect(() => {
    let isMounted = true

    const fetchObchodnici = async () => {
      const req = await axios.get("/users/fetch-obchodnici", {
        params: {
          admin: auth.user.id,
        },
      })

      if (isMounted) setObchodnici(req.data)
    }

    if (auth.user.function === "root") fetchObchodnici()

    if (cs_skupiny.length === 0) GET_SKUPINY(auth)

    return () => {
      isMounted = false
    }
  }, [cs_skupiny, auth])

  useEffect(() => {
    let isMounted = true

    const getNewKontakt = async () => {
      const req = await axios.get("/kontakty/get-last-id")

      if (isMounted)
        setEditedKontakt({
          ...initialState,
          id_klienta: req.data.success + 1,
          obchodnik: auth.user.id,
        })
    }

    if (!newKontakt && kontakt) {
      setEditedKontakt(kontakt)
      return
    }

    if (newKontakt) getNewKontakt()

    return () => {
      isMounted = false
    }
  }, [newKontakt, kontakt])

  const confirmationRefTop = useRef<ConfirmationType>(null)
  const confirmationRefBottom = useRef<ConfirmationType>(null)

  if (setKontakt === undefined || addNew === undefined || !editedKontakt)
    return null

  const addNewKontakt = async (position: "top" | "bottom") => {
    if (auth.user.function !== "root") editedKontakt.obchodnik = auth.user.id

    const available = await isKontaktAvailable(
      auth.user.id,
      editedKontakt.nazev
    )
    if (available) {
      if (position === "top") confirmationRefTop.current?.clickHandler()
      if (position === "bottom") confirmationRefBottom.current?.clickHandler()
      return
    }

    addNew(editedKontakt)
    setNewKontakt(false)
  }

  return (
    <div
      className="kontakty"
      style={{
        display: shown ? "block" : "none",
      }}
    >
      {editable || newKontakt ? (
        <div className="editable">
          <Confirmation
            title={"Klient je již v databázi, přejete si pokračovat?"}
            yes={() => {
              addNew(editedKontakt)
              setNewKontakt(false)
            }}
            ref={confirmationRefTop}
            style={{
              top: "-100%",
              left: "-12.5%",
              whiteSpace: "nowrap",
            }}
          />
          <Button
            onClick={() => {
              if (newKontakt) {
                addNewKontakt("top")
                return
              }
              setKontakt(editedKontakt)
              setEditable(false)
            }}
          >
            <IoMdCheckmark
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ULOŽIT
          </Button>
          <Button
            onClick={() => {
              if (newKontakt) {
                setNewKontakt(false)
                return
              }
              setEditable(false)
            }}
          >
            <IoMdClose
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ZRUŠIT
          </Button>
        </div>
      ) : null}
      <div>
        <div className="fakturacna-adresa-wrapper">
          <div className="kontakt-section-title">OBJEDNÁVATEĽ</div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Klient / Spoločnost"
              value={editedKontakt?.nazev !== null ? editedKontakt?.nazev : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  nazev: value,
                })
              }
            />
            <DropdownInput
              htmlId="kontakt-skupina-input"
              datalist={cs_skupiny.map((s) => {
                return {
                  value: s.id,
                  title: s.nazev,
                }
              })}
              disabled={!(editable || newKontakt)}
              title="Skupina"
              value={
                cs_skupiny.find((i) => i.id === editedKontakt.skupina)
                  ? cs_skupiny.find((i) => i.id === editedKontakt.skupina)
                      ?.nazev
                  : editedKontakt.skupina
              }
              setValue={(item: DataListItem | string) => {
                if (editable || newKontakt) {
                  if (typeof item === "object" && item) {
                    let findByTitle = cs_skupiny.find(
                      (i) => i.id === item.value
                    )

                    if (findByTitle) {
                      setEditedKontakt({
                        ...editedKontakt,
                        skupina: findByTitle.id,
                      })
                      return
                    }
                  }

                  if (typeof item === "string")
                    setEditedKontakt({
                      ...editedKontakt,
                      skupina: item,
                    })
                }
              }}
            />

            <AttributeInput
              disabled={true}
              title="ID - klienta"
              value={
                editedKontakt?.id_klienta !== null
                  ? editedKontakt?.id_klienta
                  : ""
              }
            />
          </div>
          <div className="in-row">
            <DropdownInput
              disabled={!(editable || newKontakt)}
              htmlId="titul-dropdown"
              datalist={["Mgr.", "Ing."]}
              title="Titul"
              value={editedKontakt.titul}
              setValue={(value: string) => {
                if (editable || newKontakt)
                  setEditedKontakt({
                    ...editedKontakt,
                    titul: value,
                  })
              }}
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Meno"
              value={editedKontakt?.jmeno !== null ? editedKontakt?.jmeno : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  jmeno: value,
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Priezvisko"
              value={
                editedKontakt?.prijmeni !== null ? editedKontakt?.prijmeni : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  prijmeni: value,
                })
              }
            />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Funkcia"
              value={
                editedKontakt?.funkce !== null ? editedKontakt?.funkce : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  funkce: value,
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Adresa"
              value={
                editedKontakt?.adresa !== null ? editedKontakt?.adresa : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  adresa: value,
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="PSČ"
              value={editedKontakt?.psc !== null ? editedKontakt?.psc : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  psc: value,
                })
              }
            />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Mesto"
              value={editedKontakt?.obec !== null ? editedKontakt?.obec : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  obec: value,
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Okres"
              value={editedKontakt?.okres !== null ? editedKontakt?.okres : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  okres: value,
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Štát"
              value={editedKontakt?.stat !== null ? editedKontakt?.stat : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  stat: value,
                })
              }
            />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Tel."
              value={editedKontakt?.tel !== null ? editedKontakt?.tel : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  tel: value,
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Mobil"
              value={editedKontakt?.mobil !== null ? editedKontakt?.mobil : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  mobil: value,
                })
              }
            />
            <div style={{ maxWidth: 360 }} />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Email"
              value={editedKontakt?.email !== null ? editedKontakt?.email : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  email: value,
                })
              }
            />
            <div className="in-row">
              <AttributeInput
                disabled={!(editable || newKontakt)}
                style={{
                  width: "50%"
                }}
                title="Rabat (%)"
                type="number"
                value={
                  editedKontakt?.rabat !== null ? editedKontakt?.rabat : ""
                }
                setValue={(value: number) =>
                  setEditedKontakt({
                    ...editedKontakt,
                    rabat: value,
                  })
                }
              />
              <AttributeInput
                disabled={!(editable || newKontakt)}
                style={{
                  width: "50%"
                }}
                title="Splatnosť faktúr"
                value={
                  editedKontakt?.splatnost !== null
                    ? editedKontakt?.splatnost
                    : ""
                }
                setValue={(value: string) =>
                  setEditedKontakt({
                    ...editedKontakt,
                    splatnost: value,
                  })
                }
              />
            </div>
            <div style={{ maxWidth: 360 }} />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Web"
              value={editedKontakt?.web !== null ? editedKontakt?.web : ""}
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  web: value,
                })
              }
            />
            <div className="in-row">
              <AttributeInput
                style={{
                  width: "50%",
                }}
                disabled={!(editable || newKontakt)}
                title="Bonus (%)"
                type="number"
                value={
                  editedKontakt?.bonus !== null ? editedKontakt?.bonus : ""
                }
                setValue={(value: number) =>
                  setEditedKontakt({
                    ...editedKontakt,
                    bonus: value,
                  })
                }
              />
            </div>
            <div style={{ maxWidth: 360 }} />
          </div>
          <div className="in-row">
            <DropdownInput
              htmlId="kontakt-skupina-input"
              datalist={obchodnici.map((s) => {
                return {
                  value: s.id,
                  title: s.fullName,
                }
              })}
              disabled={
                !(editable || newKontakt) || auth.user.function !== "root"
              }
              freeSolo={false}
              title="Obchodník"
              value={
                auth.user.function === "root"
                  ? obchodnici.find((i) => i.id === editedKontakt.obchodnik)
                    ? obchodnici.find((i) => i.id === editedKontakt.obchodnik)
                        ?.fullName
                    : editedKontakt.obchodnik
                  : {
                      title: auth.user.firstName + " " + auth.user.lastName,
                      value: auth.user.id,
                    }
              }
              setValue={(item: DataListItem | string) => {
                if (editable || newKontakt) {
                  if (typeof item === "object" && item) {
                    let findByTitle = obchodnici.find(
                      (i) => i.id === item.value
                    )

                    if (findByTitle) {
                      setEditedKontakt({
                        ...editedKontakt,
                        obchodnik: findByTitle.id,
                      })
                      return
                    }
                  }

                  if (typeof item === "string")
                    setEditedKontakt({
                      ...editedKontakt,
                      obchodnik: item,
                    })
                }
              }}
            />
            <div style={{ maxWidth: 360 }} />
            <div style={{ maxWidth: 360 }} />
          </div>
        </div>

        <div className="objednavatel-wrapper">
          <div className="kontakt-section-title">FAKTURAČNÁ ADRESA</div>

          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Názov spoločnosti"
              value={
                editedKontakt?.fakturacna?.nazev !== null
                  ? editedKontakt?.fakturacna?.nazev
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    nazev: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Meno kontaktnej osoby"
              value={
                editedKontakt?.fakturacna?.jmeno !== null
                  ? editedKontakt?.fakturacna?.jmeno
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    jmeno: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Priezvisko kontaktnej osoby"
              value={
                editedKontakt?.fakturacna?.prijmeni !== null
                  ? editedKontakt?.fakturacna?.prijmeni
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    prijmeni: value,
                  },
                })
              }
            />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Ulica"
              value={
                editedKontakt?.fakturacna?.adresa !== null
                  ? editedKontakt?.fakturacna?.adresa
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    adresa: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="PSČ"
              value={
                editedKontakt?.fakturacna?.psc !== null
                  ? editedKontakt?.fakturacna?.psc
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    psc: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Mesto"
              value={
                editedKontakt?.fakturacna?.obec !== null
                  ? editedKontakt?.fakturacna?.obec
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    obec: value,
                  },
                })
              }
            />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Okres"
              value={
                editedKontakt?.fakturacna?.okres !== null
                  ? editedKontakt?.fakturacna?.okres
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    okres: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Telefón"
              value={
                editedKontakt?.fakturacna?.tel !== null
                  ? editedKontakt?.fakturacna?.tel
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    tel: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="Email"
              value={
                editedKontakt?.fakturacna?.email !== null
                  ? editedKontakt?.fakturacna?.email
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    email: value,
                  },
                })
              }
            />
          </div>
          <div className="in-row">
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="IČO"
              value={
                editedKontakt?.fakturacna.ico !== null
                  ? editedKontakt?.fakturacna.ico
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    ico: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="DIČ"
              value={
                editedKontakt?.fakturacna.dic !== null
                  ? editedKontakt?.fakturacna.dic
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    dic: value,
                  },
                })
              }
            />
            <AttributeInput
              disabled={!(editable || newKontakt)}
              title="IČ DPH"
              value={
                editedKontakt?.fakturacna.icodph !== null
                  ? editedKontakt?.fakturacna.icodph
                  : ""
              }
              setValue={(value: string) =>
                setEditedKontakt({
                  ...editedKontakt,
                  fakturacna: {
                    ...editedKontakt.fakturacna,
                    icodph: value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>

      {editable || newKontakt ? (
        <div className="editable">
          <Confirmation
            title={"Klient je již v databázi, přejete si pokračovat?"}
            yes={() => {
              addNew(editedKontakt)
              setNewKontakt(false)
            }}
            ref={confirmationRefBottom}
            style={{
              top: "-100%",
              left: "-12.5%",
              whiteSpace: "nowrap",
            }}
          />
          <Button
            onClick={() => {
              if (newKontakt) {
                addNewKontakt("bottom")
                return
              }
              setKontakt(editedKontakt)
              setEditable(false)
            }}
          >
            <IoMdCheckmark
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ULOŽIT
          </Button>
          <Button
            onClick={() => {
              if (newKontakt) {
                setNewKontakt(false)
                return
              }
              setEditable(false)
            }}
          >
            <IoMdClose
              size={16}
              style={{
                marginRight: 10,
              }}
            />
            ZRUŠIT
          </Button>
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  cs_skupiny: state.cs_skupiny,
  auth: state.auth,
})

export default connect(mapStateToProps, { GET_SKUPINY })(Kontakty)
