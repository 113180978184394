import { Action, Dispatch } from "redux"
import { FETCH_CENIK } from "../../components/main/Cenik/utils"

export const GET_CENIK =
  (id: string) => async(dispatch: Dispatch<Action<"SET_CENIK">>) => {
    const products = await FETCH_CENIK(id)

    dispatch({
      type: "SET_CENIK",
      payload: products,
    })
  }