import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import axios from "../../../axios/axios"
import { Auth } from "../../../ts/Auth"
import ReduxState from "../../../ts/Redux"
import { Column, FetchedObchodnik } from "../../../ts/UI"
import Loading from "../../ui/Loading/Loading"
import Table from "../../ui/Table/Table"
import "./Pouzivatelia.scss"

const obchodniciColumns: Column[] = [
  { key: "id", visible: false },
  { key: "fullName", width: "20%", title: "Meno a priezvisko" },
  { key: "email", width: "20%", title: "EMAIL" },
  { key: "function", width: "20%", title: "Funkcia" },
  { key: "mobil", width: "20%", title: "Mobil" },
  { key: "last_online", width: "20%", title: "Naposledy prihlásený" },
]

interface Props {
  auth: Auth
}

const Pouzivatelia: React.FC<Props> = ({ auth }: Props) => {
  const [obchodnici, setObchodnici] = useState<FetchedObchodnik[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchObchodnici = async () => {
      if (isMounted) setLoading(true)
      const req = await axios.get("/users/fetch-obchodnici", {
        params: {
          admin: auth.user.id,
        },
      })
      if (isMounted) {
        setObchodnici(req.data)
        setLoading(false)
      }
    }

    fetchObchodnici()

    return () => {
      isMounted = false
    }
  }, [])

  if (loading) return <Loading text="Získávanie obchodníkov zo servera" />

  return (
    <div className="pouzivatelia">
      <div className="title-wrapper">POUŽIVATELIA</div>
      <Table
        actionsDisabled
        columns={obchodniciColumns}
        data={obchodnici.map((o) => {
          return {
            ...o,
            id: o.id,
            email: o.email ? o.email : "",
            fullName: o.fullName ? o.fullName : "",
            function: o.function,
            mobil: o.mobil ? o.mobil : "",
            last_online: o.last_online
              ? new Date(o.last_online).toLocaleDateString() +
                " " +
                new Date(o.last_online).toLocaleTimeString()
              : "Nikdy",
          }
        })}
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(Pouzivatelia)
