import { Obrat } from "../../ts/Obraty"

const initialState: Obrat[] = []

type Action = {
  type: string
  payload: any
}

export const obratyReducer = (state: Obrat[] = initialState, action: Action) => {
  switch (action.type) {
    case "ADD_OBRATY":
      return { ...state, ...action.payload }
    case "SET_OBRATY":
      return action.payload
    default:
      return state
  }
}
