import React, { CSSProperties, useEffect, useState } from "react"
import { Column } from "../../../ts/UI"
import Actions from "./Actions"
import TableInput from "./TableInput"

interface Props {
  columns: Column[]
  confirm(data: any): void
  cancel(): void
}

const NewRow: React.FC<Props> = ({ columns, cancel, confirm }: Props) => {
  const [columnValues, setColumnValues] = useState<{ [key: string]: string }>(
    () => {
      let indexed: { [key: string]: string } = {}

      columns.forEach((column) => {
        if (column.key !== "actions") indexed[column.key] = ""
      })
      return indexed
    }
  )

  return (
    <tr className="newRow">
      {columns.map((column, i) =>
        column.key === "actions" || column.visible === false ? null : (
          <td key={i} style={{ ...column.cellStyle, width: column.width }}>
            <TableInput
              disabled={column.editable === false}
              visible={true}
              value={columnValues[column.key]}
              onChange={(e) => {
                let newValues = { ...columnValues }
                newValues[column.key] = e.target.value
                setColumnValues(newValues)
              }}
            />
          </td>
        )
      )}
      <Actions
        style={{
          ...columns.find((c) => c.key === "actions")?.cellStyle,
          width: columns.find((c) => c.key === "actions")?.width,
        }}
        active={true}
        close={() => {
          cancel()
          setColumnValues({})
        }}
        confirm={() => {
          confirm(columnValues)
          setColumnValues({})
        }}
        handleEdit={() => null}
        handleRemove={() => null}
      />
    </tr>
  )
}

export default NewRow
