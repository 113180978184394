import classNames from "classnames"
import React from "react"
import { FiCheck } from "react-icons/fi"
import "./Checkbox.scss"

interface Props {
  checked: boolean
  onClick(state: boolean): void
  disabled?: boolean
}

const Checkbox: React.FC<Props> = ({ checked, onClick, disabled }: Props) => {
  return (
    <div
      onClick={disabled ? undefined : () => onClick(!checked)}
      className={classNames("checkBox", { checked, disabled })}
    >
      <FiCheck className="checkmark" />
    </div>
  )
}

export default Checkbox
