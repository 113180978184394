export const initialState: Kontakt = {
  id: "",
  nazev: "",
  skupina: "",
  id_klienta: "",
  adresa: "",
  email: "",
  funkce: "",
  jmeno: "",
  mobil: "",
  obchodnik: "",
  obec: "",
  okres: "",
  prijmeni: "",
  psc: "",
  rabat: 0,
  splatnost: "",
  stat: "",
  titul: "",
  bonus: 0,
  tel: "",
  web: "",
  fakturacna: {
    nazev: "",
    jmeno: "",
    prijmeni: "",
    adresa: "",
    psc: "",
    obec: "",
    okres: "",
    tel: "",
    email: "",
    ico: "",
    icodph: "",
    dic: ""
  },
  author: ""
}

type Fakturacna = {
  nazev: string
  jmeno: string
  prijmeni: string
  adresa: string
  psc: string
  obec: string
  okres: string
  tel?: string
  email: string
  ico: string
  dic: string
  icodph: string
}
export interface Kontakt {
  id: string
  nazev: string
  skupina: string
  id_klienta?: string
  titul: string
  jmeno: string
  prijmeni: string
  funkce: string
  adresa: string
  psc: string
  obec: string
  okres: string
  stat: string
  tel?: string
  mobil: string
  email: string
  web?: string
  rabat: number
  bonus: number
  splatnost: string
  obchodnik: string
  fakturacna: Fakturacna
  author: string
}
