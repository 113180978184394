import React, { useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import { RiCloseFill } from "react-icons/ri"
import { connect } from "react-redux"
import { GET_CENIK } from "../../../redux/actions/cenikActions"
import { Auth } from "../../../ts/Auth"
import { ICenik, Item } from "../../../ts/Cenik"
import ReduxState from "../../../ts/Redux"
import { Row, TableReference } from "../../../ts/UI"
import Button from "../../ui/Button/Button"
import Input from "../../ui/Input/Input"
import Loading from "../../ui/Loading/Loading"
import Table from "../../ui/Table/Table"
import "./Cenik.scss"
import { FETCH_CENIK } from "./utils"

interface Props {
  cenik: ICenik
  rowClickHandler?(row: Row): void
  GET_CENIK(id: string): void
  auth: Auth
}

const columns = [
  { key: "id", visible: false },
  { title: "Kód", key: "kod", width: "20%" },
  { title: "Šírka", key: "sirka", width: "10%" },
  { title: "Výška", key: "vyska", width: "10%" },
  { title: "Dokončená stena", key: "dokoncena_stena", width: "10%" },
  {
    title: "Popis",
    key: "popis",
    width: "20%",
    cellStyle: { fontSize: 13, letterSpacing: "0.02em", fontWeight: 500 },
  },
  { title: "Merná jednotka", key: "mj", width: "15%" },
  { title: "Cena", key: "cennikova_cena", width: "15%" },
]

const Cenik: React.FC<Props> = ({ cenik, rowClickHandler, GET_CENIK, auth }: Props) => {
  const [popis, setPopis] = useState("")
  const [kod, setKod] = useState("")
  const [sirka, setSirka] = useState("")
  const [vyska, setVyska] = useState("")
  const [filter, setFilter] = useState(false)

  useEffect(() => {
    if (cenik.list.length === 0) GET_CENIK(auth.user.id)
  }, [cenik.list.length, auth.user.id])

  const tableRef = useRef<TableReference>(null)

  const clearInputs = () => {
    setPopis("")
    setKod("")
    setSirka("")
    setVyska("")
    setFilter(false)
    tableRef.current?.removeFilter()
  }

  return (
    <div
      className="cenik"
      style={{
        width: rowClickHandler && "100%",
      }}
    >
      {cenik.loading ? (
        <div className="loading-cenik">
          <Loading
            text={[
              "Získavanie položiek z eshopu",
              "Nahrávanie položiek do cenníka",
            ]}
            interval={8000}
          />
        </div>
      ) : (
        <>
          {rowClickHandler ? null : <div className="cenikTitle">Cenník</div>}
          <div className="searchInputs">
            <div className="nazev-kod">
              <div>
                <div className="input-placeholder">Názov</div>
                <Input
                  value={popis}
                  onChange={(e) => setPopis(e.target.value)}
                />
              </div>
              <div>
                <div className="input-placeholder">Kód</div>
                <Input value={kod} onChange={(e) => setKod(e.target.value)} />
              </div>
            </div>

            <div className="sirka-vyska">
              <div>
                <div className="input-placeholder">Šírka</div>
                <Input
                  type="number"
                  min={1}
                  value={sirka}
                  onChange={(e) => setSirka(e.target.value)}
                />
              </div>
              <div>
                <div className="input-placeholder">Výška</div>
                <Input
                  min={1}
                  type="number"
                  value={vyska}
                  onChange={(e) => setVyska(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled={
                  popis === "" && sirka === "" && kod === "" && vyska === ""
                }
                onClick={() => {
                  setFilter(true)
                  tableRef.current?.filterByInput({
                    popis,
                    kod,
                    sirka: parseInt(sirka),
                    vyska: parseInt(vyska),
                  })
                }}
              >
                <BsSearch
                  size={17}
                  style={{
                    marginRight: 16,
                  }}
                />
                HĽADAŤ
              </Button>
              {filter ? (
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                  onClick={clearInputs}
                >
                  <RiCloseFill
                    size={15}
                    style={{
                      marginRight: 8,
                    }}
                  />
                  VYMAZAT FILTR
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            rowClasses={rowClickHandler && ["hover-cp"]}
            rowClickHandler={(row: Row) => {
              if (rowClickHandler) {
                rowClickHandler(row)
                clearInputs()
              }
            }}
            ref={tableRef}
            columns={columns}
            data={cenik.list}
          />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  cenik: state.cenik
})

export default connect(mapStateToProps, { GET_CENIK })(Cenik)
