import { Errors } from "../../ts/Errors"

const initialState: Errors = {}

type Action = {
  type: string
  payload: any
}

export const errorsReducer = (state: Errors = initialState, action: Action) => {
  switch (action.type) {
    case "ADD_ERROR":
      return { ...state, ...action.payload }
    case "SET_ERRORS":
      return action.payload
    case "REMOVE_ERRORS":
      return {}
    default:
      return state
  }
}
