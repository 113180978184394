import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import "./FakturaPreview.scss"
import { Portal } from "react-portal"
import classNames from "classnames"
import { IconButton, Tooltip } from "@material-ui/core"
import { FiSave } from "react-icons/fi"
import { IoMdClose } from "react-icons/io"

export interface FakturaPreviewType {
  showPreview(url: string, doc: any, fileName: string): void
}

interface Props {}

const FakturaPreview = forwardRef(({}: Props, ref) => {
  const [preview, setPreview] = useState<string>("")
  const [doc, setDoc] = useState<any>(null)
  const [fileName, setFileName] = useState("")

  const close = () => {
    setPreview("")
    setDoc(null)
    setFileName("")
  }

  useEffect(() => {
    const handleEscKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Escape") close()
    }

    if (preview === "")
      document.removeEventListener("keydown", handleEscKeyPress)
    else document.addEventListener("keydown", handleEscKeyPress)
  }, [preview])

  useImperativeHandle(
    ref,
    () => ({
      showPreview(url: string, doc: any, fileName: string) {
        setPreview(url)
        setDoc(doc)
        setFileName(fileName)
      },
    }),
    []
  )

  return (
    <Portal node={document && document.getElementById("root")}>
      <div
        className={classNames("faktura-preview", { visible: preview !== "" })}
      >
        <iframe src={preview}></iframe>
        <div className="button-wrapper">
          <div>
            <Tooltip
              title="Zrušit"
              onClick={close}
            >
              <IconButton>
                <IoMdClose />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Uložit">
              <IconButton
                onClick={() => {
                  doc.save(fileName)
                }}
              >
                <FiSave />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </Portal>
  )
})

export default FakturaPreview
