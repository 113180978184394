import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Skupina } from "../../../ts/CSSkupiny"
import ReduxState from "../../../ts/Redux"
import { Column, TableReference } from "../../../ts/UI"
import Button from "../../ui/Button/Button"
import Table from "../../ui/Table/Table"
import "./Skupiny.scss"

import {
  ADD_SKUPINA,
  DELETE_SKUPINA,
  GET_SKUPINY,
  EDIT_SKUPINA,
} from "../../../redux/actions/skupinyActions"
import { Auth } from "../../../ts/Auth"

interface Props {
  auth: Auth
  cs_skupiny: Skupina[]
  ADD_SKUPINA(skupina: Skupina, auth: Auth): void
  DELETE_SKUPINA(skupina: Skupina, auth: Auth): void
  GET_SKUPINY(auth: Auth): void
  EDIT_SKUPINA(skupina: Skupina, auth: Auth): void
}

const skupinyColumns: Column[] = [
  { key: "id", visible: false },
  { title: "Názov", key: "nazev", width: "40%" },
  { title: "Zľava", key: "sleva", width: "30%", type: "number" },
  { key: "actions", actions: true, width: "30%" },
]

const Skupiny: React.FC<Props> = ({
  auth,
  cs_skupiny,
  ADD_SKUPINA,
  DELETE_SKUPINA,
  GET_SKUPINY,
  EDIT_SKUPINA
}: Props) => {
  const tableRef = useRef<TableReference>(null)

  useEffect(() => {
    if (cs_skupiny.length === 0) GET_SKUPINY(auth)
  }, [cs_skupiny, auth])

  return (
    <div className="cs-skupiny-wrapper">
      <div className="title">Číselník: Skupiny kontaktov</div>
      <div className="new-skupina">
        <Button
          onClick={() => {
            tableRef.current?.addNewRow()
          }}
        >
          Vytvoriť nový záznam
        </Button>
      </div>
      <Table
        actions={true}
        ref={tableRef}
        deleteHandler={(skupina: Skupina) => {
          DELETE_SKUPINA(skupina, auth)
        }}
        editHandler={(skupina: Skupina) => {
          EDIT_SKUPINA(skupina, auth)
        }}
        newRowHandler={(skupina: Skupina) => {
          ADD_SKUPINA(skupina, auth)
        }}
        columns={skupinyColumns}
        data={cs_skupiny.filter((s) => s.author)}
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  cs_skupiny: state.cs_skupiny,
})

export default connect(mapStateToProps, {
  ADD_SKUPINA,
  DELETE_SKUPINA,
  GET_SKUPINY,
  EDIT_SKUPINA,
})(Skupiny)
