import { CenovaPonuka, State } from "../../ts/CP"

type Action = {
  type: string
  payload: any
}

export const cpReducer = (
  state: {
    loading: boolean
    list: CenovaPonuka[]
  } = {
    loading: false,
    list: [],
  },
  action: Action
) => {
  switch (action.type) {
    case "SET_LOADING": {
      return { ...state, loading: action.payload }
    }
    case "EDIT_CP": {
      const cp: CenovaPonuka = action.payload
      let editedCenovePonuky = [...state.list]
      editedCenovePonuky[editedCenovePonuky.findIndex((c) => c.id === cp.id)] =
        cp
      return {
        loading: state.loading,
        list: editedCenovePonuky,
      }
    }
    case "EDIT_CP_STATE": {
      const cp: CenovaPonuka = action.payload.cp
      const cpState: State = action.payload.state
      let editedCenovePonuky = [...state.list]
      editedCenovePonuky[
        editedCenovePonuky.findIndex((c) => c.id === cp.id)
      ].state = cpState
      return {
        loading: state.loading,
        list: editedCenovePonuky,
      }
    }
    case "ADD_CP": {
      return { ...state, list: [...state.list, action.payload] }
    }
    case "SET_CP":
      return {
        loading: state.loading,
        list: action.payload,
      }
    case "DELETE_CP": {
      const id = action.payload
      return {
        loading: state.loading,
        list: state.list.filter((cp) => cp.id !== id),
      }
    }
    default:
      return state
  }
}
