import React from "react"
import "./Button.scss"

import classNames from "classnames"
import { ButtonHTMLAttributes } from "react"
import Loading from "../Loading/Loading"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "dark" | "white"
  error?: boolean
  loading?: boolean
}

const Button: React.FC<Props> = ({
  color = "primary",
  error,
  children,
  loading,
  ...props
}: Props) => {
  return (
    <button
      className={classNames("styled-button", color)}
      {...props}
      disabled={props.disabled || error}
    >
      {loading ? <Loading size={17} border={2} /> : children}
    </button>
  )
}

export default Button
