import axios from "../../../axios/axios"
import { Item } from "../../../ts/Cenik"

export const FETCH_CENIK = async (id: string) => {
  return new Promise<Item[]>((resolve, reject) => {
    axios.get("/eshop/fetch-cenik", {
        params: {
            id
        }
    }).then((val) => {
        resolve(MAP_FETCHED_CENIK_TO_TABLE(val.data))
    })
  })
}

const MAP_FETCHED_CENIK_TO_TABLE = (f: any): Item[]  => {
    let mappedArr: Item[] = []

    f.forEach((p: any) => {
        if(p.associations.combinations) {
            p.associations.combinations.forEach((c: any) => {
                mappedArr.push({
                    id: p.id,
                    cennikova_cena: parseFloat(p.price) + parseFloat(c.price),
                    dokoncena_stena: c.product_option_values.ds,
                    kod: c.reference,
                    mj: "ks",
                    popis: p.name,
                    sirka: c.product_option_values.l,
                    vyska: c.product_option_values.h
                })
            })
        } else {
            mappedArr.push({
                id: p.id,
                cennikova_cena: parseFloat(p.price),
                dokoncena_stena: "",
                kod: p.reference,
                mj: "ks",
                popis: p.name,
                sirka: p.width,
                vyska: p.height
            })
        }
    })

    return mappedArr
}
