import axios from "../../axios/axios"

import { Action, Dispatch } from "redux"
import { EditUser, LoginUser, User } from "../../ts/Auth"
import { setAuthToken } from "../../utils"

import jwt_decode from "jwt-decode"

export const EDIT_USER =
  (id: string, user: EditUser) =>
  (dispatch: Dispatch<Action<"SET_USER" | "SET_ERRORS">>) => {
    axios
      .post("/users/edit", { id, user })
      .then((res) => {
        const { token } = res.data
        localStorage.setItem("jwtToken", token)
        setAuthToken(token)
        const decoded = jwt_decode(token)
        dispatch({
          type: "SET_ERRORS",
          payload: {},
        })
        dispatch({
          type: "SET_USER",
          payload: {
            user: decoded,
            logged: true,
          },
        })
      })
      .catch(({ response }) => {
        if (response)
          dispatch({
            type: "SET_ERRORS",
            payload: response.data.errors,
          })
      })
  }

export const LOGIN_USER =
  (user: LoginUser, callback: () => void) =>
  (dispatch: Dispatch<Action<"SET_USER" | "SET_ERRORS">>) => {
    axios
      .post("/users/login", user)
      .then((res) => {
        const { token } = res.data
        localStorage.setItem("jwtToken", token)
        setAuthToken(token)
        const decoded = jwt_decode(token)
        callback()
        dispatch({
          type: "SET_ERRORS",
          payload: {},
        })
        dispatch({
          type: "SET_USER",
          payload: {
            user: decoded,
            logged: true,
          },
        })
      })
      .catch(({ response }) => {
        callback()
        if (response)
          dispatch({
            type: "SET_ERRORS",
            payload: response.data.errors,
          })
      })
  }

export const LOGOUT_USER = () => (dispatch: Dispatch<Action<any>>) => {
  localStorage.removeItem("jwtToken")
  setAuthToken(false)
  dispatch({
    type: "SET_KONTAKTY",
    payload: [],
  })
  dispatch({
    type: "SET_CP",
    payload: [],
  })
  dispatch({
    type: "SET_SKUPINY",
    payload: [],
  })
  dispatch({
    type: "SET_USER",
    payload: {},
  })
}

export const SET_USER =
  (user: User) => (dispatch: Dispatch<Action<"SET_USER">>) => {
    dispatch({
      type: "SET_USER",
      payload: {
        user,
        logged: true,
      },
    })
  }
