import { Action, Dispatch } from "redux"
import { Kontakt } from "../../ts/Kontakt"
import axios from "../../axios/axios"

export const EDIT_KONTAKT =
  (kontakt: Kontakt, id: string) =>
  async (dispatch: Dispatch<Action<"EDIT_KONTAKT">>) => {
    await axios.post("/kontakty/edit", {
      kontakt,
      id,
    })
    dispatch({
      type: "EDIT_KONTAKT",
      payload: kontakt,
    })
  }

export const ADD_KONTAKT =
  (kontakt: Kontakt, id: string) =>
  async (dispatch: Dispatch<Action<"ADD_KONTAKT">>) => {
    const req = await axios.post("/kontakty/add", {
      kontakt,
      id,
    })

    kontakt.id = req.data.id
    kontakt.author = id;

    dispatch({
      type: "ADD_KONTAKT",
      payload: kontakt,
    })
  }

export const ADD_KONTAKTY =
  (kontakty: Kontakt[], id: string, callback?: (success: boolean) => void) =>
  async (dispatch: Dispatch<Action<"SET_KONTAKTY">>) => {
    try {
      const req = await axios.post("/kontakty/add-bulk", {
        kontakty,
        id,
      })

      if(req.data.length > 0 && callback)
        callback(true)

      dispatch({
        type: "SET_KONTAKTY",
        payload: req.data,
      })
    } catch (err) {
      if(callback)
        callback(false)
    }
  }

export const GET_KONTAKTY =
  (id: string) => async (dispatch: Dispatch<Action<"SET_KONTAKTY">>) => {
    const req = await axios.get("/kontakty", {
      params: {
        id,
      },
    })

    if (req.data.length > 0)
      dispatch({
        type: "SET_KONTAKTY",
        payload: req.data,
      })
  }

export const DELETE_KONTAKT =
  (kontakt: Kontakt, id: string) =>
  async (dispatch: Dispatch<Action<"DELETE_KONTAKT">>) => {
    await axios.post("/kontakty/delete", {
      kontakt,
      id,
    })
    dispatch({
      type: "DELETE_KONTAKT",
      payload: kontakt.id,
    })
  }
