import { generateQRPayment } from "./generateQRPayment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import * as QRCode from "qrcode";
import EclisseLogo from "../styles/eclisse.png";
import {
  CenovaPonuka as CenovaPonukaType,
  LastEdited,
  Polozka,
} from "../ts/CP";
import { getCPDate, returnDPH, totalDPH } from "../utils";
import { addFont } from "./Roboto-Regular-normal";

const PADDING_MM = 14;

export const generateZalohovaFaktura = async (data: CenovaPonukaType) => {
  (() => {
    const goTroughObject = (object: any) => {
      Object.keys(object).map((k) => {
        if (typeof object[k] === "object" && object[k])
          goTroughObject(object[k]);
        if (object[k] === null) object[k] = "";
      });
    };

    goTroughObject(data);
  })();

  addFont(jsPDF.API);

  const doc = new jsPDF();

  doc.addImage(EclisseLogo, 11, PADDING_MM, 60, 24);

  doc.setFont("Roboto-Regular", "bold");
  doc.setFontSize(15);

  doc.text("Zálohová faktúra", 92 + PADDING_MM, 39);

  doc.setFont("Roboto-Regular", "normal");
  doc.setFontSize(10);
  doc.text("var. symbol: ", 92 + PADDING_MM + 4 + 35 + 4, 39); //cislo ponuky

  doc.setFontSize(12);
  doc.setFont("Roboto-Regular", "bold");
  doc.text(data.cislo_ponuky.toString(), 92 + PADDING_MM + 8 + 35 + 20, 39); //cislo ponuky

  doc.setFont("Roboto-Regular", "bold");
  doc.setFontSize(8);

  doc.text("Dodávateľ:", PADDING_MM, 44, {
    maxWidth: 95,
  });
  doc.setFontSize(9);
  doc.text("ECLISSE Slovakia, s.r.o.", PADDING_MM, 49, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text("Zvolenská cesta 23, 974 05 Banská Bystrica", PADDING_MM, 54, {
    maxWidth: 95,
  });

  doc.setFont("Roboto-Regular", "bold");
  doc.text("IČO: ", PADDING_MM, 59, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text("36663077, ", PADDING_MM + 7, 59, {
    maxWidth: 95,
  });

  doc.setFont("Roboto-Regular", "bold");
  doc.text("DIČ: ", PADDING_MM + 23, 59, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text("2022227042, ", PADDING_MM + 30, 59, {
    maxWidth: 95,
  });

  doc.setFont("Roboto-Regular", "bold");
  doc.text("IČ DPH: ", PADDING_MM + 49, 59, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text("SK2022227042", PADDING_MM + 61, 59, {
    maxWidth: 95,
  });

  doc.setFont("Roboto-Regular", "bold");
  doc.text("Register: ", PADDING_MM, 64, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text("OR.OS Banská Bystrica odd. Sro. vl. 11960/S", PADDING_MM + 14, 64, {
    maxWidth: 95,
  });

  doc.setFont("Roboto-Regular", "bold");
  doc.text("Banky: ", PADDING_MM, 69, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text("ČSOB: IBAN: SK69 7500 0000 0040 1078 0897,", PADDING_MM + 11, 69, {
    maxWidth: 95,
  });
  doc.text(
    "             Tatrabanka: IBAN: SK24 1100 0000 0026 2409 8877",
    PADDING_MM,
    74,
    {
      maxWidth: 95,
    }
  );

  doc.setFont("Roboto-Regular", "bold");
  doc.text("Kontakt:", PADDING_MM, 79, {
    maxWidth: 95,
  });
  doc.setFont("Roboto-Regular", "normal");
  doc.text(
    "tel. +421 4160700, Email: eclisse@eclisse.sk",
    PADDING_MM + 13,
    79,
    {
      maxWidth: 95,
    }
  );

  doc.setFillColor(242, 242, 242);
  doc.setFontSize(11);
  doc.setFont("Roboto-Regular", "normal");
  const up_right_rect_start = 74;
  const up_right_rect_start_x = -0.5;
  doc.rect(
    up_right_rect_start_x + PADDING_MM,
    up_right_rect_start + 7,
    102 - PADDING_MM,
    38,
    "F"
  );
  doc.text(
    "Objednávateľ:",
    up_right_rect_start_x + 2 + PADDING_MM,
    up_right_rect_start + 13
  );
  doc.text(
    "Osoba:",
    up_right_rect_start_x + 2 + PADDING_MM,
    up_right_rect_start + 24
  );
  doc.text(
    "Ulica:",
    up_right_rect_start_x + 2 + PADDING_MM,
    up_right_rect_start + 30
  );
  doc.text(
    "PSČ:",
    up_right_rect_start_x + 2 + PADDING_MM,
    up_right_rect_start + 36
  );
  doc.text(
    "Obec:",
    up_right_rect_start_x + 2 + PADDING_MM + 35,
    up_right_rect_start + 36
  );
  doc.setFontSize(9);

  if (data.objednavatel) {
    doc.setFont("Roboto-Regular", "bold");
    doc.setFontSize(11);
    doc.text(
      data.objednavatel.nazev ? data.objednavatel.nazev : "",
      up_right_rect_start_x + 2 + PADDING_MM + 28,
      up_right_rect_start + 13,
      {
        maxWidth: 54,
      }
    ); //objednavatel?.nazev
    doc.setFontSize(10);
    doc.text(
      data.objednavatel.kontaktna_osoba
        ? data.objednavatel.kontaktna_osoba
        : "",
      up_right_rect_start_x + 2 + PADDING_MM + 18,
      up_right_rect_start + 24
    ); //objednavatel?.kontaktna_osoba
    doc.text(
      data.objednavatel.adresa ? data.objednavatel.adresa : "",
      up_right_rect_start_x + 2 + PADDING_MM + 18,
      up_right_rect_start + 30,
      {
        maxWidth: 64,
      }
    ); //ulice
    doc.text(
      data.objednavatel.psc ? data.objednavatel.psc : "",
      up_right_rect_start_x + 2 + PADDING_MM + 18,
      up_right_rect_start + 40
    ); //psč
    doc.text(
      data.objednavatel.obec ? data.objednavatel.obec : "",
      up_right_rect_start_x + 2 + PADDING_MM + 12 + 35,
      up_right_rect_start + 40
    ); //obec
  }
  doc.setFontSize(9);
  doc.setFillColor(0, 0, 0, 0);

  doc.setFontSize(10);
  doc.setFont("Roboto-Regular", "normal");
  doc.setFillColor(242, 242, 242);
  doc.rect(90 + PADDING_MM, 81, 106 - PADDING_MM, 38, "F");
  doc.text("Adresa dodania:", 92 + PADDING_MM + 1, 86);
  doc.text("Osoba/firma:", 92 + PADDING_MM + 32, 92, {
    align: "right",
  });
  doc.text("Ulica:", 92 + PADDING_MM + 32, 100, {
    align: "right",
  });
  doc.text("PSČ:", 92 + PADDING_MM + 1, 110);
  doc.text("Obec:", 92 + PADDING_MM + 32, 110, {
    align: "right",
  });
  doc.text("Mobil:", 92 + PADDING_MM + 1, 116);
  doc.setFont("Roboto-Regular", "bold");
  doc.text(
    data.prijimatel?.jmeno && data.prijimatel?.prijmeni
      ? data.prijimatel.jmeno + " " + data.prijimatel.prijmeni
      : data.objednavatel?.nazev
      ? data.objednavatel.nazev
      : "",
    92 + PADDING_MM + 34,
    92,
    {
      maxWidth: 52,
    }
  );
  doc.text(
    data.prijimatel?.adresa
      ? data.prijimatel.adresa
      : data.objednavatel?.adresa
      ? data.objednavatel.adresa
      : "",
    92 + PADDING_MM + 34,
    100,
    { maxWidth: 52 }
  );
  doc.text(
    data.prijimatel?.psc
      ? data.prijimatel.psc
      : data.objednavatel?.psc
      ? data.objednavatel.psc
      : "",
    92 + PADDING_MM + 10,
    110
  );
  doc.text(
    data.prijimatel?.obec
      ? data.prijimatel.obec
      : data.objednavatel?.obec
      ? data.objednavatel.obec
      : "",
    92 + PADDING_MM + 34,
    110
  );
  doc.text(
    data.prijimatel?.mobil
      ? data.prijimatel.mobil
      : data.objednavatel?.mobil
      ? data.objednavatel.mobil
      : "",
    92 + PADDING_MM + 12,
    116
  );

  doc.setFillColor(0, 0, 0, 0);

  const down_left_rect_start = 43;
  const down_left_rect_start_x = 90 + PADDING_MM;

  doc.setFillColor(242, 242, 242);
  doc.rect(
    down_left_rect_start_x,
    down_left_rect_start,
    106 - PADDING_MM,
    36,
    "F"
  );
  doc.setFontSize(11);
  doc.setFont("Roboto-Regular", "normal");
  doc.text(
    "Fakturačné údaje:",
    down_left_rect_start_x + 2,
    down_left_rect_start + 5
  );
  doc.text("Adresa:", down_left_rect_start_x + 2, down_left_rect_start + 16);
  doc.setFontSize(8);
  doc.text("IČO:", down_left_rect_start_x + 2, down_left_rect_start + 30);
  doc.text("DIČ:", down_left_rect_start_x + 2 + 22, down_left_rect_start + 30);
  doc.text(
    "IČ DPH:",
    down_left_rect_start_x + 2 + 50,
    down_left_rect_start + 30
  );
  doc.setFont("Roboto-Regular", "bold");
  doc.text(
    data.fakturacna?.ico ? data.fakturacna.ico : "",
    down_left_rect_start_x + 6.5 + 1.5,
    down_left_rect_start + 30
  ); //ičo
  doc.text(
    data.fakturacna?.dic ? data.fakturacna.dic : "",
    down_left_rect_start_x + 8 + 22.5,
    down_left_rect_start + 30
  ); //dič
  doc.text(
    data.fakturacna?.icodph ? data.fakturacna.icodph : "",
    down_left_rect_start_x + 13 + 50,
    down_left_rect_start + 30
  ); //ič dph

  if (data.fakturacna) {
    doc.setFontSize(10);
    doc.setFont("Roboto-Regular", "bold");
    doc.text(
      data.fakturacna.nazev
        ? data.fakturacna.nazev
        : data.fakturacna.jmeno && data.fakturacna.prijmeni
        ? data.fakturacna.jmeno + " " + data.fakturacna.prijmeni
        : data.objednavatel.nazev
        ? data.objednavatel.nazev
        : "",
      down_left_rect_start_x + 2 + 33,
      down_left_rect_start + 5,
      {
        maxWidth: 52,
      }
    );
    doc.text(
      data.fakturacna.adresa
        ? data.fakturacna.adresa
        : data.objednavatel.adresa
        ? data.objednavatel.adresa
        : "",
      down_left_rect_start_x + 2 + 33,
      down_left_rect_start + 16
    );
    doc.text(
      data.fakturacna.psc
        ? data.fakturacna.psc
        : data.objednavatel.psc
        ? data.objednavatel.psc
        : "",
      down_left_rect_start_x + 2 + 21,
      down_left_rect_start + 22
    );
    doc.text(
      data.fakturacna.obec
        ? data.fakturacna.obec
        : data.objednavatel.obec
        ? data.objednavatel.obec
        : "",
      down_left_rect_start_x + 2 + 33,
      down_left_rect_start + 22
    );
    doc.text(
      data.fakturacna.jmeno && data.fakturacna.prijmeni
        ? data.fakturacna.jmeno + " " + data.fakturacna.prijmeni
        : "",
      down_left_rect_start_x + 2 + 34,
      down_left_rect_start + 19
    );
  }
  doc.setFillColor(0, 0, 0, 0);

  doc.setFont("Roboto-Regular", "normal");
  doc.setFillColor(242, 242, 242);
  doc.rect(PADDING_MM, 81 + 38 + 2, 210 - PADDING_MM * 2, 20, "F");
  doc.setFontSize(10);

  doc.text("Dodacia doba", PADDING_MM + 2, 81 + 38 + 3 + 4);

  doc.setFont("Roboto-Regular", "bold");

  doc.text(
    data.termin ? data.termin.toString() : "X dní",
    PADDING_MM + 2 + 24,
    81 + 38 + 2 + 5
  );
  doc.text(
    data.dobierka
      ? "od úhrady zálohovej faktúry pri dobierke"
      : "od úhrady zálohovej faktúry pri platbe prevodom",
    PADDING_MM + 2,
    81 + 38 + 2 + 4 + 6
  );

  let rabatValue = data.rabat ? data.rabat : 0,
    bonusValue = data.bonus ? data.bonus : 0,
    dph = totalDPH(data),
    celkom_bez_dph = 0,
    sleva = 0,
    bonus = 0,
    celkom_s_dph_bonus = 0,
    celkom_bez_dph_bonus = 0,
    polozkyValues = data.polozky;

  polozkyValues.forEach((polozka: Polozka) => {
    celkom_bez_dph += polozka.cennikova_cena * polozka.mn;
  });

  sleva = celkom_bez_dph * (rabatValue / 100);
  bonus = (celkom_bez_dph - sleva) * (bonusValue / 100);

  celkom_bez_dph_bonus = celkom_bez_dph - sleva - bonus;
  celkom_s_dph_bonus = celkom_bez_dph_bonus + dph;

  let zaloha = data.zaloha_platba
      ? celkom_s_dph_bonus * (data.zaloha / 100)
      : null,
    doplatek =
      data.zaloha_platba && zaloha !== null
        ? celkom_s_dph_bonus - zaloha
        : null;

  if (data.zaloha_platba && zaloha !== null && doplatek !== null) {
    doc.text(
      zaloha.toFixed(2) + " €",
      PADDING_MM + 2 + 210 - PADDING_MM * 2 - 4,
      81 + 38 + 3 + 4,
      { align: "right" }
    );
    doc.text(
      doplatek.toFixed(2) + " €",
      PADDING_MM + 2 + 210 - PADDING_MM * 2 - 4,
      81 + 38 + 3 + 4 + 6,
      { align: "right" }
    );
  }

  doc.text(
    data.splatnost ? data.splatnost.toString() : "X dní",
    PADDING_MM + 2 + 210 - PADDING_MM * 2 - 4,
    81 + 38 + 3 + 4 + (!data.zaloha_platba ? 0 : 12),
    { align: "right" }
  );

  doc.setFont("Roboto-Regular", "normal");
  if (data.zaloha_platba) {
    doc.text("Záloha pri objednávke:", PADDING_MM + 2 + 100, 81 + 36 + 3 + 4);
    doc.text(
      "Doplatok pred dodávkou:",
      PADDING_MM + 2 + 100,
      81 + 38 + 3 + 4 + 6
    );
  }
  doc.text(
    "Faktúra so splatnosťou:",
    PADDING_MM + 2 + 100,
    81 + 38 + 3 + 4 + (!data.zaloha_platba ? 0 : 12)
  );
  doc.setFont("Roboto-Regular", "bold");

  doc.setFillColor(0, 0, 0, 0);

  let polozky = data.polozky
    .filter((p) => Boolean(p.id) && Boolean(p.popis))
    .map((p: Polozka) => {
      return [
        p.kod,
        p.sirka,
        p.vyska,
        p.dokoncena_stena,
        p.popis,
        p.mj,
        parseFloat(p.mn as any).toString(),
        parseFloat(p.cennikova_cena as any).toFixed(2),
        (parseFloat(p.mn as any) * parseFloat(p.cennikova_cena as any)).toFixed(
          2
        ),
        returnDPH(data.fakturacna, p).toString(),
        (
          parseFloat(p.mn as any) *
          parseFloat(p.cennikova_cena as any) *
          (1 + returnDPH(data.fakturacna, p) / 100)
        ).toFixed(2),
      ];
    });

  let tableStartY = 81 + 38 + 2 + 20 + 2,
    afterTableOffsetMinimum = 30;

  // @ts-expect-error

  doc.autoTable({
    head: [
      [
        "KÓD",
        "Šírka",
        "Výška",
        "Dokonč. stena",
        "Popis položky",
        "m.j.",
        "mn.",
        "Jed. cena (€)",
        "Celkom (€)",
        "DPH (%)",
        "Suma s DPH (€)",
      ],
    ],
    body: polozky,
    theme: "plain",
    columnStyles: {
      0: { halign: "center", cellWidth: 26.666 },
      1: { halign: "center", cellWidth: 10 },
      2: { halign: "center", cellWidth: 10 },
      3: { halign: "center", cellWidth: 14 },
      4: { halign: "left", cellWidth: 50 },
      5: { halign: "center", cellWidth: 6.666 },
      6: { halign: "center", cellWidth: 6.6666 },
      7: { halign: "center", cellWidth: 16.666 },
      8: { halign: "center", cellWidth: 16.666 },
      9: { halign: "center", cellWidth: 8 },
      10: { halign: "right", cellWidth: 16.666 },
    },
    startY: tableStartY,
    styles: {
      font: "Roboto-Regular",
      fontSize: 8,
    },
    bodyStyles: {
      lineWidth: 0.4,
      lineColor: [27, 86, 162],
      cellPadding: 1,
    },
    headStyles: {
      lineWidth: 0.4,
      cellPadding: 1,
      lineColor: [27, 86, 162],
      halign: "center",
      valign: "middle",
    },
  });

  //@ts-expect-error
  let afterTable = doc.lastAutoTable.finalY + 2,
    tableHeight = afterTable - tableStartY;

  if (
    tableStartY + tableHeight + afterTableOffsetMinimum >
    doc.internal.pageSize.height - PADDING_MM * 2
  ) {
    doc.addPage();
    afterTable = PADDING_MM;
  }

  doc.setFontSize(10);
  doc.setFont("Roboto-Regular", "normal");
  doc.setFillColor(242, 242, 242);

  let isBonus = false,
    isSleva = false;

  isBonus = Boolean(bonus !== 0 && bonus);
  isSleva = Boolean(sleva !== 0 && sleva);

  doc.rect(90 + PADDING_MM, afterTable, 106 - PADDING_MM, 34, "F");
  doc.setFont("Roboto-Regular", "normal");
  doc.text("Dodávka celkom bez DPH", 92 + PADDING_MM, afterTable + 4);

  let CELKOM_BEZ_DPH_OFFSET =
      isSleva && isBonus ? 20.5 : isSleva || isBonus ? 15 : 9.5,
    SUMA_DPH_OFFSET = isSleva && isBonus ? 26 : isSleva || isBonus ? 20.5 : 15,
    K_UHRADE_OFFSET =
      isSleva && isBonus ? 31.5 : isSleva || isBonus ? 26 : 20.5;

  doc.text(
    "Celkom bez DPH",
    92 + PADDING_MM,
    afterTable + CELKOM_BEZ_DPH_OFFSET
  );
  doc.text("Suma DPH", 92 + PADDING_MM, afterTable + SUMA_DPH_OFFSET);
  doc.text("K úhrade", 92 + PADDING_MM, afterTable + K_UHRADE_OFFSET);

  if (isSleva) {
    doc.text("Obchodná zľava (rabat)", 92 + PADDING_MM, afterTable + 9.5);
  }

  if (isBonus) {
    doc.text("Bonus", 92 + PADDING_MM, afterTable + (isSleva ? 15 : 9.5));
  }

  doc.setFont("Roboto-Regular", "bold");
  doc.text(
    celkom_bez_dph.toFixed(2) + " EUR",
    92 + PADDING_MM + (102 - PADDING_MM),
    afterTable + 4,
    {
      align: "right",
    }
  ); //cena celkom

  doc.setFont("Roboto-Regular", "normal");
  if (isSleva)
    doc.text(
      "- " + sleva.toFixed(2) + " EUR",
      92 + PADDING_MM + (102 - PADDING_MM),
      afterTable + 9.5,
      {
        align: "right",
      }
    ); // dohodnuta zlava v EUR

  doc.setFontSize(8);
  if (isSleva)
    doc.text(
      rabatValue.toString() + "%",
      92 + PADDING_MM + (102 - PADDING_MM) - 37.5,
      afterTable + 9.5,
      {
        align: "right",
      }
    ); // sleva v %
  doc.setFontSize(10);

  doc.setFont("Roboto-Regular", "normal");

  if (isBonus)
    doc.text(
      "- " + bonus.toFixed(2) + " EUR",
      92 + PADDING_MM + (102 - PADDING_MM),
      afterTable + (isSleva ? 15 : 9.5),
      {
        align: "right",
      }
    ); // bonus v EUR

  doc.setFontSize(8);
  if (isBonus)
    doc.text(
      bonusValue.toString() + "%",
      92 + PADDING_MM + (102 - PADDING_MM) - 37.5,
      afterTable + (isSleva ? 15 : 9.5),
      {
        align: "right",
      }
    ); // bonus v %
  doc.setFontSize(10);

  doc.setFont("Roboto-Regular", "normal");

  doc.text(
    dph.toFixed(2) + " EUR",
    92 + PADDING_MM + (102 - PADDING_MM),
    afterTable + SUMA_DPH_OFFSET,
    {
      align: "right",
    }
  ); //DPH v eur

  doc.setFont("Roboto-Regular", "bold");

  doc.text(
    (celkom_bez_dph - sleva - bonus).toFixed(2) + " EUR",
    92 + PADDING_MM + (102 - PADDING_MM),
    afterTable + CELKOM_BEZ_DPH_OFFSET,
    {
      align: "right",
    }
  ); //celkom bez dph

  doc.text(
    celkom_s_dph_bonus.toFixed(2) + " EUR",
    92 + PADDING_MM + (102 - PADDING_MM),
    afterTable + K_UHRADE_OFFSET,
    {
      align: "right",
    }
  ); //k uhrade s dph

  doc.setFillColor(0, 0, 0, 0);

  doc.setFont("Roboto-Regular", "normal");
  doc.setFontSize(9);
  doc.text("Vypracoval:", PADDING_MM, afterTable + 4);
  doc.text("Mobil:", PADDING_MM, afterTable + 9);
  doc.text("Email:", PADDING_MM, afterTable + 14);
  doc.text("Dňa:", PADDING_MM, afterTable + 24);

  let editedByDate = data.last_edited_date as number,
    editedBy = data.last_edited_by as LastEdited;

  doc.setFont("Roboto-Regular", "bold");
  doc.text(editedBy.name, PADDING_MM + 25, afterTable + 4); //vypracoval
  doc.text(editedBy.mobil, PADDING_MM + 25, afterTable + 9); //mobil
  doc.text(editedBy.email, PADDING_MM + 25, afterTable + 14); //email

  doc.text(getCPDate(editedByDate), PADDING_MM + 25, afterTable + 24); //datum vystavenia

  if (afterTable + 34 + 40 > doc.internal.pageSize.height - 10) {
    doc.addPage();
    afterTable = -25;
  }

  doc.setFillColor(27, 86, 162);
  doc.rect(PADDING_MM, afterTable + 51, 70, 0.25, "F");
  doc.setFillColor(0, 0, 0);

  const qrPaymentString = generateQRPayment(celkom_s_dph_bonus, data.cislo_ponuky);

  let QRbase64: string = await new Promise((resolve, reject) => {
    QRCode.toDataURL(qrPaymentString, function (err, code) {
      if (err) {
        reject(reject);
        return;
      }
      resolve(code);
    });
  });
  doc.addImage(QRbase64, "png", 159, afterTable + 34, 40, 40);

  doc.setFont("Roboto-Regular", "normal");
  doc.text("podpis", PADDING_MM + 30, afterTable + 56);

  let fileName = "ZF-" + data.cislo_ponuky.toString();

  return { url: doc.output("bloburl").toString(), doc: doc, fileName };
};

export default generateZalohovaFaktura;
