import axios from "../axios/axios";
import { User } from "../ts/Auth";
import { CenovaPonuka, Fakturace, LastEdited, Polozka, State } from "../ts/CP";
import { Kontakt } from "../ts/Kontakt";

export const setAuthToken = (token: string | boolean) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const isKontaktAvailable = (
  id: string,
  nazev: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .get("/kontakty/available", {
        params: {
          id,
          nazev,
        },
      })
      .then((req) => {
        resolve(req.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getObchodnikById = (id: string): Promise<LastEdited> => {
  return new Promise((resolve, reject) => {
    axios
      .get("/users/fetch-obchodnik", {
        params: {
          id,
        },
      })
      .then((req) => {
        resolve(req.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCPState = (state: State) => {
  switch (state) {
    case "new":
      return "Nová";
    case "in-progress":
      return "Spracováva sa";
    case "finished":
      return "Vybavená";
  }
};

export const getNextCPState = (state: State) => {
  switch (state) {
    case "new":
      return "Spracováva sa";
    case "in-progress":
    case "finished":
      return "Vybavená";
    default:
      return "Nová";
  }
};

export const getNextCPStateRaw = (state: State) => {
  switch (state) {
    case "new":
      return "in-progress";
    case "in-progress":
    case "finished":
      return "finished";
    default:
      return "new";
  }
};

const isValidJsonKey = (key: string) => {
  const keyArray = [
    "nazev",
    "skupina",
    "id_klienta",
    "titul",
    "jmeno",
    "prijmeni",
    "funkce",
    "adresa",
    "psc",
    "obec",
    "okres",
    "stat",
    "tel",
    "mobil",
    "email",
    "web",
    "ico",
    "dic",
    "icodph",
    "rabat",
    "splatnost",
    "obchodnik",
  ];

  return keyArray.includes(key);
};

export const mapCSVDataToJson = (object: any): Kontakt[] | false => {
  let objectLength = Object.keys(object).length;

  let mappedArray: any[] = [];

  for (let i = 0; i < objectLength; i++) {
    if (Object.keys(object[i]).length !== 31) continue;

    let mappedObject: any = {};

    Object.keys(object[i]).map((k) => {
      if (k.includes("fakturacna_") || k.includes("prijimatel_")) {
        if (k.includes("fakturacna_")) {
          if (!mappedObject.fakturacna) mappedObject.fakturacna = {};
          mappedObject["fakturacna"][k.split("_")[1]] = object[i][k];
        }
      } else {
        mappedObject[k] = object[i][k];
      }
    });
    mappedArray.push(mappedObject);
  }

  return mappedArray.length === 0 ? false : (mappedArray as Kontakt[]);
};

export const checkJsonFormat = (data: any[]) => {
  let returnedValue = true;

  data.forEach((d) => {
    if (Object.keys(d).length !== 22) {
      returnedValue = false;
      return;
    }
    Object.keys(d).forEach((key) => {
      if (!isValidJsonKey(key)) {
        returnedValue = false;
        return;
      }
    });
  });
  return returnedValue;
};

export const getSum = (cp: CenovaPonuka): number => {
  if (cp.polozky.length > 0)
    return cp.polozky
      .map((p) => p.mn * p.cennikova_cena)
      .reduce((prev, curr) => {
        return prev + curr;
      });
  return 0;
};

export const getFileDate = () => {
  let dateString,
    date = new Date(),
    day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear();

  dateString = `${day}-${month}-${year}`;

  return dateString;
};

export const getCPDate = (dateTime: number | undefined): string => {
  if (!dateTime) return "";

  let dateString,
    date = new Date(dateTime),
    day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear();

  dateString = `${day}. ${month}. ${year}`;

  return dateString;
};

export const translateString = (str: string): string => {
  const convertLetter = (l: string) => {
    if (l === "á" || l === "ä") return "a";
    if (l === "č") return "c";
    if (l === "ď") return "d";
    if (l === "é" || l === "ě") return "e";
    if (l === "í") return "i";
    if (l === "ĺ" || l === "ľ") return "l";
    if (l === "ň") return "n";
    if (l === "ô" || l === "ó") return "o";
    if (l === "ŕ") return "r";
    if (l === "š") return "s";
    if (l === "ť") return "t";
    if (l === "ú" || l === "ů") return "u";
    if (l === "ý") return "y";
    if (l === "ž") return "z";

    return l;
  };

  let newString = "";

  for (let i = 0; i < str.length; i++) {
    newString += convertLetter(str[i]);
  }

  return newString;
};

export const totalDPH = (cp: CenovaPonuka): number => {
  let total = 0,
    rabat = cp.rabat ? cp.rabat : 0,
    bonus = cp.bonus ? cp.bonus : 0;

  cp.polozky.forEach((p) => {
    const priceTotal = p.cennikova_cena * p.mn;

    const priceAfterSale = priceTotal - priceTotal * (rabat / 100);
    const priceAfterBonus = priceAfterSale - priceAfterSale * (bonus / 100);

    total += priceAfterBonus * (returnDPH(cp.fakturacna, p) / 100);
  });

  return total;
};

export const returnDPH = (fakturacna: Fakturace, polozka: Polozka): number => {
  if (fakturacna) {
    if (fakturacna.icodph !== "" && fakturacna.icodph) {
      let slovo1 = polozka.popis.split(" ")[0],
        slovo2 = polozka.popis.split(" ")[1],
        slovo = slovo1 + " " + slovo2;

      if (
        slovo.toLowerCase().includes("stavebné púzdro") ||
        slovo.toLowerCase().includes("stavebné puzdro") ||
        slovo.toLowerCase().includes("stavebne púzdro") ||
        slovo.toLowerCase().includes("stavebne puzdro")
      ) {
        return 0;
      }
      return polozka.dph;
    }

    return polozka.dph;
  }
  return polozka.dph;
};

export const getSumNoDPH = (cp: CenovaPonuka): number => {
  if (cp.polozky.length > 0)
    return cp.polozky
      .map((p) => p.mn * p.cennikova_cena)
      .reduce((prev, curr) => {
        return prev + curr;
      });
  return 0;
};

export const formatCPNumber = (cp: string) => {
  return cp;
};
