/**
 * Mapping semantic version to encoded version number, header 4-bits
 *
 * It's a bit silly to limit the version number to 4-bit, if they keep
 * increasing the version number, the latest possible mapped value is 16
 */
export var Version;
(function (Version) {
    /**
     * 2013-02-22
     * Created this document from original by square specifications
     */
    Version[Version["1.0.0"] = 0] = "1.0.0";
    /**
     * 2015-06-24
     * Added fields for beneficiary name and address
     */
    Version[Version["1.1.0"] = 1] = "1.1.0";
})(Version || (Version = {}));
/**
 * Kalendárny mesiac.
 */
export var MonthClassifier;
(function (MonthClassifier) {
    MonthClassifier[MonthClassifier["January"] = 1] = "January";
    MonthClassifier[MonthClassifier["February"] = 2] = "February";
    MonthClassifier[MonthClassifier["March"] = 4] = "March";
    MonthClassifier[MonthClassifier["April"] = 8] = "April";
    MonthClassifier[MonthClassifier["May"] = 16] = "May";
    MonthClassifier[MonthClassifier["June"] = 32] = "June";
    MonthClassifier[MonthClassifier["July"] = 64] = "July";
    MonthClassifier[MonthClassifier["August"] = 128] = "August";
    MonthClassifier[MonthClassifier["September"] = 256] = "September";
    MonthClassifier[MonthClassifier["October"] = 512] = "October";
    MonthClassifier[MonthClassifier["November"] = 1024] = "November";
    MonthClassifier[MonthClassifier["December"] = 2048] = "December";
})(MonthClassifier || (MonthClassifier = {}));
/**
 * Deň platby vyplývajúci z opakovania (Periodicity). Deň v mesiaci je číslo
 * medzi 1 a 31. Deň v týždni je číslo medzi 1 a 7 (1 = pondelok, 2=utorok, …, 7
 * = nedeľa).
 */
export var Periodicity;
(function (Periodicity) {
    Periodicity["Daily"] = "d";
    Periodicity["Weekly"] = "w";
    Periodicity["Biweekly"] = "b";
    Periodicity["Monthly"] = "m";
    Periodicity["Bimonthly"] = "B";
    Periodicity["Quarterly"] = "q";
    Periodicity["Semiannually"] = "s";
    Periodicity["Annually"] = "a";
})(Periodicity || (Periodicity = {}));
/**
 * Možnosti platby sa dajú kombinovať. Oddeľujú sa medzerou a treba uviesť vždy
 * aspoň jednu z možností:
 *
 * - paymentorder: platobný príkaz
 * - standingorder: trvalý príkaz, údaje sa vyplnia do StandingOrderExt
 * - directdebit: inkaso, údaje sa vyplnia do DirectDebitExt
 */
export var PaymentOptions;
(function (PaymentOptions) {
    PaymentOptions[PaymentOptions["PaymentOrder"] = 1] = "PaymentOrder";
    PaymentOptions[PaymentOptions["StandingOrder"] = 2] = "StandingOrder";
    PaymentOptions[PaymentOptions["DirectDebit"] = 4] = "DirectDebit";
})(PaymentOptions || (PaymentOptions = {}));
/**
 * Inksaná schéma. Uvádza ja jedna z možností:
 *
 * SEPA - Inkaso zodpovedá schéme
 * SEPA. other - iné
 */
export var DirectDebitScheme;
(function (DirectDebitScheme) {
    DirectDebitScheme[DirectDebitScheme["Other"] = 0] = "Other";
    DirectDebitScheme[DirectDebitScheme["Sepa"] = 1] = "Sepa";
})(DirectDebitScheme || (DirectDebitScheme = {}));
/**
 * Maximálna dĺžka 1
 *
 * Typ inkasa. Uvádza ja jedna z možností:
 *
 * one-off - jednorázové inkaso
 * recurrent - opakované inkaso
 */
export var DirectDebitType;
(function (DirectDebitType) {
    DirectDebitType[DirectDebitType["OneOff"] = 0] = "OneOff";
    DirectDebitType[DirectDebitType["Recurrent"] = 1] = "Recurrent";
})(DirectDebitType || (DirectDebitType = {}));
/**
 * ISO-4217
 */
export var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["AED"] = "AED";
    CurrencyCode["AFN"] = "AFN";
    CurrencyCode["ALL"] = "ALL";
    CurrencyCode["AMD"] = "AMD";
    CurrencyCode["ANG"] = "ANG";
    CurrencyCode["AOA"] = "AOA";
    CurrencyCode["ARS"] = "ARS";
    CurrencyCode["AUD"] = "AUD";
    CurrencyCode["AWG"] = "AWG";
    CurrencyCode["AZN"] = "AZN";
    CurrencyCode["BAM"] = "BAM";
    CurrencyCode["BBD"] = "BBD";
    CurrencyCode["BDT"] = "BDT";
    CurrencyCode["BGN"] = "BGN";
    CurrencyCode["BHD"] = "BHD";
    CurrencyCode["BIF"] = "BIF";
    CurrencyCode["BMD"] = "BMD";
    CurrencyCode["BND"] = "BND";
    CurrencyCode["BOB"] = "BOB";
    CurrencyCode["BRL"] = "BRL";
    CurrencyCode["BSD"] = "BSD";
    CurrencyCode["BTN"] = "BTN";
    CurrencyCode["BWP"] = "BWP";
    CurrencyCode["BYN"] = "BYN";
    CurrencyCode["BZD"] = "BZD";
    CurrencyCode["CAD"] = "CAD";
    CurrencyCode["CDF"] = "CDF";
    CurrencyCode["CHF"] = "CHF";
    CurrencyCode["CLP"] = "CLP";
    CurrencyCode["CNY"] = "CNY";
    CurrencyCode["COP"] = "COP";
    CurrencyCode["CRC"] = "CRC";
    CurrencyCode["CUC"] = "CUC";
    CurrencyCode["CUP"] = "CUP";
    CurrencyCode["CVE"] = "CVE";
    CurrencyCode["CZK"] = "CZK";
    CurrencyCode["DJF"] = "DJF";
    CurrencyCode["DKK"] = "DKK";
    CurrencyCode["DOP"] = "DOP";
    CurrencyCode["DZD"] = "DZD";
    CurrencyCode["EGP"] = "EGP";
    CurrencyCode["ERN"] = "ERN";
    CurrencyCode["ETB"] = "ETB";
    CurrencyCode["EUR"] = "EUR";
    CurrencyCode["FJD"] = "FJD";
    CurrencyCode["FKP"] = "FKP";
    CurrencyCode["GBP"] = "GBP";
    CurrencyCode["GEL"] = "GEL";
    CurrencyCode["GHS"] = "GHS";
    CurrencyCode["GIP"] = "GIP";
    CurrencyCode["GMD"] = "GMD";
    CurrencyCode["GNF"] = "GNF";
    CurrencyCode["GTQ"] = "GTQ";
    CurrencyCode["GYD"] = "GYD";
    CurrencyCode["HKD"] = "HKD";
    CurrencyCode["HNL"] = "HNL";
    CurrencyCode["HRK"] = "HRK";
    CurrencyCode["HTG"] = "HTG";
    CurrencyCode["HUF"] = "HUF";
    CurrencyCode["IDR"] = "IDR";
    CurrencyCode["ILS"] = "ILS";
    CurrencyCode["INR"] = "INR";
    CurrencyCode["IQD"] = "IQD";
    CurrencyCode["IRR"] = "IRR";
    CurrencyCode["ISK"] = "ISK";
    CurrencyCode["JMD"] = "JMD";
    CurrencyCode["JOD"] = "JOD";
    CurrencyCode["JPY"] = "JPY";
    CurrencyCode["KES"] = "KES";
    CurrencyCode["KGS"] = "KGS";
    CurrencyCode["KHR"] = "KHR";
    CurrencyCode["KMF"] = "KMF";
    CurrencyCode["KPW"] = "KPW";
    CurrencyCode["KRW"] = "KRW";
    CurrencyCode["KWD"] = "KWD";
    CurrencyCode["KYD"] = "KYD";
    CurrencyCode["KZT"] = "KZT";
    CurrencyCode["LAK"] = "LAK";
    CurrencyCode["LBP"] = "LBP";
    CurrencyCode["LKR"] = "LKR";
    CurrencyCode["LRD"] = "LRD";
    CurrencyCode["LSL"] = "LSL";
    CurrencyCode["LYD"] = "LYD";
    CurrencyCode["MAD"] = "MAD";
    CurrencyCode["MDL"] = "MDL";
    CurrencyCode["MGA"] = "MGA";
    CurrencyCode["MKD"] = "MKD";
    CurrencyCode["MMK"] = "MMK";
    CurrencyCode["MNT"] = "MNT";
    CurrencyCode["MOP"] = "MOP";
    CurrencyCode["MRU"] = "MRU";
    CurrencyCode["MUR"] = "MUR";
    CurrencyCode["MVR"] = "MVR";
    CurrencyCode["MWK"] = "MWK";
    CurrencyCode["MXN"] = "MXN";
    CurrencyCode["MYR"] = "MYR";
    CurrencyCode["MZN"] = "MZN";
    CurrencyCode["NAD"] = "NAD";
    CurrencyCode["NGN"] = "NGN";
    CurrencyCode["NIO"] = "NIO";
    CurrencyCode["NOK"] = "NOK";
    CurrencyCode["NPR"] = "NPR";
    CurrencyCode["NZD"] = "NZD";
    CurrencyCode["OMR"] = "OMR";
    CurrencyCode["PAB"] = "PAB";
    CurrencyCode["PEN"] = "PEN";
    CurrencyCode["PGK"] = "PGK";
    CurrencyCode["PHP"] = "PHP";
    CurrencyCode["PKR"] = "PKR";
    CurrencyCode["PLN"] = "PLN";
    CurrencyCode["PYG"] = "PYG";
    CurrencyCode["QAR"] = "QAR";
    CurrencyCode["RON"] = "RON";
    CurrencyCode["RSD"] = "RSD";
    CurrencyCode["RUB"] = "RUB";
    CurrencyCode["RWF"] = "RWF";
    CurrencyCode["SAR"] = "SAR";
    CurrencyCode["SBD"] = "SBD";
    CurrencyCode["SCR"] = "SCR";
    CurrencyCode["SDG"] = "SDG";
    CurrencyCode["SEK"] = "SEK";
    CurrencyCode["SGD"] = "SGD";
    CurrencyCode["SHP"] = "SHP";
    CurrencyCode["SLL"] = "SLL";
    CurrencyCode["SOS"] = "SOS";
    CurrencyCode["SRD"] = "SRD";
    CurrencyCode["SSP"] = "SSP";
    CurrencyCode["STN"] = "STN";
    CurrencyCode["SVC"] = "SVC";
    CurrencyCode["SYP"] = "SYP";
    CurrencyCode["SZL"] = "SZL";
    CurrencyCode["THB"] = "THB";
    CurrencyCode["TJS"] = "TJS";
    CurrencyCode["TMT"] = "TMT";
    CurrencyCode["TND"] = "TND";
    CurrencyCode["TOP"] = "TOP";
    CurrencyCode["TRY"] = "TRY";
    CurrencyCode["TTD"] = "TTD";
    CurrencyCode["TWD"] = "TWD";
    CurrencyCode["TZS"] = "TZS";
    CurrencyCode["UAH"] = "UAH";
    CurrencyCode["UGX"] = "UGX";
    CurrencyCode["USD"] = "USD";
    CurrencyCode["UYU"] = "UYU";
    CurrencyCode["UZS"] = "UZS";
    CurrencyCode["VES"] = "VES";
    CurrencyCode["VND"] = "VND";
    CurrencyCode["VUV"] = "VUV";
    CurrencyCode["WST"] = "WST";
    CurrencyCode["XAF"] = "XAF";
    CurrencyCode["XCD"] = "XCD";
    CurrencyCode["XOF"] = "XOF";
    CurrencyCode["XPF"] = "XPF";
    CurrencyCode["YER"] = "YER";
    CurrencyCode["ZAR"] = "ZAR";
    CurrencyCode["ZMW"] = "ZMW";
    CurrencyCode["ZWL"] = "ZWL";
})(CurrencyCode || (CurrencyCode = {}));
